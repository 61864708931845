<template>
    <div>
        <Navbar/>

        <p v-if="!perfilPermitido">Usted no puede acceder a esta area.</p>

        <div v-if="perfilPermitido">
            <b-button-group>                
                <b-dropdown right text="Informes periodo">
                <b-dropdown-item @click="menu('PRESUPUESTAL')">Ejecución presupuestal</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="menu('ESTADOPRESUPUESTO')">Estado presupuesto</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="menu('INGRESOSFECHAS')">Ingresos fechas</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="menu('SALIDASFECHAS')">Egresos fechas</b-dropdown-item>
                </b-dropdown>
                <b-button @click="menu('DATODIA')">Estado día</b-button>
                <b-button @click="menu('ALBERGUEDX')">Albergue DX</b-button>
            </b-button-group>
        </div>
        
        <b-container v-if="showEjecucionfecha && perfilPermitido">
            <br>
            <b-card>
                <b-card-header>
                    Generar reporte por mes
                </b-card-header>
                <b-card-body>
                    <b>Contrato: </b>
                    <b-form-select v-model="contrato">
                        <b-form-select-option v-for="contrato in contratos" :key="contrato.id" :value="contrato.id">
                            {{ contrato.NROCONTRATO }}
                        </b-form-select-option>
                    </b-form-select>
                    <b-input-group>                        
                        <b-form-datepicker locale="es" v-model="fechaInicio"></b-form-datepicker>
                        <b-form-datepicker locale="es" v-model="fechaFin"></b-form-datepicker>
                        <b-button @click="generarInformeMensual">Generar reporte fechas</b-button>
                    </b-input-group>
                </b-card-body>
            </b-card>
            
            <b-card>
                <b-card-header>
                    Estado ejecución
                </b-card-header>
                <b-card-body>                    
                    <b-input-group>
                        <b-button class="mb-3"  @click="getEstadoEjecucion()">Ver ejecucion</b-button>
                    </b-input-group>
                    <b-table
                        responsive 
                        hover 
                        bordered=bordered
                        :items="ejecuciones">
                    </b-table>
                </b-card-body>
            </b-card>            
        </b-container>

        <b-container v-if="showDatodia && perfilPermitido">
            <br>
            <h2>ESTADO DÍA</h2>
            <b-row align-h="center">
                <b-col sm="4">
                    Reporte al día
                    <b-input-group>
                        <b-form-datepicker                        
                            locale='es' 
                            id="fechaCupodia" 
                            v-model="fechaCupodia" 
                            class="mb-3"
                            :min="minDate" :max="maxDate">                        
                        </b-form-datepicker>
                        <b-button class="mb-3"  @click="getDatoDia()">Ver</b-button>
                    </b-input-group>
                    
                </b-col>
            </b-row>
            <b-row align-h="center">
                <b-col sm="3">
                    
                    <b-table 
                        hover 
                        bordered=bordered
                        :items="datoDia">
                    </b-table>
                </b-col>                
            </b-row>
        </b-container>
        <b-container v-if="showPresupuestal && perfilPermitido">
            <br>
            <h2>EJECUCION PRESUPUESTAL</h2>
            <b-row align-h="center">                
                <b-col sm="5">
                    <b-input-group prepend="Fecha inicio">
                        <b-form-datepicker 
                        locale='es' 
                        id="fechaInicio" 
                        v-model="fechaInicio" 
                        class="sm-3"
                        :min="minDate" :max="maxDate"></b-form-datepicker>                        
                    </b-input-group>
                </b-col>
                <b-col sm="5">
                    <b-input-group prepend="Fecha fin">
                        <b-form-datepicker 
                        locale='es' 
                        id="fechaFin" 
                        v-model="fechaFin" 
                        class="sm-3"
                        :min="minDate" :max="maxDate"></b-form-datepicker>                        
                    </b-input-group>
                </b-col>
                <b-col sm="2">
                    <b-button-group>
                        <b-button @click="generarCostos()">Ver</b-button>
                        <b-button variant="success" v-if="costosAlbergue.length != 0" @click="csvExport(costosAlbergue)">Exportar</b-button>
                    </b-button-group>
                </b-col>
            </b-row>
            <b-row v-if="costoTotal != 0">
                <b-col>
                    Costo total: {{ costoTotal }}
                </b-col>
            </b-row>
            <b-table responsive
                :sticky-header="stickyHeader"
                :striped="striped"
                :bordered=bordered
                :items="costosAlbergue"
                >                
            </b-table>
            <b-table responsive
                :sticky-header="stickyHeader"
                :striped="striped"
                :bordered="bordered"
                :items="contratos"
                >                
            </b-table>
        </b-container>
        <b-container v-if="showIngresosFechas && perfilPermitido">
            <br>
            <h2>INGRESOS FECHAS</h2>
            <b-row align-h="center">                
                <b-col sm="5">
                    <b-input-group prepend="Fecha inicio">
                        <b-form-datepicker 
                        locale='es' 
                        id="fechaInicio" 
                        v-model="fechaInicio" 
                        class="sm-3"
                        :min="minDate" :max="maxDate"></b-form-datepicker>                        
                    </b-input-group>
                </b-col>
                <b-col sm="5">
                    <b-input-group prepend="Fecha fin">
                        <b-form-datepicker 
                        locale='es' 
                        id="fechaFin" 
                        v-model="fechaFin" 
                        class="sm-3"
                        :min="minDate" :max="maxDate"></b-form-datepicker>                        
                    </b-input-group>
                </b-col>
                <b-col sm="2">
                    <b-button-group>
                        <b-button @click="ingresosFechas()">Ver</b-button>
                        <b-button variant="success" v-if="ingresosFechasData.length != 0" @click="csvExport(ingresosFechasData)">Exportar</b-button>
                    </b-button-group>
                </b-col>
            </b-row>
            <b-table responsive
                :sticky-header="stickyHeader"
                :striped="striped"
                :bordered="bordered"
                :items="ingresosFechasData"
                >                
            </b-table>
        </b-container>
        <b-container v-if="showSalidasFechas && perfilPermitido">
            <br>
            <h2>EGRESOS FECHAS</h2>
            <b-row align-h="center">                
                <b-col sm="5">
                    <b-input-group prepend="Fecha inicio">
                        <b-form-datepicker 
                        locale='es' 
                        id="fechaInicio" 
                        v-model="fechaInicio" 
                        class="sm-3"
                        :min="minDate" :max="maxDate"></b-form-datepicker>                        
                    </b-input-group>
                </b-col>
                <b-col sm="5">
                    <b-input-group prepend="Fecha fin">
                        <b-form-datepicker 
                        locale='es' 
                        id="fechaFin" 
                        v-model="fechaFin" 
                        class="sm-3"
                        :min="minDate" :max="maxDate"></b-form-datepicker>                        
                    </b-input-group>
                </b-col>
                <b-col sm="2">
                    <b-button-group>
                        <b-button @click="salidasFechas()">Ver</b-button>
                        <b-button variant="success" v-if="salidasFechasData.length != 0" @click="csvExport(salidasFechasData)">Exportar</b-button>
                    </b-button-group>
                </b-col>
            </b-row>
            <b-table responsive
                :sticky-header="stickyHeader"
                :striped="striped"
                :bordered="bordered"
                :items="salidasFechasData"
                >                
            </b-table>
        </b-container>
        <b-container v-if="showAlberguedx && perfilPermitido">
            <br>
            <b-row>
                <b-col>
                    <b-button variant="success" v-if="alberguedx.length != 0" @click="csvExport(alberguedx)">Exportar</b-button>
                </b-col>
            </b-row>
            <h2>ALBERGUE DX</h2>
            <b-row>
                <b-col sm="5">
                    <b-input-group prepend="Fecha inicio">
                        <b-form-datepicker 
                        locale='es' 
                        id="fechaInicio" 
                        v-model="fechaInicio" 
                        class="sm-3"
                        :min="minDate" :max="maxDate"></b-form-datepicker>                        
                    </b-input-group>
                </b-col>
                <b-col sm="5">
                    <b-input-group prepend="Fecha fin">
                        <b-form-datepicker 
                        locale='es' 
                        id="fechaFin" 
                        v-model="fechaFin" 
                        class="sm-3"
                        :min="minDate" :max="maxDate"></b-form-datepicker>                        
                    </b-input-group>
                </b-col>
                <b-col sm="2">
                    <b-button-group>
                        <b-button @click="getAlbergueDx()">Ver</b-button>
                        <b-button variant="success" v-if="alberguedx.length != 0" @click="csvExport(alberguedx)">Exportar</b-button>
                    </b-button-group>
                </b-col>
            </b-row>
            <b-table responsive
                :sticky-header="stickyHeader"
                :striped="striped"
                :bordered="bordered"
                :items="alberguedx"
                >                
            </b-table>
        </b-container>
    </div>
</template>
<script>
import axios from 'axios'
import Navbar from '@/components/Navbar.vue'
import moment from 'moment-timezone'
export default {
    name: 'InformeAlbergue',
    components: {
        Navbar
    },
    data () {
        return {
            perfil: localStorage.getItem("userPerfil"),
            rol: localStorage.getItem("userRol"),
            perfilPermitido: false,
            showDatodia: false,
            showEjecucionfecha: false,
            showPresupuestal: false,
            showIngresosFechas: false,
            showSalidasFechas: false,
            showAlberguedx: false,
            token: '',
            ejecuciones: [],
            datoDia: [],
            costoAlbergue: 0,
            fechaInicio: '',
            fechaFin: '',
            minDate: '2019-07-01',
            maxDate: new Date(),
            costosAlbergue: [],
            contrato: '',
            contratos: [],
            ingresosFechasData: [],
            salidasFechasData: [],
            costoTotal: 0,
            striped: true,
            bordered: true,
            stickyHeader: "800px",
            alberguedx: [],
            fechaCupodia: moment().format("YYYY-MM-DD")
        }
    },
    methods: {
        menu: function(menu){
            switch (menu) {
                case 'DATODIA':
                    this.showDatodia = true
                    this.showPresupuestal = false
                    this.showIngresosFechas = false
                    this.showSalidasFechas = false
                    this.showAlberguedx = false
                    this.showEjecucionfecha = false
                    break;
                case 'PRESUPUESTAL':
                    this.showDatodia = false
                    this.showPresupuestal = true
                    this.showIngresosFechas = false
                    this.showSalidasFechas = false
                    this.showAlberguedx = false
                    this.showEjecucionfecha = false
                    break;
                case 'INGRESOSFECHAS':
                    this.showDatodia = false
                    this.showPresupuestal = false
                    this.showIngresosFechas = true
                    this.showSalidasFechas = false
                    this.showAlberguedx = false
                    this.showEjecucionfecha = false
                    break;
                case 'SALIDASFECHAS':
                    this.showDatodia = false
                    this.showPresupuestal = false
                    this.showIngresosFechas = false
                    this.showSalidasFechas = true
                    this.showAlberguedx = false
                    this.showEjecucionfecha = false
                    break;
                case 'ALBERGUEDX':
                    this.showDatodia = false
                    this.showPresupuestal = false
                    this.showIngresosFechas = false
                    this.showSalidasFechas = false
                    this.showAlberguedx = true
                    this.showEjecucionfecha = false
                    break;
                case 'ESTADOPRESUPUESTO':
                    this.getEstadoEjecucion()
                    this.showDatodia = false
                    this.showEjecucionfecha = true
                    this.showPresupuestal = false
                    this.showIngresosFechas = false
                    this.showSalidasFechas = false
                    this.showAlberguedx = false
                    break;
                default:
                    break;
            }
        },
        generarInformeMensual: function () {
            let url = this.$store.getters.getApiUrl
            this.token = this.$store.getters.theToken
            const params = new URLSearchParams()
            params.append('contrato', this.contrato)
            params.append('fechaInicial', this.fechaInicio)
            params.append('fechaFinal', this.fechaFin)
            axios.post( url + 'setInformeMensual',
                params,
                {
                headers: {  
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${this.token}`
                }
            })
            .then((data) => {             
                console.log(data.data.data)             
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        getEstadoEjecucion: function() {
            let url = this.$store.getters.getApiUrl
            this.token = this.$store.getters.theToken
            const params = new URLSearchParams()
            params.append('fechaInicial', this.fechaInicio)
            params.append('fechaFinal', this.fechaFin)
            axios.post( url + 'infmensual',
                params,
                {
                headers: {  
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${this.token}`
                }
            })
            .then((data) => {             
                console.log(data.data.data)   
                this.ejecuciones = data.data.data             
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        getDatoDia: function () {
            let url = this.$store.getters.getApiUrl
            this.token = this.$store.getters.theToken
            const params = new URLSearchParams()
            params.append('FECHA', this.fechaCupodia)
            axios.post( url + 'albergueDia',
                params,
                {
                headers: {  
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${this.token}`
                }
            })
            .then((data) => {                
                this.datoDia = data.data.data
                
            })
            .catch((error) => {
                this.iraLogin(error)
            })


        },
        ingresosFechas: function () {
            this.token = this.$store.getters.theToken
            let url = this.$store.getters.getApiUrl
            axios.get( url + 'ingresosFechasAlbergue', 
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                },
                params: { 
                    fechaInicial: this.fechaInicio,
                    fechaFinal: this.fechaFin
                }
            })
            .then((res) => {            
                this.ingresosFechasData = res.data.data                                
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        salidasFechas: function () {
            this.token = this.$store.getters.theToken
            let url = this.$store.getters.getApiUrl
            axios.get( url + 'salidasFechasAlbergue', 
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                },
                params: { 
                    fechaInicial: this.fechaInicio,
                    fechaFinal: this.fechaFin
                }
            })
            .then((res) => {  
                console.log(res.data.data)          
                this.salidasFechasData = res.data.data                                
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        generarCostos: function () {
            this.token = this.$store.getters.theToken
            let url = this.$store.getters.getApiUrl
            axios.get( url + 'costoalberguefechas', 
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                },
                params: { 
                    fechaInicial: this.fechaInicio,
                    fechaFinal: this.fechaFin
                }
            })
            .then((res) => {
                if(res.data.data.length == 0) {alert (res.data.message)}         
                this.costosAlbergue = res.data.data 
                this.costoTotal = res.data.costo                 
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        getAlbergueDx: function () {
            this.token = this.$store.getters.theToken
            let url = this.$store.getters.getApiUrl
            const params = new FormData()            
            params.append('fechaInicio', this.fechaInicio)
            params.append('fechaFin', this.fechaFin)
            axios.post( url + 'alberguedx', params,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                },
                
            })
            .then((res) => {
                if(res.data.data.length == 0) {alert (res.data.message)}         
                this.alberguedx = res.data.data                
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        getContratosAlbergue: function () {
            this.token = this.$store.getters.theToken
            let url = this.$store.getters.getApiUrl
            axios.get( url + 'contratoalbergue', 
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                }
            })
            .then((res) => {                
                this.contratos = res.data.data                
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        csvExport(arrData) {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
                Object.keys(arrData[0]).join(";"),
                ...arrData.map(item => Object.values(item).join(";"))
            ]
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "");

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", "export.csv");
            link.click();
        }      
    },
    mounted () {
        this.getDatoDia()
        this.getContratosAlbergue()
        if(this.perfil == "CONSULTA" || this.perfil == "ADMINISTRADOR" || this.rol == "3"){
            this.perfilPermitido = true
        }        
    }
}
</script>