<template>
    <div>
        <h1>categorias</h1>
    </div>
</template>
<script>
export default {
    name: 'Categorias'
}
</script>
