<template>
    <div>
        <Navbar/>
        <b-container>
            <b-button-group>
                <b-button v-if="adminLogged" variant="outline-dark" @click="showConfigs('USUARIOS')">Usuarios</b-button>
                <b-button variant="outline-dark" @click="showConfigs('CATEGORIAS')">Categorias</b-button>
                <b-button v-if="adminLogged" variant="outline-dark" @click="showConfigs('CONTRATOALBERGUE')">Contrato albergue</b-button>
            </b-button-group>
        </b-container>        
        <Register v-if="showRegister" :adminLogged.sync="adminLogged"/>
        <Categorias v-if="showCategorias"/>
        <Contratoalbergue v-if="showContratoalbergue"/>
        
    </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Register from '../components/Register.vue'
import Categorias from '../components/Categorias.vue'
import Contratoalbergue from '../components/albergue/Contratoalbergue.vue'
export default {
    name: 'config',
    data () {
        return {
            showRegister: true,
            showCategorias: false,
            adminLogged: false,
            showContratoalbergue: false
        }
    },
    components: {
        Register,
        Categorias,
        Navbar,
        Contratoalbergue
    },
    methods: {
        showConfigs(idConfig){
            switch (idConfig) {
                case 'USUARIOS':
                    this.showRegister = (this.adminLogged)?true:false
                    this.showCategorias = false
                    this.showContratoalbergue = false
                    break;
                case 'CATEGORIAS':
                    this.showRegister = false
                    this.showCategorias = true
                    this.showContratoalbergue = false
                    break;
                case 'CONTRATOALBERGUE':
                    this.showRegister = false
                    this.showCategorias = false
                    this.showContratoalbergue = true
                    break;
                default:
                    break;
            }
        }
    },
    mounted () {
        if(localStorage.getItem('userPerfil') == 'ADMINISTRADOR'){
            this.adminLogged = true
        }
    }
}
</script>