<template>    
    <div style="background-color: #00A7B8; height: 100%" class="login">
        
        <div class="columns is-centered">
            <div class="column is-5-tablet is-4-desktop is-3-widescreen">
                
                <p class="text-danger">{{ info }}</p>
                <br><br>
                <form class="box" @submit.prevent="logeo">   
                              
                    <div class="field">
                        <label class="label">Ingreso a VIDAR</label>
                        <div class="control has-icons-left">
                            <input class="input" type="text" placeholder="Usuario" v-model="login" required>
                            <span class="icon">
                                <i class="fas fa-home"></i>
                            </span>
                        </div>                        
                    </div>
                    <div class="field">
                        <label class="label">Contraseña</label>
                        <div class="control has-icons-left">
                            <input class="input" type="password" placeholder="*******" v-model="password" required>
                            <span class="icon is-small is-left">
                                <i class="fa fa-lock"></i>
                            </span>
                        </div>                        
                    </div>
                    <div class="field">
                        <label class="label">Sede</label>
                        <b-form-select v-model="selectSede" class="form-control" :options="sedes" required></b-form-select>
                    </div>
                    <div class="field">
                        <b-button type="submit" squared variant="outline-primary">Ingresar</b-button>                    
                    </div>                    
                </form>
            </div>
        </div>
        <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
    </div>
</template>
<script>
import 'bulma/css/bulma.css'
export default {
    name: 'login',
    data () {
        return {            
            login: '',
            password: '',
            info: '',
            selectSede: '',
            sedes: [
                { value: 'BELENCITO', text: 'BELENCITO' },
                { value: 'JUANES', text: 'JUANES' },
                { value: 'CAUNCES', text: 'CAUNCES' },
                { value: 'UPJ', text: 'UPJ' },
                { value: 'ALBERGUE', text: 'ALBERGUE' }
            ]
        }
    },
    methods: {
      logeo: function () {
        let usuario = this.login
        let password = this.password
        this.$store.dispatch('login', { usuario, password })
       .then(() => 
        {
            this.setDatos()
            this.$router.push('/')          
        })
       .catch(() => {
           this.info = 'Hubo problemas ingresando: '
       })
      },
      setDatos: function () {
            localStorage.setItem('usuario', this.login)                
            localStorage.setItem('sede', this.selectSede)            
      }
    }
}
</script>