<template>
    <div>        
        <b-container v-if="adminLogged" class="lg">   
            <h1>Usuarios VIDAR</h1>         
            <b-row class="mb-3">
                <b-col md="3">
                    <b-button-group>
                        <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Buscar usuario"></b-form-input>
                        <b-button v-b-modal.formModal @click="limpiarForm">Nuevo</b-button>
                    </b-button-group>
                    
                </b-col>
                <b-col sm="7" md="6" class="my-1">
                    <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="sm"
                    class="my-0"
                    ></b-pagination>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-table 
                        striped 
                        hover 
                        outlined 
                        :items="users"
                        :filter="filter"
                        :fields="fields"
                        :current-page="currentPage"
                        :per-page="perPage">
                        <template #cell(actions)="row">
                            <b-button size="sm" @click="cargarForm(row.item, row.index)" class="mr-1">
                            Actualizar
                            </b-button>                        
                        </template>


                    </b-table>
                </b-col>
            </b-row>
        </b-container>
        
        

        <!-- Info modal -->
        <b-modal id="formModal" size="lg" title="Registro de usuarios" ok-only>
            <b-container fluid="sm">
                <b-form @submit.prevent="submitRegister">
                    <b-form-input class="text" disabled v-model='user.id'></b-form-input>
                    <b-row class="my-1">
                        <b-col class="sm-2">
                            <label for="name">Nombre</label>
                        </b-col>
                        <b-col class="sm-9">
                            <b-form-input v-model="user.name" name="name" placeholder="Nombre" required></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col class="sm-2">
                            <label for="usuario">Usuario</label>
                        </b-col>
                        <b-col class="sm-9">
                            <b-form-input v-model="user.usuario" name="usuario" placeholder="usuario" required></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col class="sm-2">
                            <label for="email">Correo</label>
                        </b-col>
                        <b-col class="sm-9">
                            <b-form-input v-model="user.email" type="email" name="email" placeholder="Email" required></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col class="sm-2">
                            <label for="perfil">Perfil</label>
                        </b-col>
                        <b-col class="sm-9">
                            <b-form-select v-model="user.perfil" class="form-control" :options="perfiles" required></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col class="sm-2">
                            <label for="rol">Rol</label>
                        </b-col>
                        <b-col class="sm-9">
                            <b-form-select v-model="user.ROL" class="form-control" :options="roles" required></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col class="sm-2">
                            <label for="caducidad">Caducidad</label>
                        </b-col>
                        <b-col class="sm-9">
                            <b-form-datepicker id="caducidad" v-model="user.CADUCIDAD" required></b-form-datepicker>
                        </b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col class="sm-2">
                            <label for="cargo">Cargo</label>
                        </b-col>
                        <b-col class="sm-9">
                            <b-form-input v-model="user.CARGO" name="cargo" placeholder="Cargo" required></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col class="sm-2">
                            <label for="entidad">Entidad</label>
                        </b-col>
                        <b-col class="sm-9">
                            <b-form-select v-model="user.ENTIDAD" class="form-control" :options="entidades" required></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col class="sm-2">
                            <label for="estado">Estado</label>
                        </b-col>
                        <b-col class="sm-9">
                            <b-form-select v-model="user.estado" class="form-control" :options="estados" required></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col class="sm-2">
                            <label for="password">Contraseña</label>
                        </b-col>
                        <b-col class="sm-9">
                            <b-form-input v-model="user.password" type="password" name="password" placeholder="****" required></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col class="sm-2">
                            <label for="password">Repite contraseña</label>
                        </b-col>
                        <b-col class="sm-9">
                            <b-form-input v-model="user.c_password" type="password" name="password" placeholder="****" required></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-button type="submit">Registrar</b-button>
                        </b-col>
                        <b-col>
                            <b-button hidden variant="outline-primary" type="reset">Actualizar</b-button>
                        </b-col>
                        <b-col></b-col>
                        <b-col>
                            <b-button hidden variant="danger">Eliminar</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-container>

        </b-modal>                
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'register',
    data () {
        return {
            showFormModal: false,
            user: {
                id: '',
                name: '',
                usuario: '',
                email: '',
                perfil: '',
                ROL: '',
                CADUCIDAD: '',
                CARGO: '',
                ENTIDAD: '',
                estado: '',
                password: '',
                c_password: ''
            },
            filter: "",
            totalRows: 1,
            currentPage: 1,
            perPage: 6,
            users: [],
            fields: [
                'id',
                'name',
                'usuario',
                'email',
                'perfil',
                'estado',
                'actions' // this is a virtual column, that does not exist in our `items`
            ],
            estados: [
                { value: 'ACTIVO', text: 'ACTIVO' },
                { value: 'INACTIVO', text: 'INACTIVO' }
            ],
            perfiles: [
                { value: 'ADMINISTRADOR', text: 'ADMINISTRADOR' },
                { value: 'ANALISTA', text: 'ANALISTA' },
                { value: 'ALBERGUE', text: 'ALBERGUE' },
                { value: 'GESTION_SOCIAL', text: 'GESTION_SOCIAL' },
                { value: 'CONSULTA', text: 'CONSULTA' }
            ],
            roles: [
                { value: 1, text: "ADMINISTRADOR" },
                { value: 2, text: "CONSULTA" },
                { value: 3, text: "GESTOR" },
                { value: 4, text: "PROFESIONAL" }
            ],
            entidades: [
                { value: 1, text: "EARV MEDELLIN" },
                { value: 2, text: "UNIDAD DE VICTIMAS - UARIV" },
            ]
        }
    },
    props: ['adminLogged'],
    methods: {
        submitRegister () {
            let url = this.$store.getters.getApiUrl
            this.token = this.$store.getters.theToken
            const params = new URLSearchParams()
            let perfil = 0;
            switch(this.user.perfil) { 
                case 'ADMINISTRADOR': { 
                    perfil = 2;
                    break; 
                } 
                case 'ANALISTA': { 
                    perfil = 22;
                    break; 
                }
                case 'ALBERGUE': {
                    perfil = 18;
                    break;
                }
                case 'GESTION_SOCIAL': {
                    perfil = 10;
                    break;
                }
                case 'CONSULTA': {
                    perfil = 1;
                    break;
                }
            }
            let estado = (this.user.estado == 'ACTIVO')?1:0
            params.append('name', this.user.name.toUpperCase())
            params.append('usuario', this.user.usuario)
            params.append('email', this.user.email)
            params.append('perfil', perfil)
            params.append('estado', estado)
            params.append('password', this.user.password)
            params.append('c_password', this.user.c_password)
            axios.post( url + 'register', 
                params,
            {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.token}`
            }
            })
            .then(() => {
                this.cargarUsuarios()
                alert ("Usuario registrado")
                this.$bvModal.hide('formModal') 
            })
            .catch((error) => {
                console.error(error)
            })
        },
        cargarForm(item){
            this.user.id = item['id']
            this.user.name = item['name']
            this.user.usuario = item['usuario']
            this.user.email = item['email']
            this.user.perfil = item['perfil']
            this.user.estado = item['estado']  
            this.$bvModal.show('formModal')            
        },
        limpiarForm (){
            this.user.id = ''
            this.user.name = ''
            this.user.usuario = ''
            this.user.email = ''
            this.user.perfil = ''
            this.user.estado = ''
            this.user.password = ''
            this.user.c_password = ''
        },
        cargarUsuarios () {
            this.token = this.$store.getters.theToken
            let url = this.$store.getters.getApiUrl
            axios.get( url + 'users', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token}`          
            }
            })
            .then((res) => {            
                this.users = res.data
                this.totalRows = this.users.length            
            })
            .catch((error) => {
                if(error.response.status == 401){
                    this.$store.dispatch('logout')
                    .then(() => {
                        this.$router.push('/login')
                    })
                }           
            })
        }
    },
    mounted (){
        this.cargarUsuarios()
    }
}
</script>