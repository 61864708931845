
<template>
    <div>
        <navbar/>
        <h1>Revision DX</h1>
        <p v-if="!perfilPermitido">Usted no puede acceder a esta area.</p>
        <b-container v-if="perfilPermitido">
            <b-row>                
                <b-col>
                    <b-input-group>
                        <b-form-datepicker 
                            locale='es' 
                            id="fechaInicio" 
                            v-model="fechaInicio" 
                            class="mb-4"
                            :min="minDate" :max="maxDate"></b-form-datepicker>
                        <b-form-datepicker 
                            locale='es' 
                            id="fechaFin" 
                            v-model="fechaFin" 
                            class="mb-4"
                            :min="minDate" :max="maxDate"></b-form-datepicker>
                    <b-input-group-append>
                        <b-button @click="buscarDxs()">Mostrar</b-button>
                    </b-input-group-append>
                    </b-input-group>                    
                </b-col>                
            </b-row>
        </b-container>
        <b-container  v-if="perfilPermitido" fluid>
            <b-row>
                <b-col lg="6" class="my-1">
                    <b-form-group
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-3"
                        >
                        <b-input-group size="sm">
                            <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Escribe la busqueda"
                            ></b-form-input>

                            <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Limpiar</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <div style="height: 600px;">
                <b-table responsive
                    :sticky-header="stickyHeader"
                    :striped="striped"
                    :bordered="bordered"
                    :items="dxs"
                    :fields="fields"
                    :filter="filter"
                    >
                    <template #cell(actions)="row">
                        <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">
                        Ver
                        </b-button>                    
                    </template>
                </b-table>
            </div>
            
            <!-- Info modal -->
            <b-modal :id="infoModal.id" size="xl" :title="infoModal.title" hide-footer>
                <b-container>
                    <b>RESPONSABLE:</b> {{ infoModal.content.dx_7_1_nombre_responsable_atencion }}
                    <b-form @submit.prevent="submitDx">
                        <b-row>
                            <b-col>
                                <b>ID:</b> {{ infoModal.content.id }}
                                <b>FECHA:</b> {{ infoModal.content.dx_fecha }}, 
                                <b>NOMBRES:</b> {{ infoModal.content.id_1_1_1__p_nombre }} 
                                {{ infoModal.content.id_1_1_2__s_nombre }} 
                                {{ infoModal.content.id_1_1_3__p_apellido }} 
                                {{ infoModal.content.id_1_1_4__s_apellido }}, 
                                <b>DOCUMENTO:</b> {{ infoModal.content.id_1_2_2__n_documento }}, 
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-input-group prepend="FECHA HECHO:">
                                    <b-form-datepicker v-model="infoModal.content.vict_4_5_fecha_hecho" class="mb-2"></b-form-datepicker>
                                </b-input-group>
                            </b-col>
                            
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-input-group prepend="MUNICIPIO:">
                                    <b-form-select class="form-control" :selected="infoModal.content.vict_4_4_2__municipio" @change="setLugarHecho" v-model="infoModal.content.vict_4_4_2__municipio" required>
                                        <b-form-select-option v-for="municipio in lugares.municipio" :key="municipio.id" :value="municipio.id">{{ municipio.municipio }}</b-form-select-option>    
                                    </b-form-select>
                                </b-input-group>                                
                            </b-col>
                            <b-col>                                
                                <b>DEPARTAMENTO:</b> {{ infoModal.content.departamentoHecho }},                                
                                <b>SUBREGION:</b>  {{ infoModal.content.vict_4_4_3__subregion_antioquia }},
                                <b>TIPO MOVILIDAD:</b> {{ lugares.tipoMovilidad[infoModal.content.vict_4_4_6__tipo_movilidad] }},                                                                 
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-input-group prepend="LUGAR EXACTO:">
                                    <b-form-select class="form-control" :selected='infoModal.content.vict_4_4_4__lugar_exacto_del_municipio' v-model="infoModal.content.vict_4_4_4__lugar_exacto_del_municipio" :options="lugarExacto"></b-form-select>
                                </b-input-group>
                            </b-col>
                            <b-col>
                                <b-input-group prepend="ENTORNO:">
                                    <b-input v-model="infoModal.content.vict_4_4_5__nombre_tipo_entorno"></b-input>
                                </b-input-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <h5>Ubicación del hecho intraurbano</h5>
                            <b-col>
                                <b-input-group prepend="BARRIO:">
                                    <b-form-select class="form-control" :selected="infoModal.content.vict_4_4_8__hecho_Intraurbano_Barrio" @change="setBarrioHecho" v-model="infoModal.content.vict_4_4_8__hecho_Intraurbano_Barrio">
                                        <b-form-select-option v-for="barrio in lugares.barrio" :key="barrio.id" :value="barrio.id">{{ barrio.barrio }}</b-form-select-option>
                                    </b-form-select>
                                </b-input-group>                                
                            </b-col>
                            <b-col>
                                <b>COMUNA:</b> {{ infoModal.content.vict_4_4_7__hecho_Intraurbano_Comuna_o_Corregimiento }} - {{ infoModal.content.comunaHecho }}                                
                            </b-col> 
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-input-group prepend="SECTOR INTRAURBANO:">
                                    <b-input v-model="infoModal.content.vict_4_4_9__hecho_Intraurbano_Sector_o_Asentamiento"></b-input>
                                </b-input-group>                                
                            </b-col>
                            <b-col>
                                <b-input-group prepend="DIRECCION:">
                                    <b-input v-model="infoModal.content.vict_4_4_10__hecho_Intraurbano_Direccion"></b-input>
                                </b-input-group>
                            </b-col>
                        </b-row>                        
                        <b-row>
                            <h5>Ubicación arribo</h5>
                            <b-col>
                                <b-input-group prepend="BARRIO:">
                                    <b-form-select class="form-control" :selected="infoModal.content.ubi_3_2__barrio_o_vereda" @change="setBarrioArribo" v-model="infoModal.content.ubi_3_2__barrio_o_vereda" required>
                                        <b-form-select-option v-for="barrio in lugares.barrio" :key="barrio.id" :value="barrio.id">{{ barrio.barrio }}</b-form-select-option>
                                    </b-form-select>
                                </b-input-group>                                
                            </b-col>
                            <b-col>
                                <b>COMUNA:</b> {{ infoModal.content.ubi_3_1__comuna_o_corregimiento }} - {{ infoModal.content.comunaArribo }}                                
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-input-group prepend="SECTOR INTRAURBANO:">
                                    <b-input v-model="infoModal.content.ubi_3_3__asentamiento_o_sector"></b-input>
                                </b-input-group>                                
                            </b-col>
                            <b-col>
                                <b-input-group prepend="DIRECCION:">
                                    <b-input v-model="infoModal.content.ubi_3_4__direccion"></b-input>
                                </b-input-group>
                            </b-col>
                        </b-row>                        
                        <b-row>
                            <h5>Actor armados responsables</h5>
                            <b-col>
                                <b-input-group prepend="ACTOR ARMADO 1:">
                                    <b-form-select class="form-control" :selected="infoModal.content.vict_4_2__actor_armado_ocasiono_el_hecho_victimizante" v-model="infoModal.content.vict_4_2__actor_armado_ocasiono_el_hecho_victimizante">
                                        <b-form-select-option v-for="actor in lugares.actorArmado" :key="actor.value" :value="actor.value">{{ actor.text }}</b-form-select-option>
                                    </b-form-select>
                                </b-input-group>  
                            </b-col>  
                            <b-col>
                                <b-input-group prepend="ACTOR ARMADO 2:">
                                    <b-form-select class="form-control" :selected="infoModal.content.vict_4_2_1_actor_armado_ocasiono_el_hecho_victimizante" v-model="infoModal.content.vict_4_2_1_actor_armado_ocasiono_el_hecho_victimizante">
                                        <b-form-select-option v-for="actor in lugares.actorArmado" :key="actor.value" :value="actor.value">{{ actor.text }}</b-form-select-option>
                                    </b-form-select>
                                </b-input-group>  
                            </b-col>
                            <b-col>
                                <b-input-group prepend="OTRO ACTOR ARMADO">
                                    <b-input v-model="infoModal.content.vict_4_2_1_1_Si_la_Respuesta_anterior_es_OTROS_cual_Identificar"></b-input>
                                </b-input-group>
                            </b-col>    
                        </b-row>  
                        <b-row>
                            <h5>Hechos declarados</h5>
                        </b-row>    
                        <b-row>
                            <b-col cols="3">
                                <b-input-group prepend="Desplazamiento">
                                    <b-input type="number" min="0" max="1" v-model="infoModal.content.vict_4_1_3__desplazamiento_forzado"></b-input>
                                </b-input-group>
                            </b-col>
                            <b-col cols="3">
                                <b-input-group prepend="Homicidio">
                                    <b-input type="number" min="0" max="1" v-model="infoModal.content.vict_4_1_4__homicidio"></b-input>
                                </b-input-group>
                            </b-col>
                            <b-col cols="3">
                                <b-input-group prepend="Masacre">
                                    <b-input type="number" min="0" max="1" v-model="infoModal.content.vict_4_1_5__masacre"></b-input>
                                </b-input-group>
                            </b-col>
                            <b-col cols="3">
                                <b-input-group prepend="Tortura">
                                    <b-input type="number" min="0" max="1" v-model="infoModal.content.vict_4_1_6__Tortura_o_tratos_inhumanos_degradantes"></b-input>
                                </b-input-group>
                            </b-col>                           
                        </b-row>
                        <b-row>
                            <b-col cols="3">
                                <b-input-group prepend="Lesiones">
                                    <b-input type="number" min="0" max="1" v-model="infoModal.content.vict_4_1_7__lesiones_permanentes_o_transitorias"></b-input>
                                </b-input-group>
                            </b-col> 
                            <b-col cols="3">
                                <b-input-group prepend="Minas">
                                    <b-input type="number" min="0" max="1" v-model="infoModal.content.vict_4_1_8__minas_antipersonal_municiones_sin_Explotar"></b-input>
                                </b-input-group>
                            </b-col>
                            <b-col cols="3">
                                <b-input-group prepend="Delito sexual">
                                    <b-input type="number" min="0" max="1" v-model="infoModal.content.vict_4_1_9__delitos_contra_la_libertad_y_la_integridad_sexual"></b-input>
                                </b-input-group>
                            </b-col>
                            <b-col cols="3">
                                <b-input-group prepend="Intento reclutamiento">
                                    <b-input type="number" min="0" max="1" v-model="infoModal.content.vict_4_1_10_1__intento_de_reclutamiento"></b-input>
                                </b-input-group>
                            </b-col>                                                        
                        </b-row>
                        <b-row>
                            <b-col cols="3">
                                <b-input-group prepend="Reclutamiento">
                                    <b-input type="number" min="0" max="1" v-model="infoModal.content.vict_4_1_10_2__reclutamiento_y_utilizacion"></b-input>
                                </b-input-group>
                            </b-col>
                            <b-col cols="3">
                                <b-input-group prepend="acto terorista">
                                    <b-input type="number" min="0" max="1" v-model="infoModal.content.vict_4_1_11__acto_terrorista"></b-input>
                                </b-input-group>
                            </b-col>
                            <b-col cols="3">
                                <b-input-group prepend="Amenaza">
                                    <b-input type="number" min="0" max="1" v-model="infoModal.content.vict_4_1_12__amenaza"></b-input>
                                </b-input-group>
                            </b-col>
                            <b-col cols="3">
                                <b-input-group prepend="Abandono">
                                    <b-input type="number" min="0" max="1" v-model="infoModal.content.vict_4_1_13__despojo_y_abandono_forzado_de_tierras"></b-input>
                                </b-input-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                               <b>Observaciones: </b>
                               <p>{{ infoModal.content.vict_4_7__observaciones }}</p>
                            </b-col>
                        </b-row>
                        <br>
                        <b-row align-h="end">
                            <b-col cols="3">
                                <b-button variant="primary" type="submit">Guardar</b-button>
                            </b-col>                            
                        </b-row>
                    </b-form>                    
                </b-container>
            </b-modal>
        </b-container>
    </div>
</template>
<script>
import lugares from './lugares.js'
import Navbar from '@/components/Navbar.vue';
import axios from 'axios'
export default {
    name: 'Revisiondx',
    components: {
        Navbar
    },
    data () {
        return {
            perfil: localStorage.getItem("userPerfil"),
            perfilPermitido: false,
            token: '',
            fechaInicio: '',
            fechaFin: '',
            minDate: '2019-07-01',
            maxDate: new Date(),
            dxs: [],
            lugares: lugares,
            filter: null,
            striped: true,
            bordered: true,
            stickyHeader: "800px",
            fields: [
                { key: 'dx_fecha', label: 'Fecha dx' },
                { key: 'id_1_1_1__p_nombre', label: 'Primer nombre'},
                { key: 'id_1_1_2__s_nombre', label: 'Segundo nombre' },
                { key: 'id_1_1_3__p_apellido', label: 'Primer apellido' },
                { key: 'id_1_1_4__s_apellido', label: 'Segundo apellido'},
                { key: 'id_1_2_2__n_documento', label: 'Documento', stickyColumn: true },
                { key: 'comunaArribo', label: 'Comuna arribo' },
                { key: 'barrioArribo', label: 'Barrio arribo' },
                { key: 'ubi_3_3__asentamiento_o_sector', label: 'Sector arribo' },
                { key: 'ubi_3_4__direccion', label: 'Direccion arribo' },
                { key: 'departamentoHecho', label: 'Departamento' },
                { key: 'municipioHecho', label: 'Municipio' },
                { key: 'vict_4_4_3__subregion_antioquia', label: 'Subregion' },
                { key: 'lugarExacto', label: 'Lugar exacto' },
                { key: 'vict_4_4_5__nombre_tipo_entorno', label: 'Entorno' },
                { key: 'tipoMovilidad', label: 'Tipo movilidad' },
                { key: 'comunaHecho', label: 'Comuna hecho' },
                { key: 'barrioHecho', label: 'Barrio hecho' },
                { key: 'vict_4_4_9__hecho_Intraurbano_Sector_o_Asentamiento', label: 'Sector hecho' },
                { key: 'vict_4_4_10__hecho_Intraurbano_Direccion', label: 'Dirección hecho' },
                { key: 'vict_4_5_fecha_hecho', label: 'Fecha ocurrencia' },
                { key: 'vict_4_1_3__desplazamiento_forzado', label: 'Desplazamiento' },
                { key: 'vict_4_1_4__homicidio', label: 'Homicidio' },
                { key: 'vict_4_1_5__masacre', label: 'Masacre' },
                { key: 'vict_4_1_6__Tortura_o_tratos_inhumanos_degradantes', label: 'Tortura' },
                { key: 'vict_4_1_7__lesiones_permanentes_o_transitorias', label: 'Lesiones' },
                { key: 'vict_4_1_8__minas_antipersonal_municiones_sin_Explotar', label: 'Minas' },
                { key: 'vict_4_1_9__delitos_contra_la_libertad_y_la_integridad_sexual', label: 'Delito sexual' },
                { key: 'vict_4_1_10_1__intento_de_reclutamiento', label: 'Intento reclutamiento' },
                { key: 'vict_4_1_10_2__reclutamiento_y_utilizacion', label: 'Reclutamiento' },
                { key: 'vict_4_1_11__acto_terrorista', label: 'Acto terrorista' },
                { key: 'vict_4_1_12__amenaza', label: 'Amenaza' },
                { key: 'vict_4_1_13__despojo_y_abandono_forzado_de_tierras', label: 'Despojo tierras' },
                { key: 'actor1', label: 'Actor armado 1' },
                { key: 'actor2', label: 'Actor armado 2' },
                { key: 'vict_4_2_1_1_Si_la_Respuesta_anterior_es_OTROS_cual_Identificar', label: 'Otro actor armado' },
                { key: 'dx_7_1_nombre_responsable_atencion', label: 'Responsable' },
                { key: 'actions', label: 'Acciones' }
            ],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            lugarExacto: [
                { value: '0', text: 'Sin dato'},
                { value: '1', text: '1 - Cabecera municipal'},
                { value: '2', text: '2 - Barrio'},
                { value: '3', text: '3 - Corregimiento'},
                { value: '3', text: '4 - Vereda'}                
            ]
        }
    },
    methods: {
        buscarDxs () {
            this.token = this.$store.getters.theToken
            let url = this.$store.getters.getApiUrl
            axios.get( url + 'diagnosticoFecha', 
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                },
                params: { 
                    fechaInicial: this.fechaInicio,
                    fechaFinal: this.fechaFin
                }
            })
            .then((res) => {           
                this.dxs = res.data.data
                this.dxs.forEach(fila => {                    
                    let barrio = fila.vict_4_4_8__hecho_Intraurbano_Barrio
                    lugares.barrio.some( item => {
                        if(item.id == barrio){
                            fila.barrioHecho = item.barrio             
                            fila.comunaHecho = item.comuna                    
                        }
                    })        
                    
                    barrio = fila.ubi_3_2__barrio_o_vereda
                    lugares.barrio.some( item => {
                        if(item.id == barrio){
                            fila.barrioArribo = item.barrio             
                            fila.comunaArribo = item.comuna                    
                        }
                    })

                    let municipio = fila.vict_4_4_2__municipio
                    lugares.municipio.some( item => {
                        if(item.id == municipio){
                            fila.municipioHecho = item.municipio             
                            fila.departamentoHecho = item.departamento                    
                        }
                    })

                    fila.lugarExacto = lugares.lugarExacto[fila.vict_4_4_4__lugar_exacto_del_municipio]
                    fila.tipoMovilidad = lugares.tipoMovilidad[fila.vict_4_4_6__tipo_movilidad]
                    
                    let actor = fila.vict_4_2__actor_armado_ocasiono_el_hecho_victimizante
                    lugares.actorArmado.some( item => {
                        if(item.value == actor){
                            fila.actor1 = item.text
                        }
                    })

                    actor = fila.vict_4_2_1_actor_armado_ocasiono_el_hecho_victimizante
                    lugares.actorArmado.some( item => {
                        if(item.value == actor){
                            fila.actor2 = item.text
                        }
                    })
                })        
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        info(item, index, button) {
            item.indice = index
            this.resetInfoModal()
            this.infoModal.title = `Indice fila: ${index}`
            this.infoModal.content = item
            this.setLugarHecho()
            this.setBarrioHecho()
            this.setBarrioArribo()
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        submitDx: function () {
            alert(this.infoModal.content.indice)
                                   
            let url = this.$store.getters.getApiUrl
            this.token = this.$store.getters.theToken         
            const params = new URLSearchParams()            
            params.append('vict_4_5_fecha_hecho', this.infoModal.content.vict_4_5_fecha_hecho)
            params.append('vict_4_4_2__municipio', this.infoModal.content.vict_4_4_2__municipio)
            params.append('vict_4_4_1__departamento', this.infoModal.content.vict_4_4_1__departamento)            
            params.append('vict_4_4_3__subregion_antioquia', this.infoModal.content.vict_4_4_3__subregion_antioquia)            
            params.append('vict_4_4_5__nombre_tipo_entorno', this.infoModal.content.vict_4_4_5__nombre_tipo_entorno)            
            params.append('vict_4_4_6__tipo_movilidad', this.infoModal.content.vict_4_4_6__tipo_movilidad)            
            params.append('vict_4_4_4__lugar_exacto_del_municipio', this.infoModal.content.vict_4_4_4__lugar_exacto_del_municipio)            
            params.append('vict_4_4_8__hecho_Intraurbano_Barrio', this.infoModal.content.vict_4_4_8__hecho_Intraurbano_Barrio)            
            params.append('vict_4_4_7__hecho_Intraurbano_Comuna_o_Corregimiento', this.infoModal.content.vict_4_4_7__hecho_Intraurbano_Comuna_o_Corregimiento)            
            params.append('vict_4_4_9__hecho_Intraurbano_Sector_o_Asentamiento', this.infoModal.content.vict_4_4_9__hecho_Intraurbano_Sector_o_Asentamiento)            
            params.append('vict_4_4_10__hecho_Intraurbano_Direccion', this.infoModal.content.vict_4_4_10__hecho_Intraurbano_Direccion)            
            params.append('ubi_3_1__comuna_o_corregimiento', this.infoModal.content.ubi_3_1__comuna_o_corregimiento)            
            params.append('ubi_3_2__barrio_o_vereda', this.infoModal.content.ubi_3_2__barrio_o_vereda)            
            params.append('ubi_3_3__asentamiento_o_sector', this.infoModal.content.ubi_3_3__asentamiento_o_sector)
            params.append('ubi_3_4__direccion', this.infoModal.content.ubi_3_4__direccion)           
            params.append('vict_4_2__actor_armado_ocasiono_el_hecho_victimizante', this.infoModal.content.vict_4_2__actor_armado_ocasiono_el_hecho_victimizante)            
            params.append('vict_4_2_1_actor_armado_ocasiono_el_hecho_victimizante', this.infoModal.content.vict_4_2_1_actor_armado_ocasiono_el_hecho_victimizante)            
            params.append('vict_4_2_1_1_Si_la_Respuesta_anterior_es_OTROS_cual_Identificar', this.infoModal.content.vict_4_2_1_1_Si_la_Respuesta_anterior_es_OTROS_cual_Identificar)            
            params.append('vict_4_1_3__desplazamiento_forzado', this.infoModal.content.vict_4_1_3__desplazamiento_forzado)            
            params.append('vict_4_1_4__homicidio', this.infoModal.content.vict_4_1_4__homicidio)            
            params.append('vict_4_1_5__masacre', this.infoModal.content.vict_4_1_5__masacre)            
            params.append('vict_4_1_6__Tortura_o_tratos_inhumanos_degradantes', this.infoModal.content.vict_4_1_6__Tortura_o_tratos_inhumanos_degradantes)            
            params.append('vict_4_1_7__lesiones_permanentes_o_transitorias', this.infoModal.content.vict_4_1_7__lesiones_permanentes_o_transitorias)            
            params.append('vict_4_1_8__minas_antipersonal_municiones_sin_Explotar', this.infoModal.content.vict_4_1_8__minas_antipersonal_municiones_sin_Explotar)            
            params.append('vict_4_1_9__delitos_contra_la_libertad_y_la_integridad_sexual', this.infoModal.content.vict_4_1_9__delitos_contra_la_libertad_y_la_integridad_sexual)            
            params.append('vict_4_1_10_1__intento_de_reclutamiento', this.infoModal.content.vict_4_1_10_1__intento_de_reclutamiento)            
            params.append('vict_4_1_10_2__reclutamiento_y_utilizacion', this.infoModal.content.vict_4_1_10_2__reclutamiento_y_utilizacion)            
            params.append('vict_4_1_11__acto_terrorista', this.infoModal.content.vict_4_1_11__acto_terrorista)            
            params.append('vict_4_1_12__amenaza', this.infoModal.content.vict_4_1_12__amenaza)            
            params.append('vict_4_1_13__despojo_y_abandono_forzado_de_tierras', this.infoModal.content.vict_4_1_13__despojo_y_abandono_forzado_de_tierras)        

            axios.post( url + 'saverevisiondx/' + this.infoModal.content.id, 
                params,
            {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.token}`
            }
            })
            .then(() => {                        
                alert ("dx guardado.")
                this.$bvModal.hide(this.infoModal.id) 
                this.buscarDxs() 
            })
            .catch((error) => {
                this.iraLogin(error)
            })


        },
        iraLogin: function (error) {
            if(error.response && error.response.status == 401){
                this.$store.dispatch('logout')
                this.$router.push('/login') 
            }
        },
        setLugarHecho: function () {
            let th = this
            let municipio = th.infoModal.content.vict_4_4_2__municipio
            lugares.municipio.some(function(item){                        
                if(item.id == municipio){                   
                    th.infoModal.content.vict_4_4_1__departamento = item.cod_dept
                    th.infoModal.content.departamentoHecho = item.departamento
                    th.infoModal.content.vict_4_4_3__subregion_antioquia = item.subregion
                    if(item.id == 12){
                        th.infoModal.content.vict_4_4_6__tipo_movilidad = 3
                    }else if(item.cod_dept == 2){
                        th.infoModal.content.vict_4_4_6__tipo_movilidad = 2
                    }else {
                        th.infoModal.content.vict_4_4_6__tipo_movilidad = 1
                    }
                }
            })
        },
        setBarrioHecho: function () {
            let th = this
            let barrio = this.infoModal.content.vict_4_4_8__hecho_Intraurbano_Barrio
            lugares.barrio.some(function(item){                        
                if(item.id == barrio){
                    th.infoModal.content.barrioHecho = item.barrio
                    th.infoModal.content.vict_4_4_7__hecho_Intraurbano_Comuna_o_Corregimiento = item.idcomuna
                    th.infoModal.content.comunaHecho = item.comuna                    
                }
            })
        },
        setBarrioArribo: function () {
            let th = this
            let barrio = this.infoModal.content.ubi_3_2__barrio_o_vereda
            lugares.barrio.some(function(item){                        
                if(item.id == barrio){                   
                    th.infoModal.content.barrioArribo = item.barrio
                    th.infoModal.content.ubi_3_1__comuna_o_corregimiento = item.idcomuna
                    th.infoModal.content.comunaArribo = item.comuna                    
                }
            })
        }
    },
    mounted () {
        if(this.perfil == "ANALISTA" || this.perfil == "ADMINISTRADOR"){
            this.perfilPermitido = true
        }
    }
}
</script>
