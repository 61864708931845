<template>
    <div>
        <b-navbar toggleable="lg" type="dark" variant="secondary ">
        <a class="navbar-item">
            <img src="./../assets/dx.png" alt="Logo">
        </a>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
            <b-nav-item>
                {{sede}}
            </b-nav-item>
            <b-nav-item v-if="isLoggedIn">
                <router-link to="/">Inicio</router-link>
            </b-nav-item>
            <b-nav-item v-if="isLoggedIn">
                <router-link to="/about">Acerca de</router-link>
            </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav v-if="isLoggedIn" class="ml-auto">            

            <b-nav-item-dropdown text="Configs" right>
            <b-dropdown-item>
                <b-button variant="outlet-primary" v-b-modal.changepass>Cambiar mi contraseña</b-button>
            </b-dropdown-item>
            <b-dropdown-item v-if="isLoggedIn">
                <b-button variant="outlet-primary">
                    <router-link to="/configs">Configs</router-link>
                </b-button>                
            </b-dropdown-item>
            <b-dropdown-item v-if="isLoggedIn">
                <b-button variant="outlet-primary">
                    <router-link to="/sede">Sede</router-link>
                </b-button>                
            </b-dropdown-item>          
            </b-nav-item-dropdown>

            <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
                <em>{{user}}</em>
            </template>
            <b-dropdown-item>{{perfil}}</b-dropdown-item>
            <b-dropdown-item v-if="isLoggedIn">
                <a @click="logout">Salida</a>
            </b-dropdown-item>
            </b-nav-item-dropdown>            
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav v-if="!isLoggedIn" class="ml-auto">
            <b-button v-if="!isLoggedIn"  variant="secondary" right>
                <router-link to="/login">Ingreso</router-link>
            </b-button>
        </b-navbar-nav>


        
        </b-collapse>
    </b-navbar>

    <b-container fluid>
      <b-row align-v="start">
        <b-col cols="1"><b-button squared v-b-toggle.sidebar-1>Menu</b-button></b-col>        
      </b-row>
    </b-container> 
    <b-sidebar 
      id="sidebar-1" 
      no-header
      width="250px"
      :backdrop-variant="variant"
      backdrop shadow>
      <div class="px-3 py-2">
        <nav class="mb-3">
          <b-nav vertical>
            <b-nav-item v-if="isLoggedIn">
                <router-link to="/caracterizacion">Caracterizacion DFI</router-link>
            </b-nav-item>
            <b-nav-item v-if="isLoggedIn">
                <router-link to="/revisiondx">Revision Dx</router-link>
            </b-nav-item>
            <b-nav-item v-if="isLoggedIn">
                <router-link to="/albergues">Albergues</router-link>
            </b-nav-item>
            <b-nav-item v-if="isLoggedIn">
                <router-link to="/informealbergue">Informe albergues</router-link>
            </b-nav-item>
            <b-nav-item v-if="isLoggedIn">
                <router-link to="/configs">Configs</router-link>
            </b-nav-item>
            <b-nav-item v-if="isLoggedIn">
                <router-link to="/about">Acerca de</router-link>
            </b-nav-item>
          </b-nav>
        </nav>
      </div>
    </b-sidebar>

    <b-modal id="changepass" title="Cambiar mi contraseña" hide-footer>
        <b-container>
            <b-form @submit.prevent="submitChangepass">
                <b-row>
                    <b-col class="sm-2">
                        <label for="usuario">Usuario</label>
                    </b-col>
                    <b-col class="sm-9">
                        <b-form-input disabled v-model="usuario.usuario" name="usuario" placeholder="Usuario" required></b-form-input>
                    </b-col>
                </b-row>                
                <b-row>
                    <b-col class="sm-2">
                        <label for="pass">Nueva contraseña</label>
                    </b-col>
                    <b-col class="sm-9">
                        <b-form-input type="password" v-model="usuario.pass" name="pass" placeholder="*****" required></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sm-2">
                        <label for="c_pass">Repita nueva contraseña</label>
                    </b-col>
                    <b-col class="sm-9">
                        <b-form-input type="password" v-model="usuario.c_pass" name="c_pass" placeholder="*****" required></b-form-input>
                    </b-col>
                </b-row>
                <b-button type="submit">Guardar</b-button>
            </b-form>
        </b-container>        
    </b-modal>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'Navbar',
    data () {
        return {
            variant: 'dark',
            sede: localStorage.getItem('sede'),
            user: localStorage.getItem('userName'),
            perfil: localStorage.getItem('userPerfil'),
            usuario: {
                usuario: localStorage.getItem('usuario'),
                pass: '',
                c_pass: ''
            }
        }
    },
    computed : {
        isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
    },
    methods: {
        submitChangepass: function() {
            let url = this.$store.getters.getApiUrl
            this.token = this.$store.getters.theToken     
            const params = new URLSearchParams()
            params.append('usuario', localStorage.getItem('usuario'))
            params.append('pass', this.usuario.pass)
            params.append('c_pass', this.usuario.c_pass)
            axios.post( url + 'changePass', 
                params,
            {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.token}`
            }
            })
            .then(() => {
                alert ("Contraseña actualizada")
                this.$bvModal.hide('changepass') 
            })
            .catch((error) => {
                console.error(error)
            })
        },
        logout: function () {
        this.$store.dispatch('logout')
        .then(() => {
            this.$router.push('/login')
        })
        }
    },
}
</script>