<template>
    <div>
        <Navbar/>
        <h1>Caracterización DFI</h1>
        <p v-if="!perfilPermitido">Usted no puede acceder a esta area.</p>
        <b-container v-if="perfilPermitido">            
            <b-row> 
                <b-col>
                    <b-input-group>
                        <b-form-datepicker 
                        locale='es' 
                        id="fechaInicio" 
                        v-model="fechaInicio" 
                        class="mb-3"
                        :min="minDate" :max="maxDate"></b-form-datepicker>
                        <b-form-datepicker 
                        locale='es' 
                        id="fechaFin" 
                        v-model="fechaFin" 
                        class="mb-3"
                        :min="minDate" :max="maxDate"></b-form-datepicker>
                        <b-input-group-append>
                            <b-button @click="buscarDxs()">Mostrar</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>
        </b-container>

        <b-container fluid v-if="perfilPermitido">
            <b-row>
                <b-col lg="6" class="my-1">
                    <b-form-group
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-input-group size="sm">
                            <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Escribe la busqueda"
                            ></b-form-input>

                            <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Limpiar</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-table responsive
                :sticky-header="stickyHeader"
                :striped="striped"
                :bordered="bordered"
                :items="dxs"
                :fields="fields"
                :filter="filter"
                >
                <template #cell(actions)="row">
                    <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">
                    Ver
                    </b-button>                    
                </template>
            </b-table>
            <!-- Info modal -->
            <b-modal :id="infoModal.id" size="xl" :title="infoModal.title" hide-footer>
                <b-container>
                    <b-form @submit.prevent="submitCategoria">
                        <b-row>
                            <b-col>
                                <b>ID:</b> {{ infoModal.content.id }}
                                <b>FECHA:</b> {{ infoModal.content.dx_fecha }}, 
                                <b>NOMBRES:</b> {{ infoModal.content.id_1_1_1__p_nombre }} 
                                {{ infoModal.content.id_1_1_2__s_nombre }} 
                                {{ infoModal.content.id_1_1_3__p_apellido }} 
                                {{ infoModal.content.id_1_1_4__s_apellido }} 
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b>DOCUMENTO:</b> {{ infoModal.content.id_1_2_2__n_documento }}, 
                                <b>DEPARTAMENTO:</b> {{ infoModal.content.vict_4_4_1__departamento }}, 
                                <b>MUNICIPIO:</b> {{ infoModal.content.vict_4_4_2__municipio }}, 
                                <b>SUBREGION:</b> {{ infoModal.content.vict_4_4_3__subregion_antioquia }}, 
                                <b>LUGAR EXACTO:</b> {{ infoModal.content.vict_4_4_4__lugar_exacto_del_municipio }} 
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b>ENTORNO:</b> {{ infoModal.content.vict_4_4_5__nombre_tipo_entorno }}, 
                                <b>TIPO MOVILIDAD:</b> {{ lugares.tipoMovilidad[infoModal.content.vict_4_4_6__tipo_movilidad] }}, 
                                <b>COMUNA:</b> {{ infoModal.content.vict_4_4_7__hecho_Intraurbano_Comuna_o_Corregimiento }}, 
                                <b>BARRIO:</b> {{ infoModal.content.vict_4_4_8__hecho_Intraurbano_Barrio }}, 
                                <b>SECTOR INTRAURBANO:</b> {{ infoModal.content.vict_4_4_9__hecho_Intraurbano_Sector_o_Asentamiento }}, 
                                <b>DIRECCION:</b> {{ infoModal.content.vict_4_4_10__hecho_Intraurbano_Direccion }}, 
                                <b>FECHA HECHO:</b> {{ infoModal.content.vict_4_5_fecha_hecho }}
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b>RESPONSABLE:</b> {{ infoModal.content.dx_7_1_nombre_responsable_atencion }}
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b>OBSERVACIONES:</b>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                {{ infoModal.content.vict_4_7__observaciones }}
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <b>CATEGORIA:</b> <b-form-select v-model="infoModal.content.res_6_1_3__categoria" :selected="infoModal.content.res_6_1_3__categoria" :options="categorias" size="sm" class="form-control" @change="cambiarFactores(infoModal.content.res_6_1_3__categoria)"></b-form-select>
                            </b-col>
                            <b-col cols="4">
                                <b>HECHO DETERMINANTE:</b> <b-form-select v-model="infoModal.content.res_6_1_2__factor_determinante" :options="factores" :selected="infoModal.content.res_6_1_2__factor_determinante" size="sm" class="form-control"></b-form-select>
                            </b-col>
                            <b-col cols="4">
                                <b>OTRO:</b> <input class="input" type="text" placeholder="Otro" v-model="infoModal.content.res_6_1_1__factores_incidencia">
                            </b-col>
                        </b-row>
                        <br>
                        <b-row align-h="end">
                            <b-col cols="3">
                                <b-button variant="primary" type="submit">Guardar</b-button>
                            </b-col>
                            
                        </b-row>
                    </b-form>                    
                </b-container>
            </b-modal>
        </b-container>
    </div>
</template>
<script>
import axios from 'axios'
import dfi from './dfi.js'
import lugares from './lugares.js'
import Navbar from '@/components/Navbar.vue'
export default {
    name: 'caracterizacion',
    components: {
        Navbar
    },
    data () {
        return {
            perfil: localStorage.getItem("userPerfil"),
            perfilPermitido: false,
            token: '',
            fechaInicio: '',
            fechaFin: '',
            minDate: '2019-07-01',
            maxDate: new Date(),
            dxs: [],
            filter: null,
            striped: true,
            bordered: true,
            stickyHeader: "800px",
            fields: [
                { key: 'dx_fecha', label: 'Fecha dx' },
                { key: 'id_1_1_1__p_nombre', label: 'Primer nombre' },
                { key: 'id_1_1_2__s_nombre', label: 'Segundo nombre' },
                { key: 'id_1_1_3__p_apellido', label: 'Primer apellido' },
                { key: 'id_1_1_4__s_apellido', label: 'Segundo apellido'},
                { key: 'id_1_2_2__n_documento', label: 'Documento', stickyColumn: true },
                { key: 'categorianame', label: 'Categoria' },
                { key: 'factordet', label: 'Hecho determinante' },             
                { key: 'actions', label: 'Acciones' }
            ],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            categorias: [
                { value: 0, text: 'Sin dato' },
                { value: 1, text: 'Violencia homicida' },
                { value: 2, text: 'Delitos contra la integridad y seguridad personal' },
                { value: 3, text: 'Amenaza' },
                { value: 4, text: 'Extorsión' },
                { value: 5, text: 'Vinculación, reclutamiento y utilización de niños, niñas y adolescentes (NNA)'},
                { value: 6, text: 'Riesgos de género' },
                { value: 7, text: 'Despojo y uso de bienes' },
                { value: 8, text: 'Temor por violencia' },
            ],
            factores: [],
            dfi: dfi,
            lugares: lugares
        }        
    },
    methods: {
        buscarDxs () {
            this.token = this.$store.getters.theToken
            let url = this.$store.getters.getApiUrl
            axios.get( url + 'intraurbanoFecha', 
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                },
                params: { 
                    fechaInicial: this.fechaInicio,
                    fechaFinal: this.fechaFin
                }
            })
            .then((res) => {            
                this.dxs = res.data.data
                this.dxs.forEach(fila => {
                    fila.vict_4_4_2__municipio = (fila.vict_4_4_2__municipio == 12)?"MEDELLÍN":fila.vict_4_4_2__municipio
                    fila.vict_4_4_1__departamento = (fila.vict_4_4_1__departamento == 2)?"ANTIOQUIA":fila.vict_4_4_1__departamento

                    let barrio = fila.vict_4_4_8__hecho_Intraurbano_Barrio
                    lugares.barrio.some(function(item){                        
                        if(item.id == barrio){
                            fila.vict_4_4_8__hecho_Intraurbano_Barrio = item.barrio
                            fila.vict_4_4_7__hecho_Intraurbano_Comuna_o_Corregimiento = item.comuna
                        }
                    })
                    fila.vict_4_4_6__tipo_movilidad = lugares.tipoMovilidad[fila.vict_4_4_6__tipo_movilidad]
                    fila.vict_4_4_4__lugar_exacto_del_municipio = lugares.lugarExacto[fila.vict_4_4_4__lugar_exacto_del_municipio]
                    
                    let categoria = fila.res_6_1_3__categoria
                    dfi.categorias.some(function(item){                        
                        if(item.value == categoria){
                            fila.categorianame = item.text                                                                                  
                        }
                    })

                    let factor = fila.res_6_1_2__factor_determinante
                    dfi.categ.some(function(item){                        
                        if(item.id == factor){
                            fila.factordet = item.factor_incidencia                                                                                  
                        }
                    })
                })        
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        iraLogin: function (error) {
            if(error.response && error.response.status == 401){
                this.$store.dispatch('logout')
                this.$router.push('/login') 
            }
        },
        submitCategoria () {
            let url = this.$store.getters.getApiUrl
            this.token = this.$store.getters.theToken         
            const params = new URLSearchParams()            
            params.append('categoria', this.infoModal.content.res_6_1_3__categoria)
            params.append('factorIncidencia', this.infoModal.content.res_6_1_2__factor_determinante)
            params.append('otro', this.infoModal.content.res_6_1_1__factores_incidencia)            
            axios.post( url + 'categorizacion/' + this.infoModal.content.id, 
                params,
            {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.token}`
            }
            })
            .then(() => {
                this.buscarDxs()                         
                alert ("Categorización guardada.")
                this.$bvModal.hide(this.infoModal.id) 
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        info(item, index, button) {
            this.resetInfoModal()
            this.cambiarFactores(item.res_6_1_3__categoria)
            this.infoModal.title = `Indice fila: ${index}`
            this.infoModal.content = item
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        cambiarFactores(categoria) {
            switch (categoria) {
                case 0:
                    this.factores = [{ value: 0, text: 'Sin dato' }]
                    break
                case 1:
                    this.factores = dfi.vh
                    break;
                case 2:
                    this.factores = dfi.dci
                    break;
                case 3:
                    this.factores = dfi.ame
                    break;
                case 4:
                    this.factores = dfi.extor
                    break;
                case 5:
                    this.factores = dfi.vincnna
                    break;
                case 6:
                    this.factores = dfi.rgen
                    break;
                case 7:
                    this.factores = dfi.despo
                    break;
                case 8:
                    this.factores = dfi.tviolen
                    break;
                default:
                    break;
            }
        }
    },
    mounted () {
        if(this.perfil == "ANALISTA" || this.perfil == "ADMINISTRADOR"){
            this.perfilPermitido = true
        }
    }
}
</script>
