<template>  
  <div class="home">
    <Navbar/>
    <inicio msg="Bienvenido a VIDAR"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Inicio from '@/components/Inicio.vue'
import Navbar from '@/components/Navbar.vue';

export default {
  name: 'Home',
  components: {
    'inicio': Inicio,
    Navbar
  },
  mounted (){
      const formData = new FormData();
      formData.append('nombreapp', 'volkvox');
      formData.append('key', '8cb2237d0679ca88db6464eac60da96345513964');
      formData.append('idpersona', '50876157');

      fetch( "http://infovict.medellin.gov.co/api/nombre/index.php", {
        method: 'POST',
        body: formData
      })
      .then( resp => resp.json())
      .then( data => console.log(data.nombre))

  }
}
</script>
