export default {
    ingreso: {
        id: '',
        DECLARAID: '',
        FUD: '',
        TIPODOCUMENTO: '',
        DOCUMENTO: '',
        PRIMERNOMBRE: '',
        SEGUNDONOMBRE: '',
        PRIMERAPELLIDO: '',
        SEGUNDOAPELLIDO: '',
        PARENTESCO: '',
        SEDE: '',
        FECHAINGRESO: '',
        HORAINGRESO: '',
        FECHASALIDA: '',
        HORASALIDA: ''
    },
    declara: {
        id: '',  
        TIPODOCUMENTO: '',
        DOCUMENTO: '',
        PRIMERNOMBRE: '',
        SEGUNDONOMBRE: '',
        PRIMERAPELLIDO: '',
        SEGUNDOAPELLIDO: '',      
        NUCLEOVIVANTO: '0',
        NUCLEOINGRESO: '0',
        DESPLAZAMIENTO: '0',
        AMENAZA: '0',
        HOMICIDIO: '0',
        RECLUTAMIENTOFORZADO: '0',
        DESPOJOFORZADODETIERRAS: '0',
        ACTOSTERRORISTA: '0',
        DELITOSCONTRALIBERTADSEXUAL: '0',
        SECUESTRO: '0',
        DESPARICIONFORZADA: '0',
        LESIONESPERSONALES: '0',
        MINASANTIPERSONA: '0',
        TORTURA: '0',
        MASACRE: '0',
        HOSTIGAMIENTO: '0',
        VIOLENCIADEGENERO: '0',
        OTRAALTAVULNERABILIDADREMISIONES: '0',
        LUGARDECLARACION: '',
        ESTADOVICTIMA: '0',
        FECHAESTADO: '',
        FUD_FICHA: '',
        scannedCedula: '',
        actaSalida: ''
    },
    contrato: {
        id: '',
        NROCONTRATO: '',
        NOMBREALBERGUE: '',
        FECHAINICIO: '',
        FECHAFIN: '',
        VALORCONTRATO: '',
        VALORCUPODIA: '',
        PERCCUPONOUTIL: '',
        OBSERVACION: ''
    },
    asistencia: {
        id_asistencia: '',
        tipoDNI: '',
        numero: '',
        nombres: '',
        apellidos: '',
        nucleo: '',
        concepto: '',
        dinero: '',
        alimentos: '',
        kits: '',
        otros: '',
        fechaAtencion: '',
        fechaEntrega: '',
        estadoVivanto: '',
        fechaEstadoVivanto: '',
        municipioExpulsor: '',
        otrosHechos: '',
        contacto: '',
        remitente: '',
        observaciones: ''
    }
}