import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './../store/index.js'
import Home from '../views/Home.vue'
import Login from '../components/Login.vue'
import Configs from '../views/Configs.vue'
import Caracterizacion from '../views/Caracterizacion.vue'
import Albergues from '../views/Albergues.vue'
import Revisiondx from '../views/Revisiondx.vue'
import InformeAlbergue from '../components/albergue/InformeAlbergue.vue'
import Alberguecontrol from '../components/albergue/Alberguecontrol.vue'
import ListaEnviadosNotificar from '../components/albergue/ListaEnviadosNotificar.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/caracterizacion',
    name: 'Caracterizacion',
    component: Caracterizacion,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/configs',
    name: 'Configs',
    component: Configs,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/albergues',
    name: 'Albergues',
    component: Albergues,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/revisiondx',
    name: 'Revisiondx',
    component: Revisiondx,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/informealbergue',
    name: 'Informealbergue',
    component: InformeAlbergue,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Alberguecontrol',
    name: 'Alberguecontrol',
    component: Alberguecontrol,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/enviadosNotificarAlbergue',
    name: 'enviadosNotificarAlbergue',
    component: ListaEnviadosNotificar,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/*',
    component: Home
  }
]

const router = new VueRouter({
  mode: 'hash',
  base:  "/",
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
