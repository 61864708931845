export default {
    vh: [
        { value: 0, text: 'Sin dato' },
        { value: 1, text: 'Homicidio' },
        { value: 2, text: 'Tentativa de homicidio ' },
        { value: 3, text: 'Lesiones personales ' },
        { value: 100, text: 'Homicidios contra pobalción con orientación sexual e identitad de género diversas'},
        { value: 101, text: 'Homicidios contra pobalción migrante '},
        { value: 102, text: 'Homicidios contra mujeres'},
        { value: 103, text: 'Homicidios contra niños, niñas y adolescentes (NNA)'}
    ],
    dci: [
        { value: 0, text: 'Sin dato' },
        { value: 4, text: 'Desaparición forzada ' },
        { value: 5, text: 'Constreñimiento de la población civil (para guardar armas o drogas, aportar información, vigilancia del barrio)' },
        { value: 6, text: 'Secuestro o retención' },
        { value: 7, text: 'Atentados y hostigamiento' },
        { value: 104, text: 'Atentado por la detonación de artefactos explosivos' }
    ],
    ame: [
        { value: 0, text: 'Sin dato' },
        { value: 8, text: 'Mediación de actores armados en conflictos sociales, vecinales, familiares' },
        { value: 9, text: 'Amenaza a dirigentes o activistas de organizaciones de DD. HH., de víctimas o campesinas' },
        { value: 10, text: 'Amenaza a líderes sociales vinculados a programas derivados del Acuerdo de Paz (líderes de sustitución de cultivos, participantes de los Pedet)' },
        { value: 11, text: 'Amenaza a dirigentes o activistas sindicales' },
        { value: 12, text: 'Amenaza a líderes políticos o activistas de grupos políticos y especialmente de grupos de oposición' },
        { value: 13, text: 'Amenaza a docentes' },
        { value: 14, text: 'Amenaza a líderes de tierras ' },
        { value: 15, text: 'Amenaza a dirigentes, representantes o miembros de grupos étnicos' },
        { value: 16, text: 'Amenaza a periodistas y comunicadores sociales' },
        { value: 17, text: 'Amenaza a servidores o ex servidores públicos vinculados a política de derechos humanos y paz' },
        { value: 18, text: 'Amenaza a dirigentes, representantes o miembros de organizaciones defensoras de los derechos de la población y personas pertenecientes a la población  LGTBIQ+' },
        { value: 19, text: 'Amenaza como represalias por la búsqueda de familiares y amigos desaparecidos' },
        { value: 20, text: 'Amenazas a líderes comunitarios' },
        { value: 21, text: 'Amenaza por el lugar de procedencia de la víctima' },
        { value: 22, text: 'Amenaza por presunción de relación cercana con el actor armado (familiar, sentimental o de amistad)' },
        { value: 23, text: 'Amenaza por relación cercana con el actor armado (familiar, sentimental o de amistad)' },
        { value: 24, text: 'Amenaza por pertenencia a un grupo armado (legal o ilegal)' },
        { value: 25, text: 'Amenaza a excombatientes de grupos armados ilegales' },
        { value: 26, text: 'Amenaza a familiares de excombatientes de grupos armados ilegales' },
        { value: 27, text: 'Persistencia de amenazas contra personas que retornan al lugar expulsor' },
        { value: 28, text: 'Amenaza por ingreso al barrio sin autorización del actor armado' },
        { value: 29, text: 'Amenaza por no aportar información sobre el grupo armado “contrario”' },
        { value: 30, text: 'Amenaza por presenciar un delito' },
        { value: 31, text: 'Amenaza por denuncia ante autoridades' },
        { value: 32, text: 'Amenaza por presunción de ser informante del actor armado (legal o ilegal)' },
        { value: 33, text: 'Amenaza por comportamientos sociales sancionados por el actor armado ilegal' },
        { value: 34, text: 'Amenaza en razón de discriminación étnica' },
        { value: 99, text: 'Amenaza por conflictos sociales, vecinales y familiares con integrantes de grupos armados ilegales.' },
        { value: 105, text: 'Amenaza por homicidio de familiares o amigos' },
        { value: 106, text: 'Amenaza a personas con enfermedades de transmisión sexual (VIH positivo, entre otras).' },
        { value: 107, text: 'Amenza por discriminación y estigmatización contra población migrante ' },
        { value: 108, text: 'Amanaza derevida de la trata de personas con fines de explotación' },
        { value: 109, text: 'Amenazas por prejuicios contra personas LGBTI' }
    ],
    extor: [
        { value: 0, text: 'Sin dato' },
        { value: 35, text: 'Extorsión a personas vinculadas con la actividad de minería' },
        { value: 36, text: 'Extorsión por “servicios de seguridad”' },
        { value: 37, text: 'Extorsión para evitar desplazamiento' },
        { value: 38, text: 'Extorsión por “comisión por ventas”' },
        { value: 39, text: 'Extorsión como imposición de castigos (por no pago de arriendo, por peleas o conflictos familiares)' },
        { value: 40, text: 'Extorsión por parqueo de vehículos en zona pública ' },
        { value: 41, text: 'Extorsión por ingreso al barrio' },
        { value: 42, text: 'Extorsión por acceso a servicios públicos ' },
        { value: 43, text: 'Extorsión por mediación de conflictos ' },
        { value: 44, text: 'Extorsión por exigencia de compra de productos ' },
        { value: 45, text: 'Extorsión por cobro sobre reparación a víctimas ' },
        { value: 46, text: 'Extorsión por ocupación del espacio a habitantes de calle ' },
        { value: 47, text: 'Extorsión por no vinculación de NNA ' },
        { value: 48, text: 'Extorsión por homicidios sin autorización ' },
        { value: 49, text: 'Extorsión por construcción y reformas a inmuebles ' },
        { value: 50, text: 'Extorsión por venta de vehículos e inmuebles ' },
        { value: 51, text: 'Extorsión a proyectos comunitarios ' },
        { value: 52, text: 'Extorsión por uso de espacios públicos y control de economía informal ' },
        { value: 53, text: 'Extorsión por recolección de basuras ' },
        { value: 54, text: 'Extorsión a establecimientos comerciales e industrias ' },
        { value: 55, text: 'Extorsión por venta de productos de la canasta familiar' },
        { value: 56, text: 'Extorsión por venta de gas y limitación para su libre distribución ' },
        { value: 57, text: 'Extorsión a distribuidores de productos comerciales ' },
        { value: 58, text: 'Extorsión a constructores de obras públicas y privadas ' },
        { value: 59, text: 'Extorsión por llamadas telefónicas y redes sociales ' },
        { value: 60, text: 'Extorsión por loteo ilegal de predios para viviendas ' },
        { value: 61, text: 'Extorsión a transportadores urbanos ' },
        { value: 62, text: 'Extorsión a personas con empleo estable y buen salario ' },
        { value: 111, text: 'Extorsión mediante el cobro de paga diarios ' }
    ],
    vincnna: [
        { value: 0, text: 'Sin dato' },
        { value: 63, text: 'Intento de vinculación de NNA a grupos armados ' },
        { value: 64, text: 'Vinculación de NNA a grupos armados por medio del consumo de sustancias psicoactivas (SPA)' },
        { value: 65, text: 'Vinculación de NNA a grupos armados para “carrito” ' },
        { value: 66, text: 'Vinculación de NNA a grupos armados para extorsionar ' },
        { value: 67, text: 'Vinculación de NNA a grupos armados para labores de inteligencia ' },
        { value: 68, text: 'Vinculación de NNA a grupos armados para “campanero” ' },
        { value: 69, text: 'Vinculación de NNA a grupos armados para explotación sexual y comercial ' },
        { value: 70, text: 'Vinculación de NNA a grupos armados para Labores logísticas y aprovechamiento económico ' },
        { value: 71, text: 'Vinculación de NNA a grupos armados para patrullaje ' },
        { value: 72, text: 'Vinculación de NNA a grupos armados para venta de estupefacientes ' },
        { value: 73, text: 'Vinculación de NNA a grupos armados para confrontaciones ' },
        { value: 74, text: 'Vinculación de NNA a grupos armados para sicariato ' },
        { value: 75, text: 'Vinculación de NNA a grupos armados para vinculación de otros menores ' },
        { value: 76, text: 'Vinculación de NNA a grupos armados para coordinación de plazas' }
    ],
    rgen: [
        { value: 0, text: 'Sin dato' },
        { value: 77, text: 'Violencia sexual, explotación sexual o abuso sexual' },
        { value: 78, text: 'Explotación o esclavización para ejercer labores domésticas y roles considerados femeninos' },
        { value: 79, text: 'Amenazas por la condición, discriminación y vulnerabilidad acentuada de indígenas y afrodescendientes' },
        { value: 80, text: 'Violencia intrafamiliar ' },
        { value: 81, text: 'Presión o prohibición para establecer relaciones sentimentales ' },
        { value: 82, text: 'Desplazamiento por asesinato o desaparición del proveedor económico o desintegración de sus redes de apoyo' },
        { value: 83, text: 'Amenazas a mujeres cabeza de familia por el reclutamiento forzado de sus hijos o hijas u otro tipo de amenaza contra ellos' },
        { value: 84, text: 'Riesgos derivados del contacto o relaciones familiares o personales voluntarias, accidentales o presuntas con actores armados' },
        { value: 85, text: 'Despojadas de sus tierras y de su patrimonio' },
        { value: 86, text: 'Riesgos derivados de amenazas contra sus parejas afectivas u otro familiar' },
        { value: 87, text: 'Pertenencia a organizaciones sociales y comunitarias defensoras de los derechos humanos' }
    ],
    despo: [
        { value: 0, text: 'Sin dato' },
        { value: 88, text: 'Despojo de bienes inmuebles' },
        { value: 89, text: 'Despojo de bienes muebles' },
        { value: 90, text: 'Despojo de bienes muebles e inmuebles ' },
        { value: 91, text: 'Uso de bienes muebles e inmuebles' },
        { value: 92, text: 'Uso de bienes inmuebles' },
        { value: 110, text: 'Despojo por ubicación estratégica del bien inmueble' }
    ],
    tviolen: [
        { value: 0, text: 'Sin dato' },
        { value: 93, text: 'Enfrentamientos armados ' },
        { value: 94, text: 'Toques de queda' },
        { value: 95, text: 'Temor por vinculación de NNA' },
        { value: 96, text: 'Temor por control y restricción de movilidad' },
        { value: 97, text: 'Temor por panfletos ' },
        { value: 98, text: 'Temor por posibles represalias del actor armado' }
    ],
    categorias: [
        { value: 0, text: 'Sin dato' },
        { value: 1, text: 'Violencia homicida' },
        { value: 2, text: 'Delitos contra la integridad y seguridad personal' },
        { value: 3, text: 'Amenaza' },
        { value: 4, text: 'Extorsión' },
        { value: 5, text: 'Vinculación, reclutamiento y utilización de niños, niñas y adolescentes (NNA)'},
        { value: 6, text: 'Riesgos de género' },
        { value: 7, text: 'Despojo y uso de bienes' },
        { value: 8, text: 'Temor por violencia' }
    ],
    categ: [
        {id: '0', factor_incidencia: 'Sin dato', id_categoria: '0', categoria: 'Sin dato'},
        {id: '1', factor_incidencia: 'Homicidio', id_categoria: '1', categoria: 'Violencia homicida'},
        {id: '2', factor_incidencia: 'Tentativa de homicidio ', id_categoria: '1', categoria: 'Violencia homicida'},
        {id: '3', factor_incidencia: 'Lesiones personales ', id_categoria: '1', categoria: 'Violencia homicida'},
        {id: '4', factor_incidencia: 'Desaparición forzada ', id_categoria: '2', categoria: 'Delitos contra la integridad y seguridad personal'},
        {id: '5', factor_incidencia: 'Constreñimiento de la población civil (para guardar armas o drogas, aportar información, vigilancia del barrio)', id_categoria: '2', categoria: 'Delitos contra la integridad y seguridad personal'},
        {id: '6', factor_incidencia: 'Secuestro o retención', id_categoria: '2', categoria: 'Delitos contra la integridad y seguridad personal'},
        {id: '7', factor_incidencia: 'Atentados y hostigamiento', id_categoria: '2', categoria: 'Delitos contra la integridad y seguridad personal'},
        {id: '8', factor_incidencia: 'Mediación de actores armados en conflictos sociales, vecinales, familiares', id_categoria: '3', categoria: ' Amenaza'},
        {id: '9', factor_incidencia: 'Amenaza a dirigentes o activistas de organizaciones de DD. HH., de víctimas o campesinas', id_categoria: '3', categoria: ' Amenaza'},
        {id: '10', factor_incidencia: 'Amenaza a líderes sociales vinculados a programas derivados del Acuerdo de Paz (líderes de sustitución de cultivos, participantes de los Pedet)', id_categoria: '3', categoria: ' Amenaza'},
        {id: '11', factor_incidencia: 'Amenaza a dirigentes o activistas sindicales', id_categoria: '3', categoria: ' Amenaza'},
        {id: '12', factor_incidencia: 'Amenaza a líderes políticos o activistas de grupos políticos y especialmente de grupos de oposición', id_categoria: '3', categoria: ' Amenaza'},
        {id: '13', factor_incidencia: 'Amenaza a docentes', id_categoria: '3', categoria: ' Amenaza'},
        {id: '14', factor_incidencia: 'Amenaza a líderes de tierras ', id_categoria: '3', categoria: ' Amenaza'},
        {id: '15', factor_incidencia: 'Amenaza a dirigentes, representantes o miembros de grupos étnicos', id_categoria: '3', categoria: ' Amenaza'},
        {id: '16', factor_incidencia: 'Amenaza a periodistas y comunicadores sociales', id_categoria: '3', categoria: ' Amenaza'},
        {id: '17', factor_incidencia: 'Amenaza a servidores o ex servidores públicos vinculados a política de derechos humanos y paz', id_categoria: '3', categoria: ' Amenaza'},
        {id: '18', factor_incidencia: 'Amenaza a dirigentes, representantes o miembros de organizaciones defensoras de los derechos de la población y personas pertenecientes a la población  LGTBIQ+', id_categoria: '3', categoria: ' Amenaza'},
        {id: '19', factor_incidencia: 'Amenaza como represalias por la búsqueda de familiares y amigos desaparecidos', id_categoria: '3', categoria: ' Amenaza'},
        {id: '20', factor_incidencia: 'Amenazas a líderes comunitarios', id_categoria: '3', categoria: ' Amenaza'},
        {id: '21', factor_incidencia: 'Amenaza por el lugar de procedencia de la víctima', id_categoria: '3', categoria: ' Amenaza'},
        {id: '22', factor_incidencia: 'Amenaza por presunción de relación cercana con el actor armado (familiar, sentimental o de amistad)', id_categoria: '3', categoria: ' Amenaza'},
        {id: '23', factor_incidencia: 'Amenaza por relación cercana con el actor armado (familiar, sentimental o de amistad)', id_categoria: '3', categoria: ' Amenaza'},
        {id: '24', factor_incidencia: 'Amenaza por pertenencia a un grupo armado (legal o ilegal)', id_categoria: '3', categoria: ' Amenaza'},
        {id: '25', factor_incidencia: 'Amenaza a excombatientes de grupos armados ilegales', id_categoria: '3', categoria: ' Amenaza'},
        {id: '26', factor_incidencia: 'Amenaza a familiares de excombatientes de grupos armados ilegales', id_categoria: '3', categoria: ' Amenaza'},
        {id: '27', factor_incidencia: 'Persistencia de amenazas contra personas que retornan al lugar expulsor', id_categoria: '3', categoria: ' Amenaza'},
        {id: '28', factor_incidencia: 'Amenaza por ingreso al barrio sin autorización del actor armado', id_categoria: '3', categoria: ' Amenaza'},
        {id: '29', factor_incidencia: 'Amenaza por no aportar información sobre el grupo armado “contrario”', id_categoria: '3', categoria: ' Amenaza'},
        {id: '30', factor_incidencia: 'Amenaza por presenciar un delito', id_categoria: '3', categoria: ' Amenaza'},
        {id: '31', factor_incidencia: 'Amenaza por denuncia ante autoridades', id_categoria: '3', categoria: ' Amenaza'},
        {id: '32', factor_incidencia: 'Amenaza por presunción de ser informante del actor armado (legal o ilegal)', id_categoria: '3', categoria: ' Amenaza'},
        {id: '33', factor_incidencia: 'Amenaza por comportamientos sociales sancionados por el actor armado ilegal', id_categoria: '3', categoria: ' Amenaza'},
        {id: '34', factor_incidencia: 'Amenaza en razón de discriminación étnica', id_categoria: '3', categoria: ' Amenaza'},
        {id: '35', factor_incidencia: 'Extorsión a personas vinculadas con la actividad de minería', id_categoria: '4', categoria: 'Extorsión '},
        {id: '36', factor_incidencia: 'Extorsión por “servicios de seguridad”', id_categoria: '4', categoria: 'Extorsión '},
        {id: '37', factor_incidencia: 'Extorsión para evitar desplazamiento', id_categoria: '4', categoria: 'Extorsión '},
        {id: '38', factor_incidencia: 'Extorsión por “comisión por ventas”', id_categoria: '4', categoria: 'Extorsión '},
        {id: '39', factor_incidencia: 'Extorsión como imposición de castigos (por no pago de arriendo, por peleas o conflictos familiares)', id_categoria: '4', categoria: 'Extorsión '},
        {id: '40', factor_incidencia: 'Extorsión por parqueo de vehículos en zona pública ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '41', factor_incidencia: 'Extorsión por ingreso al barrio', id_categoria: '4', categoria: 'Extorsión '},
        {id: '42', factor_incidencia: 'Extorsión por acceso a servicios públicos ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '43', factor_incidencia: 'Extorsión por mediación de conflictos ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '44', factor_incidencia: 'Extorsión por exigencia de compra de productos ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '45', factor_incidencia: 'Extorsión por cobro sobre reparación a víctimas ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '46', factor_incidencia: 'Extorsión por ocupación del espacio a habitantes de calle ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '47', factor_incidencia: 'Extorsión por no vinculación de NNA ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '48', factor_incidencia: 'Extorsión por homicidios sin autorización ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '49', factor_incidencia: 'Extorsión por construcción y reformas a inmuebles ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '50', factor_incidencia: 'Extorsión por venta de vehículos e inmuebles ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '51', factor_incidencia: 'Extorsión a proyectos comunitarios ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '52', factor_incidencia: 'Extorsión por uso de espacios públicos y control de economía informal ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '53', factor_incidencia: 'Extorsión por recolección de basuras ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '54', factor_incidencia: 'Extorsión a establecimientos comerciales e industrias ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '55', factor_incidencia: 'Extorsión por venta de productos de la canasta familiar', id_categoria: '4', categoria: 'Extorsión '},
        {id: '56', factor_incidencia: 'Extorsión por venta de gas y limitación para su libre distribución ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '57', factor_incidencia: 'Extorsión a distribuidores de productos comerciales ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '58', factor_incidencia: 'Extorsión a constructores de obras públicas y privadas ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '59', factor_incidencia: 'Extorsión por llamadas telefónicas y redes sociales ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '60', factor_incidencia: 'Extorsión por loteo ilegal de predios para viviendas ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '61', factor_incidencia: 'Extorsión a transportadores urbanos ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '62', factor_incidencia: 'Extorsión a personas con empleo estable y buen salario ', id_categoria: '4', categoria: 'Extorsión '},
        {id: '63', factor_incidencia: 'Intento de vinculación de NNA a grupos armados ', id_categoria: '5', categoria: 'Vinculación, reclutamiento y utilización de niños, niñas y adolescentes (NNA)'},
        {id: '64', factor_incidencia: 'Vinculación de NNA a grupos armados por medio del consumo de sustancias psicoactivas (SPA)', id_categoria: '5', categoria: 'Vinculación, reclutamiento y utilización de niños, niñas y adolescentes (NNA)'},
        {id: '65', factor_incidencia: 'Vinculación de NNA a grupos armados para “carrito” ', id_categoria: '5', categoria: 'Vinculación, reclutamiento y utilización de niños, niñas y adolescentes (NNA)'},
        {id: '66', factor_incidencia: 'Vinculación de NNA a grupos armados para extorsionar ', id_categoria: '5', categoria: 'Vinculación, reclutamiento y utilización de niños, niñas y adolescentes (NNA)'},
        {id: '67', factor_incidencia: 'Vinculación de NNA a grupos armados para labores de inteligencia ', id_categoria: '5', categoria: 'Vinculación, reclutamiento y utilización de niños, niñas y adolescentes (NNA)'},
        {id: '68', factor_incidencia: 'Vinculación de NNA a grupos armados para “campanero” ', id_categoria: '5', categoria: 'Vinculación, reclutamiento y utilización de niños, niñas y adolescentes (NNA)'},
        {id: '69', factor_incidencia: 'Vinculación de NNA a grupos armados para explotación sexual y comercial ', id_categoria: '5', categoria: 'Vinculación, reclutamiento y utilización de niños, niñas y adolescentes (NNA)'},
        {id: '70', factor_incidencia: 'Vinculación de NNA a grupos armados para Labores logísticas y aprovechamiento económico ', id_categoria: '5', categoria: 'Vinculación, reclutamiento y utilización de niños, niñas y adolescentes (NNA)'},
        {id: '71', factor_incidencia: 'Vinculación de NNA a grupos armados para patrullaje ', id_categoria: '5', categoria: 'Vinculación, reclutamiento y utilización de niños, niñas y adolescentes (NNA)'},
        {id: '72', factor_incidencia: 'Vinculación de NNA a grupos armados para venta de estupefacientes ', id_categoria: '5', categoria: 'Vinculación, reclutamiento y utilización de niños, niñas y adolescentes (NNA)'},
        {id: '73', factor_incidencia: 'Vinculación de NNA a grupos armados para confrontaciones ', id_categoria: '5', categoria: 'Vinculación, reclutamiento y utilización de niños, niñas y adolescentes (NNA)'},
        {id: '74', factor_incidencia: 'Vinculación de NNA a grupos armados para sicariato ', id_categoria: '5', categoria: 'Vinculación, reclutamiento y utilización de niños, niñas y adolescentes (NNA)'},
        {id: '75', factor_incidencia: 'Vinculación de NNA a grupos armados para vinculación de otros menores ', id_categoria: '5', categoria: 'Vinculación, reclutamiento y utilización de niños, niñas y adolescentes (NNA)'},
        {id: '76', factor_incidencia: 'Vinculación de NNA a grupos armados para coordinación de plazas', id_categoria: '5', categoria: 'Vinculación, reclutamiento y utilización de niños, niñas y adolescentes (NNA)'},
        {id: '77', factor_incidencia: 'Violencia sexual, explotación sexual o abuso sexual', id_categoria: '6', categoria: 'Riesgos de género '},
        {id: '78', factor_incidencia: 'Explotación o esclavización para ejercer labores domésticas y roles considerados femeninos', id_categoria: '6', categoria: 'Riesgos de género '},
        {id: '79', factor_incidencia: 'Amenazas por la condición, discriminación y vulnerabilidad acentuada de indígenas y afrodescendientes', id_categoria: '6', categoria: 'Riesgos de género '},
        {id: '80', factor_incidencia: 'Violencia intrafamiliar ', id_categoria: '6', categoria: 'Riesgos de género '},
        {id: '81', factor_incidencia: 'Presión o prohibición para establecer relaciones sentimentales ', id_categoria: '6', categoria: 'Riesgos de género '},
        {id: '82', factor_incidencia: 'Desplazamiento por asesinato o desaparición del proveedor económico o desintegración de sus redes de apoyo', id_categoria: '6', categoria: 'Riesgos de género '},
        {id: '83', factor_incidencia: 'Amenazas a mujeres cabeza de familia por el reclutamiento forzado de sus hijos o hijas u otro tipo de amenaza contra ellos', id_categoria: '6', categoria: 'Riesgos de género '},
        {id: '84', factor_incidencia: 'Riesgos derivados del contacto o relaciones familiares o personales voluntarias, accidentales o presuntas con actores armados', id_categoria: '6', categoria: 'Riesgos de género '},
        {id: '85', factor_incidencia: 'Despojadas de sus tierras y de su patrimonio', id_categoria: '6', categoria: 'Riesgos de género '},
        {id: '86', factor_incidencia: 'Riesgos derivados de amenazas contra sus parejas afectivas u otro familiar', id_categoria: '6', categoria: 'Riesgos de género '},
        {id: '87', factor_incidencia: 'Pertenencia a organizaciones sociales y comunitarias defensoras de los derechos humanos', id_categoria: '6', categoria: 'Riesgos de género '},
        {id: '88', factor_incidencia: 'Despojo de bienes inmuebles', id_categoria: '7', categoria: 'Despojo y uso de bienes '},
        {id: '89', factor_incidencia: 'Despojo de bienes muebles', id_categoria: '7', categoria: 'Despojo y uso de bienes '},
        {id: '90', factor_incidencia: 'Despojo de bienes muebles e inmuebles ', id_categoria: '7', categoria: 'Despojo y uso de bienes '},
        {id: '91', factor_incidencia: 'Uso de bienes muebles e inmuebles', id_categoria: '7', categoria: 'Despojo y uso de bienes '},
        {id: '92', factor_incidencia: 'Uso de bienes inmuebles', id_categoria: '7', categoria: 'Despojo y uso de bienes '},
        {id: '93', factor_incidencia: 'Enfrentamientos armados ', id_categoria: '8', categoria: ' Temor por violencia'},
        {id: '94', factor_incidencia: 'Toques de queda', id_categoria: '8', categoria: ' Temor por violencia'},
        {id: '95', factor_incidencia: 'Temor por vinculación de NNA', id_categoria: '8', categoria: ' Temor por violencia'},
        {id: '96', factor_incidencia: 'Temor por control y restricción de movilidad', id_categoria: '8', categoria: ' Temor por violencia'},
        {id: '97', factor_incidencia: 'Temor por panfletos ', id_categoria: '8', categoria: ' Temor por violencia'},
        {id: '98', factor_incidencia: 'Temor por posibles represalias', id_categoria: '8', categoria: ' Temor por violencia'},
        {id: '99', factor_incidencia: 'Amenaza', id_categoria: '3', categoria: 'Amenaza por conflictos sociales, vecinales y familiares con integrantes de grupos armados ilegales.'},
        {id: '100', factor_incidencia: 'Violencia homicida', id_categoria: '1', categoria: 'Homicidios contra pobalción con orientación sexual e identitad de género diversas'},
        {id: '101', factor_incidencia: 'Violencia homicida', id_categoria: '1', categoria: 'Homicidios contra pobalción migrante '},
        {id: '102', factor_incidencia: 'Violencia homicida', id_categoria: '1', categoria: 'Homicidios contra mujeres'},
        {id: '103', factor_incidencia: 'Violencia homicida', id_categoria: '1', categoria: 'Homicidios contra niños, niñas y adolescentes (NNA)'},
        {id: '104', factor_incidencia: 'Delitos contra la integridad y seguridad personal', id_categoria: '2', categoria: 'Atentado por la detonación de artefactos explosivos'},
        {id: '105', factor_incidencia: 'Amenaza', id_categoria: '3', categoria: 'Amenaza por homicidio de familiares o amigos'},
        {id: '106', factor_incidencia: 'Amenaza', id_categoria: '3', categoria: 'Amenaza a personas con enfermedades de transmisión sexual (VIH positivo, entre otras).'},
        {id: '107', factor_incidencia: 'Amenaza', id_categoria: '3', categoria: 'Amenza por discriminación y estigmatización contra población migrante '},
        {id: '108', factor_incidencia: 'Amenaza', id_categoria: '3', categoria: 'Amanaza derevida de la trata de personas con fines de explotación'},
        {id: '109', factor_incidencia: 'Amenaza', id_categoria: '3', categoria: 'Amenazas por prejuicios contra personas LGBTI'},
        {id: '110', factor_incidencia: 'Despojo y uso de bienes', id_categoria: '7', categoria: 'Despojo por ubicación estratégica del bien inmueble'},
        {id: '111', factor_incidencia: 'Extorsión', id_categoria: '4', categoria: 'Extorsión mediante el cobro de paga diarios'}
        
    ]
}
