export default {
    municipio: [
        {id: '1', cod_dept: '1', departamento: 'AMAZONAS', municipio: 'LETICIA', subregion: 'N/A'},
        {id: '2', cod_dept: '1', departamento: 'AMAZONAS', municipio: 'EL ENCANTO', subregion: 'N/A'},
        {id: '3', cod_dept: '1', departamento: 'AMAZONAS', municipio: 'LA CHORRERA', subregion: 'N/A'},
        {id: '4', cod_dept: '1', departamento: 'AMAZONAS', municipio: 'LA PEDRERA', subregion: 'N/A'},
        {id: '5', cod_dept: '1', departamento: 'AMAZONAS', municipio: 'LA VICTORIA', subregion: 'N/A'},
        {id: '6', cod_dept: '1', departamento: 'AMAZONAS', municipio: 'MIRITÍ - PARANÁ', subregion: 'N/A'},
        {id: '7', cod_dept: '1', departamento: 'AMAZONAS', municipio: 'PUERTO ALEGRÍA', subregion: 'N/A'},
        {id: '8', cod_dept: '1', departamento: 'AMAZONAS', municipio: 'PUERTO ARICA', subregion: 'N/A'},
        {id: '9', cod_dept: '1', departamento: 'AMAZONAS', municipio: 'PUERTO NARIÑO', subregion: 'N/A'},
        {id: '10', cod_dept: '1', departamento: 'AMAZONAS', municipio: 'PUERTO SANTANDER', subregion: 'N/A'},
        {id: '11', cod_dept: '1', departamento: 'AMAZONAS', municipio: 'TARAPACÁ', subregion: 'N/A'},
        {id: '12', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'MEDELLÍN', subregion: 'VALLE DE ABURRÁ'},
        {id: '13', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'ABEJORRAL', subregion: 'ORIENTE'},
        {id: '14', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'ABRIAQUÍ', subregion: 'OCCIDENTE'},
        {id: '15', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'ALEJANDRÍA', subregion: 'ORIENTE'},
        {id: '16', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'AMAGÁ', subregion: 'SUROESTE'},
        {id: '17', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'AMALFI', subregion: 'NORDESTE'},
        {id: '18', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'ANDES', subregion: 'SUROESTE'},
        {id: '19', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'ANGELÓPOLIS', subregion: 'SUROESTE'},
        {id: '20', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'ANGOSTURA', subregion: 'NORTE'},
        {id: '21', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'ANORÍ', subregion: 'NORDESTE'},
        {id: '22', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SANTA FE DE ANTIOQUIA', subregion: 'OCCIDENTE'},
        {id: '23', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'ANZÁ', subregion: 'OCCIDENTE'},
        {id: '24', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'APARTADÓ', subregion: 'URABÁ'},
        {id: '25', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'ARBOLETES', subregion: 'URABÁ'},
        {id: '26', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'ARGELIA', subregion: 'ORIENTE'},
        {id: '27', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'ARMENIA', subregion: 'OCCIDENTE'},
        {id: '28', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'BARBOSA', subregion: 'VALLE DE ABURRÁ'},
        {id: '29', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'BELMIRA', subregion: 'NORTE'},
        {id: '30', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'BELLO', subregion: 'VALLE DE ABURRÁ'},
        {id: '31', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'BETANIA', subregion: 'SUROESTE'},
        {id: '32', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'BETULIA', subregion: 'SUROESTE'},
        {id: '33', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'CIUDAD BOLÍVAR', subregion: 'SUROESTE'},
        {id: '34', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'BRICEÑO', subregion: 'NORTE'},
        {id: '35', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'BURITICÁ', subregion: 'OCCIDENTE'},
        {id: '36', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'CÁCERES', subregion: 'BAJO CAUCA'},
        {id: '37', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'CAICEDO', subregion: 'OCCIDENTE'},
        {id: '38', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'CALDAS', subregion: 'VALLE DE ABURRÁ'},
        {id: '39', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'CAMPAMENTO', subregion: 'NORTE'},
        {id: '40', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'CAÑASGORDAS', subregion: 'OCCIDENTE'},
        {id: '41', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'CARACOLÍ', subregion: 'MAGDALENA MEDIO'},
        {id: '42', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'CARAMANTA', subregion: 'SUROESTE'},
        {id: '43', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'CAREPA', subregion: 'URABÁ'},
        {id: '44', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'CARMEN DE VIBORAL', subregion: 'ORIENTE'},
        {id: '45', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'CAROLINA DEL PRÍNCIPE', subregion: 'NORTE'},
        {id: '46', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'CAUCASIA', subregion: 'BAJO CAUCA'},
        {id: '47', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'CHIGORODÓ', subregion: 'URABÁ'},
        {id: '48', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'CISNEROS', subregion: 'NORDESTE'},
        {id: '49', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'COCORNÁ', subregion: 'ORIENTE'},
        {id: '50', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'CONCEPCIÓN', subregion: 'ORIENTE'},
        {id: '51', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'CONCORDIA', subregion: 'SUROESTE'},
        {id: '52', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'COPACABANA', subregion: 'VALLE DE ABURRÁ'},
        {id: '53', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'DABEIBA', subregion: 'OCCIDENTE'},
        {id: '54', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'DONMATÍAS', subregion: 'NORTE'},
        {id: '55', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'EBÉJICO', subregion: 'OCCIDENTE'},
        {id: '56', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'EL BAGRE', subregion: 'BAJO CAUCA'},
        {id: '57', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'ENTRERRÍOS', subregion: 'NORTE'},
        {id: '58', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'ENVIGADO', subregion: 'VALLE DE ABURRÁ'},
        {id: '59', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'FREDONIA', subregion: 'SUROESTE'},
        {id: '60', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'FRONTINO', subregion: 'OCCIDENTE'},
        {id: '61', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'GIRALDO', subregion: 'OCCIDENTE'},
        {id: '62', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'GIRARDOTA', subregion: 'VALLE DE ABURRÁ'},
        {id: '63', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'GÓMEZ PLATA', subregion: 'NORTE'},
        {id: '64', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'GRANADA', subregion: 'ORIENTE'},
        {id: '65', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'GUADALUPE', subregion: 'NORTE'},
        {id: '66', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'GUARNE', subregion: 'ORIENTE'},
        {id: '67', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'GUATAPÉ', subregion: 'ORIENTE'},
        {id: '68', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'HELICONIA', subregion: 'OCCIDENTE'},
        {id: '69', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'HISPANIA', subregion: 'SUROESTE'},
        {id: '70', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'ITAGÜÍ', subregion: 'VALLE DE ABURRÁ'},
        {id: '71', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'ITUANGO', subregion: 'NORTE'},
        {id: '72', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'JARDÍN', subregion: 'SUROESTE'},
        {id: '73', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'JERICÓ', subregion: 'SUROESTE'},
        {id: '74', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'LA CEJA', subregion: 'ORIENTE'},
        {id: '75', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'LA ESTRELLA', subregion: 'VALLE DE ABURRÁ'},
        {id: '76', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'LA PINTADA', subregion: 'SUROESTE'},
        {id: '77', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'LA UNIÓN', subregion: 'ORIENTE'},
        {id: '78', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'LIBORINA', subregion: 'OCCIDENTE'},
        {id: '79', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'MACEO', subregion: 'MAGDALENA MEDIO'},
        {id: '80', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'MARINILLA', subregion: 'ORIENTE'},
        {id: '81', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'MONTEBELLO', subregion: 'SUROESTE'},
        {id: '82', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'MURINDÓ', subregion: 'URABÁ'},
        {id: '83', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'MUTATÁ', subregion: 'URABÁ'},
        {id: '84', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'NARIÑO', subregion: 'ORIENTE'},
        {id: '85', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'NECOCLÍ', subregion: 'URABÁ'},
        {id: '86', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'NECHÍ', subregion: 'BAJO CAUCA'},
        {id: '87', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'OLAYA', subregion: 'OCCIDENTE'},
        {id: '88', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'EL PEÑOL', subregion: 'ORIENTE'},
        {id: '89', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'PEQUE', subregion: 'OCCIDENTE'},
        {id: '90', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'PUEBLORRICO', subregion: 'SUROESTE'},
        {id: '91', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'PUERTO BERRÍO', subregion: 'MAGDALENA MEDIO'},
        {id: '92', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'PUERTO NARE', subregion: 'MAGDALENA MEDIO'},
        {id: '93', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'PUERTO TRIUNFO', subregion: 'MAGDALENA MEDIO'},
        {id: '94', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'REMEDIOS', subregion: 'NORDESTE'},
        {id: '95', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'EL RETIRO', subregion: 'ORIENTE'},
        {id: '96', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'RIONEGRO', subregion: 'ORIENTE'},
        {id: '97', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SABANALARGA', subregion: 'OCCIDENTE'},
        {id: '98', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SABANETA', subregion: 'VALLE DE ABURRÁ'},
        {id: '99', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SALGAR', subregion: 'SUROESTE'},
        {id: '100', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SAN ANDRÉS DE CUERQUIA', subregion: 'NORTE'},
        {id: '101', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SAN CARLOS', subregion: 'ORIENTE'},
        {id: '102', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SAN FRANCISCO', subregion: 'ORIENTE'},
        {id: '103', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SAN JERÓNIMO', subregion: 'OCCIDENTE'},
        {id: '104', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SAN JOSÉ DE LA MONTAÑA', subregion: 'NORTE'},
        {id: '105', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SAN JUAN DE URABÁ', subregion: 'URABÁ'},
        {id: '106', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SAN LUIS', subregion: 'ORIENTE'},
        {id: '107', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SAN PEDRO DE LOS MILAGROS', subregion: 'NORTE'},
        {id: '108', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SAN PEDRO DE URABÁ', subregion: 'URABÁ'},
        {id: '109', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SAN RAFAEL', subregion: 'ORIENTE'},
        {id: '110', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SAN ROQUE', subregion: 'NORDESTE'},
        {id: '111', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SAN VICENTE FERRER', subregion: 'ORIENTE'},
        {id: '112', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SANTA BÁRBARA', subregion: 'SUROESTE'},
        {id: '113', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SANTA ROSA DE OSOS', subregion: 'NORTE'},
        {id: '114', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SANTO DOMINGO', subregion: 'NORDESTE'},
        {id: '115', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'EL SANTUARIO', subregion: 'ORIENTE'},
        {id: '116', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SEGOVIA', subregion: 'NORDESTE'},
        {id: '117', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SONSÓN', subregion: 'ORIENTE'},
        {id: '118', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'SOPETRÁN', subregion: 'OCCIDENTE'},
        {id: '119', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'TÁMESIS', subregion: 'SUROESTE'},
        {id: '120', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'TARAZÁ', subregion: 'BAJO CAUCA'},
        {id: '121', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'TARSO', subregion: 'SUROESTE'},
        {id: '122', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'TITIRIBÍ', subregion: 'SUROESTE'},
        {id: '123', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'TOLEDO', subregion: 'NORTE'},
        {id: '124', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'TURBO', subregion: 'URABÁ'},
        {id: '125', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'URAMITA', subregion: 'OCCIDENTE'},
        {id: '126', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'URRAO', subregion: 'SUROESTE'},
        {id: '127', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'VALDIVIA', subregion: 'NORTE'},
        {id: '128', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'VALPARAÍSO', subregion: 'SUROESTE'},
        {id: '129', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'VEGACHÍ', subregion: 'NORDESTE'},
        {id: '130', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'VENECIA', subregion: 'SUROESTE'},
        {id: '131', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'VIGÍA DEL FUERTE', subregion: 'URABÁ'},
        {id: '132', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'YALÍ', subregion: 'NORDESTE'},
        {id: '133', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'YARUMAL', subregion: 'NORTE'},
        {id: '134', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'YOLOMBÓ', subregion: 'NORDESTE'},
        {id: '135', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'YONDÓ', subregion: 'MAGDALENA MEDIO'},
        {id: '136', cod_dept: '2', departamento: 'ANTIOQUIA', municipio: 'ZARAGOZA', subregion: 'BAJO CAUCA'},
        {id: '137', cod_dept: '3', departamento: 'ARAUCA', municipio: 'ARAUCA', subregion: 'N/A'},
        {id: '138', cod_dept: '3', departamento: 'ARAUCA', municipio: 'ARAUQUITA', subregion: 'N/A'},
        {id: '139', cod_dept: '3', departamento: 'ARAUCA', municipio: 'CRAVO NORTE', subregion: 'N/A'},
        {id: '140', cod_dept: '3', departamento: 'ARAUCA', municipio: 'FORTUL', subregion: 'N/A'},
        {id: '141', cod_dept: '3', departamento: 'ARAUCA', municipio: 'PUERTO RONDÓN', subregion: 'N/A'},
        {id: '142', cod_dept: '3', departamento: 'ARAUCA', municipio: 'SARAVENA', subregion: 'N/A'},
        {id: '143', cod_dept: '3', departamento: 'ARAUCA', municipio: 'TAME', subregion: 'N/A'},
        {id: '144', cod_dept: '4', departamento: 'ARCHIPIÉLAGO DE SAN ANDRÉS, PROVIDENCIA Y SANTA CATALINA', municipio: 'SAN ANDRÉS', subregion: 'N/A'},
        {id: '145', cod_dept: '4', departamento: 'ARCHIPIÉLAGO DE SAN ANDRÉS, PROVIDENCIA Y SANTA CATALINA', municipio: 'PROVIDENCIA', subregion: 'N/A'},
        {id: '146', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'BARRANQUILLA', subregion: 'N/A'},
        {id: '147', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'BARANOA', subregion: 'N/A'},
        {id: '148', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'CAMPO DE LA CRUZ', subregion: 'N/A'},
        {id: '149', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'CANDELARIA', subregion: 'N/A'},
        {id: '150', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'GALAPA', subregion: 'N/A'},
        {id: '151', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'JUAN DE ACOSTA', subregion: 'N/A'},
        {id: '152', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'LURUACO', subregion: 'N/A'},
        {id: '153', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'MALAMBO', subregion: 'N/A'},
        {id: '154', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'MANATÍ', subregion: 'N/A'},
        {id: '155', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'PALMAR DE VARELA', subregion: 'N/A'},
        {id: '156', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'PIOJÓ', subregion: 'N/A'},
        {id: '157', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'POLONUEVO', subregion: 'N/A'},
        {id: '158', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'PONEDERA', subregion: 'N/A'},
        {id: '159', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'PUERTO COLOMBIA', subregion: 'N/A'},
        {id: '160', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'REPELÓN', subregion: 'N/A'},
        {id: '161', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'SABANAGRANDE', subregion: 'N/A'},
        {id: '162', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'SABANALARGA', subregion: 'N/A'},
        {id: '163', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'SANTA LUCÍA', subregion: 'N/A'},
        {id: '164', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'SANTO TOMÁS', subregion: 'N/A'},
        {id: '165', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'SOLEDAD', subregion: 'N/A'},
        {id: '166', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'SUAN', subregion: 'N/A'},
        {id: '167', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'TUBARÁ', subregion: 'N/A'},
        {id: '168', cod_dept: '5', departamento: 'ATLÁNTICO', municipio: 'USIACURÍ', subregion: 'N/A'},
        {id: '169', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'CARTAGENA DE INDIAS', subregion: 'N/A'},
        {id: '170', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'ACHÍ', subregion: 'N/A'},
        {id: '171', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'ALTOS DEL ROSARIO', subregion: 'N/A'},
        {id: '172', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'ARENAL', subregion: 'N/A'},
        {id: '173', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'ARJONA', subregion: 'N/A'},
        {id: '174', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'ARROYOHONDO', subregion: 'N/A'},
        {id: '175', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'BARRANCO DE LOBA', subregion: 'N/A'},
        {id: '176', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'CALAMAR', subregion: 'N/A'},
        {id: '177', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'CANTAGALLO', subregion: 'N/A'},
        {id: '178', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'CICUCO', subregion: 'N/A'},
        {id: '179', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'CÓRDOBA', subregion: 'N/A'},
        {id: '180', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'CLEMENCIA', subregion: 'N/A'},
        {id: '181', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'EL CARMEN DE BOLÍVAR', subregion: 'N/A'},
        {id: '182', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'EL GUAMO', subregion: 'N/A'},
        {id: '183', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'EL PEÑÓN', subregion: 'N/A'},
        {id: '184', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'HATILLO DE LOBA', subregion: 'N/A'},
        {id: '185', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'MAGANGUÉ', subregion: 'N/A'},
        {id: '186', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'MAHATES', subregion: 'N/A'},
        {id: '187', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'MARGARITA', subregion: 'N/A'},
        {id: '188', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'MARÍA LA BAJA', subregion: 'N/A'},
        {id: '189', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'MONTECRISTO', subregion: 'N/A'},
        {id: '190', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'MOMPÓS', subregion: 'N/A'},
        {id: '191', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'MORALES', subregion: 'N/A'},
        {id: '192', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'NOROSÍ', subregion: 'N/A'},
        {id: '193', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'PINILLOS', subregion: 'N/A'},
        {id: '194', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'REGIDOR', subregion: 'N/A'},
        {id: '195', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'RÍO VIEJO', subregion: 'N/A'},
        {id: '196', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'SAN CRISTÓBAL', subregion: 'N/A'},
        {id: '197', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'SAN ESTANISLAO', subregion: 'N/A'},
        {id: '198', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'SAN FERNANDO', subregion: 'N/A'},
        {id: '199', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'SAN JACINTO', subregion: 'N/A'},
        {id: '200', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'SAN JACINTO DEL CAUCA', subregion: 'N/A'},
        {id: '201', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'SAN JUAN NEPOMUCENO', subregion: 'N/A'},
        {id: '202', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'SAN MARTÍN DE LOBA', subregion: 'N/A'},
        {id: '203', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'SAN PABLO', subregion: 'N/A'},
        {id: '204', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'SANTA CATALINA', subregion: 'N/A'},
        {id: '205', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'SANTA ROSA', subregion: 'N/A'},
        {id: '206', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'SANTA ROSA DEL SUR', subregion: 'N/A'},
        {id: '207', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'SIMITÍ', subregion: 'N/A'},
        {id: '208', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'SOPLAVIENTO', subregion: 'N/A'},
        {id: '209', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'TALAIGUA NUEVO', subregion: 'N/A'},
        {id: '210', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'TIQUISIO', subregion: 'N/A'},
        {id: '211', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'TURBACO', subregion: 'N/A'},
        {id: '212', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'TURBANÁ', subregion: 'N/A'},
        {id: '213', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'VILLANUEVA', subregion: 'N/A'},
        {id: '214', cod_dept: '6', departamento: 'BOLÍVAR', municipio: 'ZAMBRANO', subregion: 'N/A'},
        {id: '215', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'TUNJA', subregion: 'N/A'},
        {id: '216', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'ALMEIDA', subregion: 'N/A'},
        {id: '217', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'AQUITANIA', subregion: 'N/A'},
        {id: '218', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'ARCABUCO', subregion: 'N/A'},
        {id: '219', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'BELÉN', subregion: 'N/A'},
        {id: '220', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'BERBEO', subregion: 'N/A'},
        {id: '221', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'BETÉITIVA', subregion: 'N/A'},
        {id: '222', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'BOAVITA', subregion: 'N/A'},
        {id: '223', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'BOYACÁ', subregion: 'N/A'},
        {id: '224', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'BRICEÑO', subregion: 'N/A'},
        {id: '225', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'BUENAVISTA', subregion: 'N/A'},
        {id: '226', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'BUSBANZÁ', subregion: 'N/A'},
        {id: '227', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'CALDAS', subregion: 'N/A'},
        {id: '228', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'CAMPOHERMOSO', subregion: 'N/A'},
        {id: '229', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'CERINZA', subregion: 'N/A'},
        {id: '230', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'CHINAVITA', subregion: 'N/A'},
        {id: '231', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'CHIQUINQUIRÁ', subregion: 'N/A'},
        {id: '232', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'CHISCAS', subregion: 'N/A'},
        {id: '233', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'CHITA', subregion: 'N/A'},
        {id: '234', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'CHITARAQUE', subregion: 'N/A'},
        {id: '235', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'CHIVATÁ', subregion: 'N/A'},
        {id: '236', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'CIÉNEGA', subregion: 'N/A'},
        {id: '237', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'CÓMBITA', subregion: 'N/A'},
        {id: '238', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'COPER', subregion: 'N/A'},
        {id: '239', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'CORRALES', subregion: 'N/A'},
        {id: '240', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'COVARACHÍA', subregion: 'N/A'},
        {id: '241', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'CUBARÁ', subregion: 'N/A'},
        {id: '242', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'CUCAITA', subregion: 'N/A'},
        {id: '243', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'CUÍTIVA', subregion: 'N/A'},
        {id: '244', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'CHÍQUIZA', subregion: 'N/A'},
        {id: '245', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'CHIVOR', subregion: 'N/A'},
        {id: '246', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'DUITAMA', subregion: 'N/A'},
        {id: '247', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'EL COCUY', subregion: 'N/A'},
        {id: '248', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'EL ESPINO', subregion: 'N/A'},
        {id: '249', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'FIRAVITOBA', subregion: 'N/A'},
        {id: '250', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'FLORESTA', subregion: 'N/A'},
        {id: '251', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'GACHANTIVÁ', subregion: 'N/A'},
        {id: '252', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'GÁMEZA', subregion: 'N/A'},
        {id: '253', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'GARAGOA', subregion: 'N/A'},
        {id: '254', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'GUACAMAYAS', subregion: 'N/A'},
        {id: '255', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'GUATEQUE', subregion: 'N/A'},
        {id: '256', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'GUAYATÁ', subregion: 'N/A'},
        {id: '257', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'GÜICÁN', subregion: 'N/A'},
        {id: '258', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'IZA', subregion: 'N/A'},
        {id: '259', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'JENESANO', subregion: 'N/A'},
        {id: '260', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'JERICÓ', subregion: 'N/A'},
        {id: '261', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'LABRANZAGRANDE', subregion: 'N/A'},
        {id: '262', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'LA CAPILLA', subregion: 'N/A'},
        {id: '263', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'LA VICTORIA', subregion: 'N/A'},
        {id: '264', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'LA UVITA', subregion: 'N/A'},
        {id: '265', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'VILLA DE LEYVA', subregion: 'N/A'},
        {id: '266', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'MACANAL', subregion: 'N/A'},
        {id: '267', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'MARIPÍ', subregion: 'N/A'},
        {id: '268', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'MIRAFLORES', subregion: 'N/A'},
        {id: '269', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'MONGUA', subregion: 'N/A'},
        {id: '270', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'MONGUÍ', subregion: 'N/A'},
        {id: '271', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'MONIQUIRÁ', subregion: 'N/A'},
        {id: '272', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'MOTAVITA', subregion: 'N/A'},
        {id: '273', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'MUZO', subregion: 'N/A'},
        {id: '274', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'NOBSA', subregion: 'N/A'},
        {id: '275', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'NUEVO COLÓN', subregion: 'N/A'},
        {id: '276', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'OICATÁ', subregion: 'N/A'},
        {id: '277', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'OTANCHE', subregion: 'N/A'},
        {id: '278', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'PACHAVITA', subregion: 'N/A'},
        {id: '279', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'PÁEZ', subregion: 'N/A'},
        {id: '280', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'PAIPA', subregion: 'N/A'},
        {id: '281', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'PAJARITO', subregion: 'N/A'},
        {id: '282', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'PANQUEBA', subregion: 'N/A'},
        {id: '283', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'PAUNA', subregion: 'N/A'},
        {id: '284', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'PAYA', subregion: 'N/A'},
        {id: '285', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'PAZ DE RÍO', subregion: 'N/A'},
        {id: '286', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'PESCA', subregion: 'N/A'},
        {id: '287', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'PISBA', subregion: 'N/A'},
        {id: '288', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'PUERTO BOYACÁ', subregion: 'N/A'},
        {id: '289', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'QUÍPAMA', subregion: 'N/A'},
        {id: '290', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'RAMIRIQUÍ', subregion: 'N/A'},
        {id: '291', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'RÁQUIRA', subregion: 'N/A'},
        {id: '292', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'RONDÓN', subregion: 'N/A'},
        {id: '293', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SABOYÁ', subregion: 'N/A'},
        {id: '294', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SÁCHICA', subregion: 'N/A'},
        {id: '295', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SAMACÁ', subregion: 'N/A'},
        {id: '296', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SAN EDUARDO', subregion: 'N/A'},
        {id: '297', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SAN JOSÉ DE PARE', subregion: 'N/A'},
        {id: '298', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SAN LUIS DE GACENO', subregion: 'N/A'},
        {id: '299', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SAN MATEO', subregion: 'N/A'},
        {id: '300', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SAN MIGUEL DE SEMA', subregion: 'N/A'},
        {id: '301', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SAN PABLO DE BORBUR', subregion: 'N/A'},
        {id: '302', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SANTANA', subregion: 'N/A'},
        {id: '303', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SANTA MARÍA', subregion: 'N/A'},
        {id: '304', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SANTA ROSA DE VITERBO', subregion: 'N/A'},
        {id: '305', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SANTA SOFÍA', subregion: 'N/A'},
        {id: '306', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SATIVANORTE', subregion: 'N/A'},
        {id: '307', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SATIVASUR', subregion: 'N/A'},
        {id: '308', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SIACHOQUE', subregion: 'N/A'},
        {id: '309', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SOATÁ', subregion: 'N/A'},
        {id: '310', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SOCOTÁ', subregion: 'N/A'},
        {id: '311', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SOCHA', subregion: 'N/A'},
        {id: '312', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SOGAMOSO', subregion: 'N/A'},
        {id: '313', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SOMONDOCO', subregion: 'N/A'},
        {id: '314', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SORA', subregion: 'N/A'},
        {id: '315', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SOTAQUIRÁ', subregion: 'N/A'},
        {id: '316', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SORACÁ', subregion: 'N/A'},
        {id: '317', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SUSACÓN', subregion: 'N/A'},
        {id: '318', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SUTAMARCHÁN', subregion: 'N/A'},
        {id: '319', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'SUTATENZA', subregion: 'N/A'},
        {id: '320', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'TASCO', subregion: 'N/A'},
        {id: '321', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'TENZA', subregion: 'N/A'},
        {id: '322', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'TIBANÁ', subregion: 'N/A'},
        {id: '323', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'TIBASOSA', subregion: 'N/A'},
        {id: '324', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'TINJACÁ', subregion: 'N/A'},
        {id: '325', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'TIPACOQUE', subregion: 'N/A'},
        {id: '326', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'TOCA', subregion: 'N/A'},
        {id: '327', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'TOGÜÍ', subregion: 'N/A'},
        {id: '328', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'TÓPAGA', subregion: 'N/A'},
        {id: '329', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'TOTA', subregion: 'N/A'},
        {id: '330', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'TUNUNGUÁ', subregion: 'N/A'},
        {id: '331', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'TURMEQUÉ', subregion: 'N/A'},
        {id: '332', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'TUTA', subregion: 'N/A'},
        {id: '333', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'TUTAZÁ', subregion: 'N/A'},
        {id: '334', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'ÚMBITA', subregion: 'N/A'},
        {id: '335', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'VENTAQUEMADA', subregion: 'N/A'},
        {id: '336', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'VIRACACHÁ', subregion: 'N/A'},
        {id: '337', cod_dept: '7', departamento: 'BOYACÁ', municipio: 'ZETAQUIRA', subregion: 'N/A'},
        {id: '338', cod_dept: '8', departamento: 'CALDAS', municipio: 'MANIZALES', subregion: 'N/A'},
        {id: '339', cod_dept: '8', departamento: 'CALDAS', municipio: 'AGUADAS', subregion: 'N/A'},
        {id: '340', cod_dept: '8', departamento: 'CALDAS', municipio: 'ANSERMA', subregion: 'N/A'},
        {id: '341', cod_dept: '8', departamento: 'CALDAS', municipio: 'ARANZAZU', subregion: 'N/A'},
        {id: '342', cod_dept: '8', departamento: 'CALDAS', municipio: 'BELALCÁZAR', subregion: 'N/A'},
        {id: '343', cod_dept: '8', departamento: 'CALDAS', municipio: 'CHINCHINÁ', subregion: 'N/A'},
        {id: '344', cod_dept: '8', departamento: 'CALDAS', municipio: 'FILADELFIA', subregion: 'N/A'},
        {id: '345', cod_dept: '8', departamento: 'CALDAS', municipio: 'LA DORADA', subregion: 'N/A'},
        {id: '346', cod_dept: '8', departamento: 'CALDAS', municipio: 'LA MERCED', subregion: 'N/A'},
        {id: '347', cod_dept: '8', departamento: 'CALDAS', municipio: 'MANZANARES', subregion: 'N/A'},
        {id: '348', cod_dept: '8', departamento: 'CALDAS', municipio: 'MARMATO', subregion: 'N/A'},
        {id: '349', cod_dept: '8', departamento: 'CALDAS', municipio: 'MARQUETALIA', subregion: 'N/A'},
        {id: '350', cod_dept: '8', departamento: 'CALDAS', municipio: 'MARULANDA', subregion: 'N/A'},
        {id: '351', cod_dept: '8', departamento: 'CALDAS', municipio: 'NEIRA', subregion: 'N/A'},
        {id: '352', cod_dept: '8', departamento: 'CALDAS', municipio: 'NORCASIA', subregion: 'N/A'},
        {id: '353', cod_dept: '8', departamento: 'CALDAS', municipio: 'PÁCORA', subregion: 'N/A'},
        {id: '354', cod_dept: '8', departamento: 'CALDAS', municipio: 'PALESTINA', subregion: 'N/A'},
        {id: '355', cod_dept: '8', departamento: 'CALDAS', municipio: 'PENSILVANIA', subregion: 'N/A'},
        {id: '356', cod_dept: '8', departamento: 'CALDAS', municipio: 'RIOSUCIO', subregion: 'N/A'},
        {id: '357', cod_dept: '8', departamento: 'CALDAS', municipio: 'RISARALDA', subregion: 'N/A'},
        {id: '358', cod_dept: '8', departamento: 'CALDAS', municipio: 'SALAMINA', subregion: 'N/A'},
        {id: '359', cod_dept: '8', departamento: 'CALDAS', municipio: 'SAMANÁ', subregion: 'N/A'},
        {id: '360', cod_dept: '8', departamento: 'CALDAS', municipio: 'SAN JOSÉ', subregion: 'N/A'},
        {id: '361', cod_dept: '8', departamento: 'CALDAS', municipio: 'SUPÍA', subregion: 'N/A'},
        {id: '362', cod_dept: '8', departamento: 'CALDAS', municipio: 'VICTORIA', subregion: 'N/A'},
        {id: '363', cod_dept: '8', departamento: 'CALDAS', municipio: 'VILLAMARÍA', subregion: 'N/A'},
        {id: '364', cod_dept: '8', departamento: 'CALDAS', municipio: 'VITERBO', subregion: 'N/A'},
        {id: '365', cod_dept: '9', departamento: 'CAQUETÁ', municipio: 'FLORENCIA', subregion: 'N/A'},
        {id: '366', cod_dept: '9', departamento: 'CAQUETÁ', municipio: 'ALBANIA', subregion: 'N/A'},
        {id: '367', cod_dept: '9', departamento: 'CAQUETÁ', municipio: 'BELÉN DE LOS ANDAQUÍES', subregion: 'N/A'},
        {id: '368', cod_dept: '9', departamento: 'CAQUETÁ', municipio: 'CARTAGENA DEL CHAIRÁ', subregion: 'N/A'},
        {id: '369', cod_dept: '9', departamento: 'CAQUETÁ', municipio: 'CURILLO', subregion: 'N/A'},
        {id: '370', cod_dept: '9', departamento: 'CAQUETÁ', municipio: 'EL DONCELLO', subregion: 'N/A'},
        {id: '371', cod_dept: '9', departamento: 'CAQUETÁ', municipio: 'EL PAUJÍL', subregion: 'N/A'},
        {id: '372', cod_dept: '9', departamento: 'CAQUETÁ', municipio: 'LA MONTAÑITA', subregion: 'N/A'},
        {id: '373', cod_dept: '9', departamento: 'CAQUETÁ', municipio: 'MILÁN', subregion: 'N/A'},
        {id: '374', cod_dept: '9', departamento: 'CAQUETÁ', municipio: 'MORELIA', subregion: 'N/A'},
        {id: '375', cod_dept: '9', departamento: 'CAQUETÁ', municipio: 'PUERTO RICO', subregion: 'N/A'},
        {id: '376', cod_dept: '9', departamento: 'CAQUETÁ', municipio: 'SAN JOSÉ DEL FRAGUA', subregion: 'N/A'},
        {id: '377', cod_dept: '9', departamento: 'CAQUETÁ', municipio: 'SAN VICENTE DEL CAGUÁN', subregion: 'N/A'},
        {id: '378', cod_dept: '9', departamento: 'CAQUETÁ', municipio: 'SOLANO', subregion: 'N/A'},
        {id: '379', cod_dept: '9', departamento: 'CAQUETÁ', municipio: 'SOLITA', subregion: 'N/A'},
        {id: '380', cod_dept: '9', departamento: 'CAQUETÁ', municipio: 'VALPARAÍSO', subregion: 'N/A'},
        {id: '381', cod_dept: '10', departamento: 'CASANARE', municipio: 'YOPAL', subregion: 'N/A'},
        {id: '382', cod_dept: '10', departamento: 'CASANARE', municipio: 'AGUAZUL', subregion: 'N/A'},
        {id: '383', cod_dept: '10', departamento: 'CASANARE', municipio: 'CHÁMEZA', subregion: 'N/A'},
        {id: '384', cod_dept: '10', departamento: 'CASANARE', municipio: 'HATO COROZAL', subregion: 'N/A'},
        {id: '385', cod_dept: '10', departamento: 'CASANARE', municipio: 'LA SALINA', subregion: 'N/A'},
        {id: '386', cod_dept: '10', departamento: 'CASANARE', municipio: 'MANÍ', subregion: 'N/A'},
        {id: '387', cod_dept: '10', departamento: 'CASANARE', municipio: 'MONTERREY', subregion: 'N/A'},
        {id: '388', cod_dept: '10', departamento: 'CASANARE', municipio: 'NUNCHÍA', subregion: 'N/A'},
        {id: '389', cod_dept: '10', departamento: 'CASANARE', municipio: 'OROCUÉ', subregion: 'N/A'},
        {id: '390', cod_dept: '10', departamento: 'CASANARE', municipio: 'PAZ DE ARIPORO', subregion: 'N/A'},
        {id: '391', cod_dept: '10', departamento: 'CASANARE', municipio: 'PORE', subregion: 'N/A'},
        {id: '392', cod_dept: '10', departamento: 'CASANARE', municipio: 'RECETOR', subregion: 'N/A'},
        {id: '393', cod_dept: '10', departamento: 'CASANARE', municipio: 'SABANALARGA', subregion: 'N/A'},
        {id: '394', cod_dept: '10', departamento: 'CASANARE', municipio: 'SÁCAMA', subregion: 'N/A'},
        {id: '395', cod_dept: '10', departamento: 'CASANARE', municipio: 'SAN LUIS DE PALENQUE', subregion: 'N/A'},
        {id: '396', cod_dept: '10', departamento: 'CASANARE', municipio: 'TÁMARA', subregion: 'N/A'},
        {id: '397', cod_dept: '10', departamento: 'CASANARE', municipio: 'TAURAMENA', subregion: 'N/A'},
        {id: '398', cod_dept: '10', departamento: 'CASANARE', municipio: 'TRINIDAD', subregion: 'N/A'},
        {id: '399', cod_dept: '10', departamento: 'CASANARE', municipio: 'VILLANUEVA', subregion: 'N/A'},
        {id: '400', cod_dept: '11', departamento: 'CAUCA', municipio: 'POPAYÁN', subregion: 'N/A'},
        {id: '401', cod_dept: '11', departamento: 'CAUCA', municipio: 'ALMAGUER', subregion: 'N/A'},
        {id: '402', cod_dept: '11', departamento: 'CAUCA', municipio: 'ARGELIA', subregion: 'N/A'},
        {id: '403', cod_dept: '11', departamento: 'CAUCA', municipio: 'BALBOA', subregion: 'N/A'},
        {id: '404', cod_dept: '11', departamento: 'CAUCA', municipio: 'BOLÍVAR', subregion: 'N/A'},
        {id: '405', cod_dept: '11', departamento: 'CAUCA', municipio: 'BUENOS AIRES', subregion: 'N/A'},
        {id: '406', cod_dept: '11', departamento: 'CAUCA', municipio: 'CAJIBÍO', subregion: 'N/A'},
        {id: '407', cod_dept: '11', departamento: 'CAUCA', municipio: 'CALDONO', subregion: 'N/A'},
        {id: '408', cod_dept: '11', departamento: 'CAUCA', municipio: 'CALOTO', subregion: 'N/A'},
        {id: '409', cod_dept: '11', departamento: 'CAUCA', municipio: 'CORINTO', subregion: 'N/A'},
        {id: '410', cod_dept: '11', departamento: 'CAUCA', municipio: 'EL TAMBO', subregion: 'N/A'},
        {id: '411', cod_dept: '11', departamento: 'CAUCA', municipio: 'FLORENCIA', subregion: 'N/A'},
        {id: '412', cod_dept: '11', departamento: 'CAUCA', municipio: 'GUACHENÉ', subregion: 'N/A'},
        {id: '413', cod_dept: '11', departamento: 'CAUCA', municipio: 'GUAPÍ', subregion: 'N/A'},
        {id: '414', cod_dept: '11', departamento: 'CAUCA', municipio: 'INZÁ', subregion: 'N/A'},
        {id: '415', cod_dept: '11', departamento: 'CAUCA', municipio: 'JAMBALÓ', subregion: 'N/A'},
        {id: '416', cod_dept: '11', departamento: 'CAUCA', municipio: 'LA SIERRA', subregion: 'N/A'},
        {id: '417', cod_dept: '11', departamento: 'CAUCA', municipio: 'LA VEGA', subregion: 'N/A'},
        {id: '418', cod_dept: '11', departamento: 'CAUCA', municipio: 'LÓPEZ DE MICAY', subregion: 'N/A'},
        {id: '419', cod_dept: '11', departamento: 'CAUCA', municipio: 'MERCADERES', subregion: 'N/A'},
        {id: '420', cod_dept: '11', departamento: 'CAUCA', municipio: 'MIRANDA', subregion: 'N/A'},
        {id: '421', cod_dept: '11', departamento: 'CAUCA', municipio: 'MORALES', subregion: 'N/A'},
        {id: '422', cod_dept: '11', departamento: 'CAUCA', municipio: 'PADILLA', subregion: 'N/A'},
        {id: '423', cod_dept: '11', departamento: 'CAUCA', municipio: 'PÁEZ', subregion: 'N/A'},
        {id: '424', cod_dept: '11', departamento: 'CAUCA', municipio: 'PATÍA', subregion: 'N/A'},
        {id: '425', cod_dept: '11', departamento: 'CAUCA', municipio: 'PIAMONTE', subregion: 'N/A'},
        {id: '426', cod_dept: '11', departamento: 'CAUCA', municipio: 'PIENDAMÓ', subregion: 'N/A'},
        {id: '427', cod_dept: '11', departamento: 'CAUCA', municipio: 'PUERTO TEJADA', subregion: 'N/A'},
        {id: '428', cod_dept: '11', departamento: 'CAUCA', municipio: 'PURACÉ', subregion: 'N/A'},
        {id: '429', cod_dept: '11', departamento: 'CAUCA', municipio: 'ROSAS', subregion: 'N/A'},
        {id: '430', cod_dept: '11', departamento: 'CAUCA', municipio: 'SAN SEBASTIÁN', subregion: 'N/A'},
        {id: '431', cod_dept: '11', departamento: 'CAUCA', municipio: 'SANTANDER DE QUILICHAO', subregion: 'N/A'},
        {id: '432', cod_dept: '11', departamento: 'CAUCA', municipio: 'SANTA ROSA', subregion: 'N/A'},
        {id: '433', cod_dept: '11', departamento: 'CAUCA', municipio: 'SILVIA', subregion: 'N/A'},
        {id: '434', cod_dept: '11', departamento: 'CAUCA', municipio: 'SOTARA', subregion: 'N/A'},
        {id: '435', cod_dept: '11', departamento: 'CAUCA', municipio: 'SUÁREZ', subregion: 'N/A'},
        {id: '436', cod_dept: '11', departamento: 'CAUCA', municipio: 'SUCRE', subregion: 'N/A'},
        {id: '437', cod_dept: '11', departamento: 'CAUCA', municipio: 'TIMBÍO', subregion: 'N/A'},
        {id: '438', cod_dept: '11', departamento: 'CAUCA', municipio: 'TIMBIQUÍ', subregion: 'N/A'},
        {id: '439', cod_dept: '11', departamento: 'CAUCA', municipio: 'TORIBÍO', subregion: 'N/A'},
        {id: '440', cod_dept: '11', departamento: 'CAUCA', municipio: 'TOTORÓ', subregion: 'N/A'},
        {id: '441', cod_dept: '11', departamento: 'CAUCA', municipio: 'VILLA RICA', subregion: 'N/A'},
        {id: '442', cod_dept: '12', departamento: 'CESAR', municipio: 'VALLEDUPAR', subregion: 'N/A'},
        {id: '443', cod_dept: '12', departamento: 'CESAR', municipio: 'AGUACHICA', subregion: 'N/A'},
        {id: '444', cod_dept: '12', departamento: 'CESAR', municipio: 'AGUSTÍN CODAZZI', subregion: 'N/A'},
        {id: '445', cod_dept: '12', departamento: 'CESAR', municipio: 'ASTREA', subregion: 'N/A'},
        {id: '446', cod_dept: '12', departamento: 'CESAR', municipio: 'BECERRIL', subregion: 'N/A'},
        {id: '447', cod_dept: '12', departamento: 'CESAR', municipio: 'BOSCONIA', subregion: 'N/A'},
        {id: '448', cod_dept: '12', departamento: 'CESAR', municipio: 'CHIMICHAGUA', subregion: 'N/A'},
        {id: '449', cod_dept: '12', departamento: 'CESAR', municipio: 'CHIRIGUANÁ', subregion: 'N/A'},
        {id: '450', cod_dept: '12', departamento: 'CESAR', municipio: 'CURUMANÍ', subregion: 'N/A'},
        {id: '451', cod_dept: '12', departamento: 'CESAR', municipio: 'EL COPEY', subregion: 'N/A'},
        {id: '452', cod_dept: '12', departamento: 'CESAR', municipio: 'EL PASO', subregion: 'N/A'},
        {id: '453', cod_dept: '12', departamento: 'CESAR', municipio: 'GAMARRA', subregion: 'N/A'},
        {id: '454', cod_dept: '12', departamento: 'CESAR', municipio: 'GONZÁLEZ', subregion: 'N/A'},
        {id: '455', cod_dept: '12', departamento: 'CESAR', municipio: 'LA GLORIA', subregion: 'N/A'},
        {id: '456', cod_dept: '12', departamento: 'CESAR', municipio: 'LA JAGUA DE IBIRICO', subregion: 'N/A'},
        {id: '457', cod_dept: '12', departamento: 'CESAR', municipio: 'MANAURE BALCÓN DEL CESAR', subregion: 'N/A'},
        {id: '458', cod_dept: '12', departamento: 'CESAR', municipio: 'PAILITAS', subregion: 'N/A'},
        {id: '459', cod_dept: '12', departamento: 'CESAR', municipio: 'PELAYA', subregion: 'N/A'},
        {id: '460', cod_dept: '12', departamento: 'CESAR', municipio: 'PUEBLO BELLO', subregion: 'N/A'},
        {id: '461', cod_dept: '12', departamento: 'CESAR', municipio: 'RÍO DE ORO', subregion: 'N/A'},
        {id: '462', cod_dept: '12', departamento: 'CESAR', municipio: 'LA PAZ', subregion: 'N/A'},
        {id: '463', cod_dept: '12', departamento: 'CESAR', municipio: 'SAN ALBERTO', subregion: 'N/A'},
        {id: '464', cod_dept: '12', departamento: 'CESAR', municipio: 'SAN DIEGO', subregion: 'N/A'},
        {id: '465', cod_dept: '12', departamento: 'CESAR', municipio: 'SAN MARTÍN', subregion: 'N/A'},
        {id: '466', cod_dept: '12', departamento: 'CESAR', municipio: 'TAMALAMEQUE', subregion: 'N/A'},
        {id: '467', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'QUIBDÓ', subregion: 'N/A'},
        {id: '468', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'ACANDÍ', subregion: 'N/A'},
        {id: '469', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'ALTO BAUDÓ', subregion: 'N/A'},
        {id: '470', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'ATRATO', subregion: 'N/A'},
        {id: '471', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'BAGADÓ', subregion: 'N/A'},
        {id: '472', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'BAHÍA SOLANO', subregion: 'N/A'},
        {id: '473', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'BAJO BAUDÓ', subregion: 'N/A'},
        {id: '474', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'BOJAYÁ', subregion: 'N/A'},
        {id: '475', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'BELEN DE BAJIRÁ', subregion: 'N/A'},
        {id: '476', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'EL CANTÓN DEL SAN PABLO', subregion: 'N/A'},
        {id: '477', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'CARMEN DEL DARIÉN', subregion: 'N/A'},
        {id: '478', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'CÉRTEGUI', subregion: 'N/A'},
        {id: '479', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'CONDOTO', subregion: 'N/A'},
        {id: '480', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'EL CARMEN DE ATRATO', subregion: 'N/A'},
        {id: '481', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'EL LITORAL DEL SAN JUAN', subregion: 'N/A'},
        {id: '482', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'ISTMINA', subregion: 'N/A'},
        {id: '483', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'JURADÓ', subregion: 'N/A'},
        {id: '484', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'LLORÓ', subregion: 'N/A'},
        {id: '485', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'MEDIO ATRATO', subregion: 'N/A'},
        {id: '486', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'MEDIO BAUDÓ', subregion: 'N/A'},
        {id: '487', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'MEDIO SAN JUAN', subregion: 'N/A'},
        {id: '488', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'NÓVITA', subregion: 'N/A'},
        {id: '489', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'NUQUÍ', subregion: 'N/A'},
        {id: '490', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'RÍO IRÓ', subregion: 'N/A'},
        {id: '491', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'RÍO QUITO', subregion: 'N/A'},
        {id: '492', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'RIOSUCIO', subregion: 'N/A'},
        {id: '493', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'SAN JOSÉ DEL PALMAR', subregion: 'N/A'},
        {id: '494', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'SIPÍ', subregion: 'N/A'},
        {id: '495', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'TADÓ', subregion: 'N/A'},
        {id: '496', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'UNGUÍA', subregion: 'N/A'},
        {id: '497', cod_dept: '13', departamento: 'CHOCÓ', municipio: 'UNIÓN PANAMERICANA', subregion: 'N/A'},
        {id: '498', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'MONTERÍA', subregion: 'N/A'},
        {id: '499', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'AYAPEL', subregion: 'N/A'},
        {id: '500', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'BUENAVISTA', subregion: 'N/A'},
        {id: '501', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'CANALETE', subregion: 'N/A'},
        {id: '502', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'CERETÉ', subregion: 'N/A'},
        {id: '503', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'CHIMÁ', subregion: 'N/A'},
        {id: '504', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'CHINÚ', subregion: 'N/A'},
        {id: '505', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'CIÉNAGA DE ORO', subregion: 'N/A'},
        {id: '506', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'COTORRA', subregion: 'N/A'},
        {id: '507', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'LA APARTADA', subregion: 'N/A'},
        {id: '508', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'LORICA', subregion: 'N/A'},
        {id: '509', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'LOS CÓRDOBAS', subregion: 'N/A'},
        {id: '510', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'MOMIL', subregion: 'N/A'},
        {id: '511', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'MONTELÍBANO', subregion: 'N/A'},
        {id: '512', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'MOÑITOS', subregion: 'N/A'},
        {id: '513', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'PLANETA RICA', subregion: 'N/A'},
        {id: '514', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'PUEBLO NUEVO', subregion: 'N/A'},
        {id: '515', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'PUERTO ESCONDIDO', subregion: 'N/A'},
        {id: '516', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'PUERTO LIBERTADOR', subregion: 'N/A'},
        {id: '517', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'PURÍSIMA DE LA CONCEPCIÓN', subregion: 'N/A'},
        {id: '518', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'SAHAGÚN', subregion: 'N/A'},
        {id: '519', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'SAN ANDRÉS DE SOTAVENTO', subregion: 'N/A'},
        {id: '520', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'SAN ANTERO', subregion: 'N/A'},
        {id: '521', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'SAN BERNARDO DEL VIENTO', subregion: 'N/A'},
        {id: '522', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'SAN CARLOS', subregion: 'N/A'},
        {id: '523', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'SAN JOSÉ DE URÉ', subregion: 'N/A'},
        {id: '524', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'SAN PELAYO', subregion: 'N/A'},
        {id: '525', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'TIERRALTA', subregion: 'N/A'},
        {id: '526', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'TUCHÍN', subregion: 'N/A'},
        {id: '527', cod_dept: '14', departamento: 'CÓRDOBA', municipio: 'VALENCIA', subregion: 'N/A'},
        {id: '528', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'BOGOTÁ, D.C.', subregion: 'N/A'},
        {id: '529', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'AGUA DE DIOS', subregion: 'N/A'},
        {id: '530', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'ALBÁN', subregion: 'N/A'},
        {id: '531', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'ANAPOIMA', subregion: 'N/A'},
        {id: '532', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'ANOLAIMA', subregion: 'N/A'},
        {id: '533', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'ARBELÁEZ', subregion: 'N/A'},
        {id: '534', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'BELTRÁN', subregion: 'N/A'},
        {id: '535', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'BITUIMA', subregion: 'N/A'},
        {id: '536', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'BOJACÁ', subregion: 'N/A'},
        {id: '537', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'CABRERA', subregion: 'N/A'},
        {id: '538', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'CACHIPAY', subregion: 'N/A'},
        {id: '539', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'CAJICÁ', subregion: 'N/A'},
        {id: '540', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'CAPARRAPÍ', subregion: 'N/A'},
        {id: '541', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'CÁQUEZA', subregion: 'N/A'},
        {id: '542', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'CARMEN DE CARUPA', subregion: 'N/A'},
        {id: '543', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'CHAGUANÍ', subregion: 'N/A'},
        {id: '544', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'CHÍA', subregion: 'N/A'},
        {id: '545', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'CHIPAQUE', subregion: 'N/A'},
        {id: '546', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'CHOACHÍ', subregion: 'N/A'},
        {id: '547', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'CHOCONTÁ', subregion: 'N/A'},
        {id: '548', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'COGUA', subregion: 'N/A'},
        {id: '549', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'COTA', subregion: 'N/A'},
        {id: '550', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'CUCUNUBÁ', subregion: 'N/A'},
        {id: '551', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'EL COLEGIO', subregion: 'N/A'},
        {id: '552', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'EL PEÑÓN', subregion: 'N/A'},
        {id: '553', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'EL ROSAL', subregion: 'N/A'},
        {id: '554', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'FACATATIVÁ', subregion: 'N/A'},
        {id: '555', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'FÓMEQUE', subregion: 'N/A'},
        {id: '556', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'FOSCA', subregion: 'N/A'},
        {id: '557', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'FUNZA', subregion: 'N/A'},
        {id: '558', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'FÚQUENE', subregion: 'N/A'},
        {id: '559', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'FUSAGASUGÁ', subregion: 'N/A'},
        {id: '560', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'GACHALÁ', subregion: 'N/A'},
        {id: '561', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'GACHANCIPÁ', subregion: 'N/A'},
        {id: '562', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'GACHETÁ', subregion: 'N/A'},
        {id: '563', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'GAMA', subregion: 'N/A'},
        {id: '564', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'GIRARDOT', subregion: 'N/A'},
        {id: '565', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'GRANADA', subregion: 'N/A'},
        {id: '566', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'GUACHETÁ', subregion: 'N/A'},
        {id: '567', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'GUADUAS', subregion: 'N/A'},
        {id: '568', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'GUASCA', subregion: 'N/A'},
        {id: '569', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'GUATAQUÍ', subregion: 'N/A'},
        {id: '570', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'GUATAVITA', subregion: 'N/A'},
        {id: '571', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'GUAYABAL DE SÍQUIMA', subregion: 'N/A'},
        {id: '572', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'GUAYABETAL', subregion: 'N/A'},
        {id: '573', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'GUTIÉRREZ', subregion: 'N/A'},
        {id: '574', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'JERUSALÉN', subregion: 'N/A'},
        {id: '575', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'JUNÍN', subregion: 'N/A'},
        {id: '576', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'LA CALERA', subregion: 'N/A'},
        {id: '577', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'LA MESA', subregion: 'N/A'},
        {id: '578', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'LA PALMA', subregion: 'N/A'},
        {id: '579', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'LA PEÑA', subregion: 'N/A'},
        {id: '580', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'LA VEGA', subregion: 'N/A'},
        {id: '581', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'LENGUAZAQUE', subregion: 'N/A'},
        {id: '582', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'MACHETÁ', subregion: 'N/A'},
        {id: '583', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'MADRID', subregion: 'N/A'},
        {id: '584', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'MANTA', subregion: 'N/A'},
        {id: '585', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'MEDINA', subregion: 'N/A'},
        {id: '586', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'MOSQUERA', subregion: 'N/A'},
        {id: '587', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'NARIÑO', subregion: 'N/A'},
        {id: '588', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'NEMOCÓN', subregion: 'N/A'},
        {id: '589', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'NILO', subregion: 'N/A'},
        {id: '590', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'NIMAIMA', subregion: 'N/A'},
        {id: '591', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'NOCAIMA', subregion: 'N/A'},
        {id: '592', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'VENECIA', subregion: 'N/A'},
        {id: '593', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'PACHO', subregion: 'N/A'},
        {id: '594', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'PAIME', subregion: 'N/A'},
        {id: '595', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'PANDI', subregion: 'N/A'},
        {id: '596', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'PARATEBUENO', subregion: 'N/A'},
        {id: '597', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'PASCA', subregion: 'N/A'},
        {id: '598', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'PUERTO SALGAR', subregion: 'N/A'},
        {id: '599', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'PULÍ', subregion: 'N/A'},
        {id: '600', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'QUEBRADANEGRA', subregion: 'N/A'},
        {id: '601', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'QUETAME', subregion: 'N/A'},
        {id: '602', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'QUIPILE', subregion: 'N/A'},
        {id: '603', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'APULO', subregion: 'N/A'},
        {id: '604', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'RICAURTE', subregion: 'N/A'},
        {id: '605', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'SAN ANTONIO DEL TEQUENDAMA', subregion: 'N/A'},
        {id: '606', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'SAN BERNARDO', subregion: 'N/A'},
        {id: '607', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'SAN CAYETANO', subregion: 'N/A'},
        {id: '608', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'SAN FRANCISCO', subregion: 'N/A'},
        {id: '609', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'SAN JUAN DE RIOSECO', subregion: 'N/A'},
        {id: '610', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'SASAIMA', subregion: 'N/A'},
        {id: '611', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'SESQUILÉ', subregion: 'N/A'},
        {id: '612', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'SIBATÉ', subregion: 'N/A'},
        {id: '613', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'SILVANIA', subregion: 'N/A'},
        {id: '614', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'SIMIJACA', subregion: 'N/A'},
        {id: '615', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'SOACHA', subregion: 'N/A'},
        {id: '616', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'SOPÓ', subregion: 'N/A'},
        {id: '617', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'SUBACHOQUE', subregion: 'N/A'},
        {id: '618', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'SUESCA', subregion: 'N/A'},
        {id: '619', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'SUPATÁ', subregion: 'N/A'},
        {id: '620', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'SUSA', subregion: 'N/A'},
        {id: '621', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'SUTATAUSA', subregion: 'N/A'},
        {id: '622', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'TABIO', subregion: 'N/A'},
        {id: '623', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'TAUSA', subregion: 'N/A'},
        {id: '624', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'TENA', subregion: 'N/A'},
        {id: '625', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'TENJO', subregion: 'N/A'},
        {id: '626', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'TIBACUY', subregion: 'N/A'},
        {id: '627', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'TIBIRITA', subregion: 'N/A'},
        {id: '628', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'TOCAIMA', subregion: 'N/A'},
        {id: '629', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'TOCANCIPÁ', subregion: 'N/A'},
        {id: '630', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'TOPAIPÍ', subregion: 'N/A'},
        {id: '631', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'UBALÁ', subregion: 'N/A'},
        {id: '632', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'UBAQUE', subregion: 'N/A'},
        {id: '633', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'VILLA DE SAN DIEGO DE UBATÉ', subregion: 'N/A'},
        {id: '634', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'UNE', subregion: 'N/A'},
        {id: '635', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'ÚTICA', subregion: 'N/A'},
        {id: '636', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'VERGARA', subregion: 'N/A'},
        {id: '637', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'VIANÍ', subregion: 'N/A'},
        {id: '638', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'VILLAGÓMEZ', subregion: 'N/A'},
        {id: '639', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'VILLAPINZÓN', subregion: 'N/A'},
        {id: '640', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'VILLETA', subregion: 'N/A'},
        {id: '641', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'VIOTÁ', subregion: 'N/A'},
        {id: '642', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'YACOPÍ', subregion: 'N/A'},
        {id: '643', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'ZIPACÓN', subregion: 'N/A'},
        {id: '644', cod_dept: '15', departamento: 'CUNDINAMARCA', municipio: 'ZIPAQUIRÁ', subregion: 'N/A'},
        {id: '645', cod_dept: '16', departamento: 'GUAINÍA', municipio: 'INÍRIDA', subregion: 'N/A'},
        {id: '646', cod_dept: '16', departamento: 'GUAINÍA', municipio: 'BARRANCO MINAS', subregion: 'N/A'},
        {id: '647', cod_dept: '16', departamento: 'GUAINÍA', municipio: 'MAPIRIPANA', subregion: 'N/A'},
        {id: '648', cod_dept: '16', departamento: 'GUAINÍA', municipio: 'SAN FELIPE', subregion: 'N/A'},
        {id: '649', cod_dept: '16', departamento: 'GUAINÍA', municipio: 'PUERTO COLOMBIA', subregion: 'N/A'},
        {id: '650', cod_dept: '16', departamento: 'GUAINÍA', municipio: 'LA GUADALUPE', subregion: 'N/A'},
        {id: '651', cod_dept: '16', departamento: 'GUAINÍA', municipio: 'CACAHUAL', subregion: 'N/A'},
        {id: '652', cod_dept: '16', departamento: 'GUAINÍA', municipio: 'PANA PANA', subregion: 'N/A'},
        {id: '653', cod_dept: '16', departamento: 'GUAINÍA', municipio: 'MORICHAL', subregion: 'N/A'},
        {id: '654', cod_dept: '17', departamento: 'GUAVIARE', municipio: 'SAN JOSÉ DEL GUAVIARE', subregion: 'N/A'},
        {id: '655', cod_dept: '17', departamento: 'GUAVIARE', municipio: 'CALAMAR', subregion: 'N/A'},
        {id: '656', cod_dept: '17', departamento: 'GUAVIARE', municipio: 'EL RETORNO', subregion: 'N/A'},
        {id: '657', cod_dept: '17', departamento: 'GUAVIARE', municipio: 'MIRAFLORES', subregion: 'N/A'},
        {id: '658', cod_dept: '18', departamento: 'HUILA', municipio: 'NEIVA', subregion: 'N/A'},
        {id: '659', cod_dept: '18', departamento: 'HUILA', municipio: 'ACEVEDO', subregion: 'N/A'},
        {id: '660', cod_dept: '18', departamento: 'HUILA', municipio: 'AGRADO', subregion: 'N/A'},
        {id: '661', cod_dept: '18', departamento: 'HUILA', municipio: 'AIPE', subregion: 'N/A'},
        {id: '662', cod_dept: '18', departamento: 'HUILA', municipio: 'ALGECIRAS', subregion: 'N/A'},
        {id: '663', cod_dept: '18', departamento: 'HUILA', municipio: 'ALTAMIRA', subregion: 'N/A'},
        {id: '664', cod_dept: '18', departamento: 'HUILA', municipio: 'BARAYA', subregion: 'N/A'},
        {id: '665', cod_dept: '18', departamento: 'HUILA', municipio: 'CAMPOALEGRE', subregion: 'N/A'},
        {id: '666', cod_dept: '18', departamento: 'HUILA', municipio: 'COLOMBIA', subregion: 'N/A'},
        {id: '667', cod_dept: '18', departamento: 'HUILA', municipio: 'ELÍAS', subregion: 'N/A'},
        {id: '668', cod_dept: '18', departamento: 'HUILA', municipio: 'GARZÓN', subregion: 'N/A'},
        {id: '669', cod_dept: '18', departamento: 'HUILA', municipio: 'GIGANTE', subregion: 'N/A'},
        {id: '670', cod_dept: '18', departamento: 'HUILA', municipio: 'GUADALUPE', subregion: 'N/A'},
        {id: '671', cod_dept: '18', departamento: 'HUILA', municipio: 'HOBO', subregion: 'N/A'},
        {id: '672', cod_dept: '18', departamento: 'HUILA', municipio: 'ÍQUIRA', subregion: 'N/A'},
        {id: '673', cod_dept: '18', departamento: 'HUILA', municipio: 'ISNOS', subregion: 'N/A'},
        {id: '674', cod_dept: '18', departamento: 'HUILA', municipio: 'LA ARGENTINA', subregion: 'N/A'},
        {id: '675', cod_dept: '18', departamento: 'HUILA', municipio: 'LA PLATA', subregion: 'N/A'},
        {id: '676', cod_dept: '18', departamento: 'HUILA', municipio: 'NÁTAGA', subregion: 'N/A'},
        {id: '677', cod_dept: '18', departamento: 'HUILA', municipio: 'OPORAPA', subregion: 'N/A'},
        {id: '678', cod_dept: '18', departamento: 'HUILA', municipio: 'PAICOL', subregion: 'N/A'},
        {id: '679', cod_dept: '18', departamento: 'HUILA', municipio: 'PALERMO', subregion: 'N/A'},
        {id: '680', cod_dept: '18', departamento: 'HUILA', municipio: 'PALESTINA', subregion: 'N/A'},
        {id: '681', cod_dept: '18', departamento: 'HUILA', municipio: 'PITAL', subregion: 'N/A'},
        {id: '682', cod_dept: '18', departamento: 'HUILA', municipio: 'PITALITO', subregion: 'N/A'},
        {id: '683', cod_dept: '18', departamento: 'HUILA', municipio: 'RIVERA', subregion: 'N/A'},
        {id: '684', cod_dept: '18', departamento: 'HUILA', municipio: 'SALADOBLANCO', subregion: 'N/A'},
        {id: '685', cod_dept: '18', departamento: 'HUILA', municipio: 'SAN AGUSTÍN', subregion: 'N/A'},
        {id: '686', cod_dept: '18', departamento: 'HUILA', municipio: 'SANTA MARÍA', subregion: 'N/A'},
        {id: '687', cod_dept: '18', departamento: 'HUILA', municipio: 'SUAZA', subregion: 'N/A'},
        {id: '688', cod_dept: '18', departamento: 'HUILA', municipio: 'TARQUI', subregion: 'N/A'},
        {id: '689', cod_dept: '18', departamento: 'HUILA', municipio: 'TESALIA', subregion: 'N/A'},
        {id: '690', cod_dept: '18', departamento: 'HUILA', municipio: 'TELLO', subregion: 'N/A'},
        {id: '691', cod_dept: '18', departamento: 'HUILA', municipio: 'TERUEL', subregion: 'N/A'},
        {id: '692', cod_dept: '18', departamento: 'HUILA', municipio: 'TIMANÁ', subregion: 'N/A'},
        {id: '693', cod_dept: '18', departamento: 'HUILA', municipio: 'VILLAVIEJA', subregion: 'N/A'},
        {id: '694', cod_dept: '18', departamento: 'HUILA', municipio: 'YAGUARÁ', subregion: 'N/A'},
        {id: '695', cod_dept: '19', departamento: 'LA GUAJIRA', municipio: 'RIOHACHA', subregion: 'N/A'},
        {id: '696', cod_dept: '19', departamento: 'LA GUAJIRA', municipio: 'ALBANIA', subregion: 'N/A'},
        {id: '697', cod_dept: '19', departamento: 'LA GUAJIRA', municipio: 'BARRANCAS', subregion: 'N/A'},
        {id: '698', cod_dept: '19', departamento: 'LA GUAJIRA', municipio: 'DIBULLA', subregion: 'N/A'},
        {id: '699', cod_dept: '19', departamento: 'LA GUAJIRA', municipio: 'DISTRACCIÓN', subregion: 'N/A'},
        {id: '700', cod_dept: '19', departamento: 'LA GUAJIRA', municipio: 'EL MOLINO', subregion: 'N/A'},
        {id: '701', cod_dept: '19', departamento: 'LA GUAJIRA', municipio: 'FONSECA', subregion: 'N/A'},
        {id: '702', cod_dept: '19', departamento: 'LA GUAJIRA', municipio: 'HATONUEVO', subregion: 'N/A'},
        {id: '703', cod_dept: '19', departamento: 'LA GUAJIRA', municipio: 'LA JAGUA DEL PILAR', subregion: 'N/A'},
        {id: '704', cod_dept: '19', departamento: 'LA GUAJIRA', municipio: 'MAICAO', subregion: 'N/A'},
        {id: '705', cod_dept: '19', departamento: 'LA GUAJIRA', municipio: 'MANAURE', subregion: 'N/A'},
        {id: '706', cod_dept: '19', departamento: 'LA GUAJIRA', municipio: 'SAN JUAN DEL CESAR', subregion: 'N/A'},
        {id: '707', cod_dept: '19', departamento: 'LA GUAJIRA', municipio: 'URIBIA', subregion: 'N/A'},
        {id: '708', cod_dept: '19', departamento: 'LA GUAJIRA', municipio: 'URUMITA', subregion: 'N/A'},
        {id: '709', cod_dept: '19', departamento: 'LA GUAJIRA', municipio: 'VILLANUEVA', subregion: 'N/A'},
        {id: '710', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'SANTA MARTA', subregion: 'N/A'},
        {id: '711', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'ALGARROBO', subregion: 'N/A'},
        {id: '712', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'ARACATACA', subregion: 'N/A'},
        {id: '713', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'ARIGUANÍ', subregion: 'N/A'},
        {id: '714', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'CERRO DE SAN ANTONIO', subregion: 'N/A'},
        {id: '715', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'CHIVOLO', subregion: 'N/A'},
        {id: '716', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'CIÉNAGA', subregion: 'N/A'},
        {id: '717', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'CONCORDIA', subregion: 'N/A'},
        {id: '718', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'EL BANCO', subregion: 'N/A'},
        {id: '719', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'EL PIÑÓN', subregion: 'N/A'},
        {id: '720', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'EL RETÉN', subregion: 'N/A'},
        {id: '721', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'FUNDACIÓN', subregion: 'N/A'},
        {id: '722', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'GUAMAL', subregion: 'N/A'},
        {id: '723', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'NUEVA GRANADA', subregion: 'N/A'},
        {id: '724', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'PEDRAZA', subregion: 'N/A'},
        {id: '725', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'PIJIÑO DEL CARMEN', subregion: 'N/A'},
        {id: '726', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'PIVIJAY', subregion: 'N/A'},
        {id: '727', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'PLATO', subregion: 'N/A'},
        {id: '728', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'PUEBLOVIEJO', subregion: 'N/A'},
        {id: '729', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'REMOLINO', subregion: 'N/A'},
        {id: '730', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'SABANAS DE SAN ÁNGEL', subregion: 'N/A'},
        {id: '731', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'SALAMINA', subregion: 'N/A'},
        {id: '732', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'SAN SEBASTIÁN DE BUENAVISTA', subregion: 'N/A'},
        {id: '733', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'SAN ZENÓN', subregion: 'N/A'},
        {id: '734', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'SANTA ANA', subregion: 'N/A'},
        {id: '735', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'SANTA BÁRBARA DE PINTO', subregion: 'N/A'},
        {id: '736', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'SITIONUEVO', subregion: 'N/A'},
        {id: '737', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'TENERIFE', subregion: 'N/A'},
        {id: '738', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'ZAPAYÁN', subregion: 'N/A'},
        {id: '739', cod_dept: '20', departamento: 'MAGDALENA', municipio: 'ZONA BANANERA', subregion: 'N/A'},
        {id: '740', cod_dept: '21', departamento: 'META', municipio: 'VILLAVICENCIO', subregion: 'N/A'},
        {id: '741', cod_dept: '21', departamento: 'META', municipio: 'ACACÍAS', subregion: 'N/A'},
        {id: '742', cod_dept: '21', departamento: 'META', municipio: 'BARRANCA DE UPÍA', subregion: 'N/A'},
        {id: '743', cod_dept: '21', departamento: 'META', municipio: 'CABUYARO', subregion: 'N/A'},
        {id: '744', cod_dept: '21', departamento: 'META', municipio: 'CASTILLA LA NUEVA', subregion: 'N/A'},
        {id: '745', cod_dept: '21', departamento: 'META', municipio: 'SAN LUIS DE CUBARRAL', subregion: 'N/A'},
        {id: '746', cod_dept: '21', departamento: 'META', municipio: 'CUMARAL', subregion: 'N/A'},
        {id: '747', cod_dept: '21', departamento: 'META', municipio: 'EL CALVARIO', subregion: 'N/A'},
        {id: '748', cod_dept: '21', departamento: 'META', municipio: 'EL CASTILLO', subregion: 'N/A'},
        {id: '749', cod_dept: '21', departamento: 'META', municipio: 'EL DORADO', subregion: 'N/A'},
        {id: '750', cod_dept: '21', departamento: 'META', municipio: 'FUENTE DE ORO', subregion: 'N/A'},
        {id: '751', cod_dept: '21', departamento: 'META', municipio: 'GRANADA', subregion: 'N/A'},
        {id: '752', cod_dept: '21', departamento: 'META', municipio: 'GUAMAL', subregion: 'N/A'},
        {id: '753', cod_dept: '21', departamento: 'META', municipio: 'MAPIRIPÁN', subregion: 'N/A'},
        {id: '754', cod_dept: '21', departamento: 'META', municipio: 'MESETAS', subregion: 'N/A'},
        {id: '755', cod_dept: '21', departamento: 'META', municipio: 'LA MACARENA', subregion: 'N/A'},
        {id: '756', cod_dept: '21', departamento: 'META', municipio: 'URIBE', subregion: 'N/A'},
        {id: '757', cod_dept: '21', departamento: 'META', municipio: 'LEJANÍAS', subregion: 'N/A'},
        {id: '758', cod_dept: '21', departamento: 'META', municipio: 'PUERTO CONCORDIA', subregion: 'N/A'},
        {id: '759', cod_dept: '21', departamento: 'META', municipio: 'PUERTO GAITÁN', subregion: 'N/A'},
        {id: '760', cod_dept: '21', departamento: 'META', municipio: 'PUERTO LÓPEZ', subregion: 'N/A'},
        {id: '761', cod_dept: '21', departamento: 'META', municipio: 'PUERTO LLERAS', subregion: 'N/A'},
        {id: '762', cod_dept: '21', departamento: 'META', municipio: 'PUERTO RICO', subregion: 'N/A'},
        {id: '763', cod_dept: '21', departamento: 'META', municipio: 'RESTREPO', subregion: 'N/A'},
        {id: '764', cod_dept: '21', departamento: 'META', municipio: 'SAN CARLOS DE GUAROA', subregion: 'N/A'},
        {id: '765', cod_dept: '21', departamento: 'META', municipio: 'SAN JUAN DE ARAMA', subregion: 'N/A'},
        {id: '766', cod_dept: '21', departamento: 'META', municipio: 'SAN JUANITO', subregion: 'N/A'},
        {id: '767', cod_dept: '21', departamento: 'META', municipio: 'SAN MARTÍN', subregion: 'N/A'},
        {id: '768', cod_dept: '21', departamento: 'META', municipio: 'VISTAHERMOSA', subregion: 'N/A'},
        {id: '769', cod_dept: '22', departamento: 'NARIÑO', municipio: 'PASTO', subregion: 'N/A'},
        {id: '770', cod_dept: '22', departamento: 'NARIÑO', municipio: 'ALBÁN', subregion: 'N/A'},
        {id: '771', cod_dept: '22', departamento: 'NARIÑO', municipio: 'ALDANA', subregion: 'N/A'},
        {id: '772', cod_dept: '22', departamento: 'NARIÑO', municipio: 'ANCUYÁ', subregion: 'N/A'},
        {id: '773', cod_dept: '22', departamento: 'NARIÑO', municipio: 'ARBOLEDA', subregion: 'N/A'},
        {id: '774', cod_dept: '22', departamento: 'NARIÑO', municipio: 'BARBACOAS', subregion: 'N/A'},
        {id: '775', cod_dept: '22', departamento: 'NARIÑO', municipio: 'BELÉN', subregion: 'N/A'},
        {id: '776', cod_dept: '22', departamento: 'NARIÑO', municipio: 'BUESACO', subregion: 'N/A'},
        {id: '777', cod_dept: '22', departamento: 'NARIÑO', municipio: 'COLÓN', subregion: 'N/A'},
        {id: '778', cod_dept: '22', departamento: 'NARIÑO', municipio: 'CONSACÁ', subregion: 'N/A'},
        {id: '779', cod_dept: '22', departamento: 'NARIÑO', municipio: 'CONTADERO', subregion: 'N/A'},
        {id: '780', cod_dept: '22', departamento: 'NARIÑO', municipio: 'CÓRDOBA', subregion: 'N/A'},
        {id: '781', cod_dept: '22', departamento: 'NARIÑO', municipio: 'CUASPÚD', subregion: 'N/A'},
        {id: '782', cod_dept: '22', departamento: 'NARIÑO', municipio: 'CUMBAL', subregion: 'N/A'},
        {id: '783', cod_dept: '22', departamento: 'NARIÑO', municipio: 'CUMBITARA', subregion: 'N/A'},
        {id: '784', cod_dept: '22', departamento: 'NARIÑO', municipio: 'CHACHAGÜÍ', subregion: 'N/A'},
        {id: '785', cod_dept: '22', departamento: 'NARIÑO', municipio: 'EL CHARCO', subregion: 'N/A'},
        {id: '786', cod_dept: '22', departamento: 'NARIÑO', municipio: 'EL PEÑOL', subregion: 'N/A'},
        {id: '787', cod_dept: '22', departamento: 'NARIÑO', municipio: 'EL ROSARIO', subregion: 'N/A'},
        {id: '788', cod_dept: '22', departamento: 'NARIÑO', municipio: 'EL TABLÓN DE GÓMEZ', subregion: 'N/A'},
        {id: '789', cod_dept: '22', departamento: 'NARIÑO', municipio: 'EL TAMBO', subregion: 'N/A'},
        {id: '790', cod_dept: '22', departamento: 'NARIÑO', municipio: 'FUNES', subregion: 'N/A'},
        {id: '791', cod_dept: '22', departamento: 'NARIÑO', municipio: 'GUACHUCAL', subregion: 'N/A'},
        {id: '792', cod_dept: '22', departamento: 'NARIÑO', municipio: 'GUAITARILLA', subregion: 'N/A'},
        {id: '793', cod_dept: '22', departamento: 'NARIÑO', municipio: 'GUALMATÁN', subregion: 'N/A'},
        {id: '794', cod_dept: '22', departamento: 'NARIÑO', municipio: 'ILES', subregion: 'N/A'},
        {id: '795', cod_dept: '22', departamento: 'NARIÑO', municipio: 'IMUÉS', subregion: 'N/A'},
        {id: '796', cod_dept: '22', departamento: 'NARIÑO', municipio: 'IPIALES', subregion: 'N/A'},
        {id: '797', cod_dept: '22', departamento: 'NARIÑO', municipio: 'LA CRUZ', subregion: 'N/A'},
        {id: '798', cod_dept: '22', departamento: 'NARIÑO', municipio: 'LA FLORIDA', subregion: 'N/A'},
        {id: '799', cod_dept: '22', departamento: 'NARIÑO', municipio: 'LA LLANADA', subregion: 'N/A'},
        {id: '800', cod_dept: '22', departamento: 'NARIÑO', municipio: 'LA TOLA', subregion: 'N/A'},
        {id: '801', cod_dept: '22', departamento: 'NARIÑO', municipio: 'LA UNIÓN', subregion: 'N/A'},
        {id: '802', cod_dept: '22', departamento: 'NARIÑO', municipio: 'LEIVA', subregion: 'N/A'},
        {id: '803', cod_dept: '22', departamento: 'NARIÑO', municipio: 'LINARES', subregion: 'N/A'},
        {id: '804', cod_dept: '22', departamento: 'NARIÑO', municipio: 'LOS ANDES', subregion: 'N/A'},
        {id: '805', cod_dept: '22', departamento: 'NARIÑO', municipio: 'MAGÜÍ', subregion: 'N/A'},
        {id: '806', cod_dept: '22', departamento: 'NARIÑO', municipio: 'MALLAMA', subregion: 'N/A'},
        {id: '807', cod_dept: '22', departamento: 'NARIÑO', municipio: 'MOSQUERA', subregion: 'N/A'},
        {id: '808', cod_dept: '22', departamento: 'NARIÑO', municipio: 'NARIÑO', subregion: 'N/A'},
        {id: '809', cod_dept: '22', departamento: 'NARIÑO', municipio: 'OLAYA HERRERA', subregion: 'N/A'},
        {id: '810', cod_dept: '22', departamento: 'NARIÑO', municipio: 'OSPINA', subregion: 'N/A'},
        {id: '811', cod_dept: '22', departamento: 'NARIÑO', municipio: 'FRANCISCO PIZARRO', subregion: 'N/A'},
        {id: '812', cod_dept: '22', departamento: 'NARIÑO', municipio: 'POLICARPA', subregion: 'N/A'},
        {id: '813', cod_dept: '22', departamento: 'NARIÑO', municipio: 'POTOSÍ', subregion: 'N/A'},
        {id: '814', cod_dept: '22', departamento: 'NARIÑO', municipio: 'PROVIDENCIA', subregion: 'N/A'},
        {id: '815', cod_dept: '22', departamento: 'NARIÑO', municipio: 'PUERRES', subregion: 'N/A'},
        {id: '816', cod_dept: '22', departamento: 'NARIÑO', municipio: 'PUPIALES', subregion: 'N/A'},
        {id: '817', cod_dept: '22', departamento: 'NARIÑO', municipio: 'RICAURTE', subregion: 'N/A'},
        {id: '818', cod_dept: '22', departamento: 'NARIÑO', municipio: 'ROBERTO PAYÁN', subregion: 'N/A'},
        {id: '819', cod_dept: '22', departamento: 'NARIÑO', municipio: 'SAMANIEGO', subregion: 'N/A'},
        {id: '820', cod_dept: '22', departamento: 'NARIÑO', municipio: 'SANDONÁ', subregion: 'N/A'},
        {id: '821', cod_dept: '22', departamento: 'NARIÑO', municipio: 'SAN BERNARDO', subregion: 'N/A'},
        {id: '822', cod_dept: '22', departamento: 'NARIÑO', municipio: 'SAN LORENZO', subregion: 'N/A'},
        {id: '823', cod_dept: '22', departamento: 'NARIÑO', municipio: 'SAN PABLO', subregion: 'N/A'},
        {id: '824', cod_dept: '22', departamento: 'NARIÑO', municipio: 'SAN PEDRO DE CARTAGO', subregion: 'N/A'},
        {id: '825', cod_dept: '22', departamento: 'NARIÑO', municipio: 'SANTA BÁRBARA', subregion: 'N/A'},
        {id: '826', cod_dept: '22', departamento: 'NARIÑO', municipio: 'SANTACRUZ', subregion: 'N/A'},
        {id: '827', cod_dept: '22', departamento: 'NARIÑO', municipio: 'SAPUYES', subregion: 'N/A'},
        {id: '828', cod_dept: '22', departamento: 'NARIÑO', municipio: 'TAMINANGO', subregion: 'N/A'},
        {id: '829', cod_dept: '22', departamento: 'NARIÑO', municipio: 'TANGUA', subregion: 'N/A'},
        {id: '830', cod_dept: '22', departamento: 'NARIÑO', municipio: 'SAN ANDRÉS DE TUMACO', subregion: 'N/A'},
        {id: '831', cod_dept: '22', departamento: 'NARIÑO', municipio: 'TÚQUERRES', subregion: 'N/A'},
        {id: '832', cod_dept: '22', departamento: 'NARIÑO', municipio: 'YACUANQUER', subregion: 'N/A'},
        {id: '833', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'CÚCUTA', subregion: 'N/A'},
        {id: '834', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'ÁBREGO', subregion: 'N/A'},
        {id: '835', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'ARBOLEDAS', subregion: 'N/A'},
        {id: '836', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'BOCHALEMA', subregion: 'N/A'},
        {id: '837', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'BUCARASICA', subregion: 'N/A'},
        {id: '838', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'CÁCOTA', subregion: 'N/A'},
        {id: '839', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'CÁCHIRA', subregion: 'N/A'},
        {id: '840', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'CHINÁCOTA', subregion: 'N/A'},
        {id: '841', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'CHITAGÁ', subregion: 'N/A'},
        {id: '842', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'CONVENCIÓN', subregion: 'N/A'},
        {id: '843', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'CUCUTILLA', subregion: 'N/A'},
        {id: '844', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'DURANIA', subregion: 'N/A'},
        {id: '845', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'EL CARMEN', subregion: 'N/A'},
        {id: '846', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'EL TARRA', subregion: 'N/A'},
        {id: '847', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'EL ZULIA', subregion: 'N/A'},
        {id: '848', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'GRAMALOTE', subregion: 'N/A'},
        {id: '849', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'HACARÍ', subregion: 'N/A'},
        {id: '850', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'HERRÁN', subregion: 'N/A'},
        {id: '851', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'LABATECA', subregion: 'N/A'},
        {id: '852', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'LA ESPERANZA', subregion: 'N/A'},
        {id: '853', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'LA PLAYA', subregion: 'N/A'},
        {id: '854', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'LOS PATIOS', subregion: 'N/A'},
        {id: '855', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'LOURDES', subregion: 'N/A'},
        {id: '856', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'MUTISCUA', subregion: 'N/A'},
        {id: '857', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'OCAÑA', subregion: 'N/A'},
        {id: '858', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'PAMPLONA', subregion: 'N/A'},
        {id: '859', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'PAMPLONITA', subregion: 'N/A'},
        {id: '860', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'PUERTO SANTANDER', subregion: 'N/A'},
        {id: '861', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'RAGONVALIA', subregion: 'N/A'},
        {id: '862', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'SALAZAR', subregion: 'N/A'},
        {id: '863', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'SAN CALIXTO', subregion: 'N/A'},
        {id: '864', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'SAN CAYETANO', subregion: 'N/A'},
        {id: '865', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'SANTIAGO', subregion: 'N/A'},
        {id: '866', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'SARDINATA', subregion: 'N/A'},
        {id: '867', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'SILOS', subregion: 'N/A'},
        {id: '868', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'TEORAMA', subregion: 'N/A'},
        {id: '869', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'TIBÚ', subregion: 'N/A'},
        {id: '870', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'TOLEDO', subregion: 'N/A'},
        {id: '871', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'VILLA CARO', subregion: 'N/A'},
        {id: '872', cod_dept: '23', departamento: 'NORTE DE SANTANDER', municipio: 'VILLA DEL ROSARIO', subregion: 'N/A'},
        {id: '873', cod_dept: '24', departamento: 'PUTUMAYO', municipio: 'MOCOA', subregion: 'N/A'},
        {id: '874', cod_dept: '24', departamento: 'PUTUMAYO', municipio: 'COLÓN', subregion: 'N/A'},
        {id: '875', cod_dept: '24', departamento: 'PUTUMAYO', municipio: 'ORITO', subregion: 'N/A'},
        {id: '876', cod_dept: '24', departamento: 'PUTUMAYO', municipio: 'PUERTO ASÍS', subregion: 'N/A'},
        {id: '877', cod_dept: '24', departamento: 'PUTUMAYO', municipio: 'PUERTO CAICEDO', subregion: 'N/A'},
        {id: '878', cod_dept: '24', departamento: 'PUTUMAYO', municipio: 'PUERTO GUZMÁN', subregion: 'N/A'},
        {id: '879', cod_dept: '24', departamento: 'PUTUMAYO', municipio: 'PUERTO LEGUÍZAMO', subregion: 'N/A'},
        {id: '880', cod_dept: '24', departamento: 'PUTUMAYO', municipio: 'SIBUNDOY', subregion: 'N/A'},
        {id: '881', cod_dept: '24', departamento: 'PUTUMAYO', municipio: 'SAN FRANCISCO', subregion: 'N/A'},
        {id: '882', cod_dept: '24', departamento: 'PUTUMAYO', municipio: 'SAN MIGUEL', subregion: 'N/A'},
        {id: '883', cod_dept: '24', departamento: 'PUTUMAYO', municipio: 'SANTIAGO', subregion: 'N/A'},
        {id: '884', cod_dept: '24', departamento: 'PUTUMAYO', municipio: 'VALLE DEL GUAMUEZ', subregion: 'N/A'},
        {id: '885', cod_dept: '24', departamento: 'PUTUMAYO', municipio: 'VILLAGARZÓN', subregion: 'N/A'},
        {id: '886', cod_dept: '25', departamento: 'QUINDIO', municipio: 'ARMENIA', subregion: 'N/A'},
        {id: '887', cod_dept: '25', departamento: 'QUINDIO', municipio: 'BUENAVISTA', subregion: 'N/A'},
        {id: '888', cod_dept: '25', departamento: 'QUINDIO', municipio: 'CALARCÁ', subregion: 'N/A'},
        {id: '889', cod_dept: '25', departamento: 'QUINDIO', municipio: 'CIRCASIA', subregion: 'N/A'},
        {id: '890', cod_dept: '25', departamento: 'QUINDIO', municipio: 'CÓRDOBA', subregion: 'N/A'},
        {id: '891', cod_dept: '25', departamento: 'QUINDIO', municipio: 'FILANDIA', subregion: 'N/A'},
        {id: '892', cod_dept: '25', departamento: 'QUINDIO', municipio: 'GÉNOVA', subregion: 'N/A'},
        {id: '893', cod_dept: '25', departamento: 'QUINDIO', municipio: 'LA TEBAIDA', subregion: 'N/A'},
        {id: '894', cod_dept: '25', departamento: 'QUINDIO', municipio: 'MONTENEGRO', subregion: 'N/A'},
        {id: '895', cod_dept: '25', departamento: 'QUINDIO', municipio: 'PIJAO', subregion: 'N/A'},
        {id: '896', cod_dept: '25', departamento: 'QUINDIO', municipio: 'QUIMBAYA', subregion: 'N/A'},
        {id: '897', cod_dept: '25', departamento: 'QUINDIO', municipio: 'SALENTO', subregion: 'N/A'},
        {id: '898', cod_dept: '26', departamento: 'RISARALDA', municipio: 'PEREIRA', subregion: 'N/A'},
        {id: '899', cod_dept: '26', departamento: 'RISARALDA', municipio: 'APÍA', subregion: 'N/A'},
        {id: '900', cod_dept: '26', departamento: 'RISARALDA', municipio: 'BALBOA', subregion: 'N/A'},
        {id: '901', cod_dept: '26', departamento: 'RISARALDA', municipio: 'BELÉN DE UMBRÍA', subregion: 'N/A'},
        {id: '902', cod_dept: '26', departamento: 'RISARALDA', municipio: 'DOSQUEBRADAS', subregion: 'N/A'},
        {id: '903', cod_dept: '26', departamento: 'RISARALDA', municipio: 'GUÁTICA', subregion: 'N/A'},
        {id: '904', cod_dept: '26', departamento: 'RISARALDA', municipio: 'LA CELIA', subregion: 'N/A'},
        {id: '905', cod_dept: '26', departamento: 'RISARALDA', municipio: 'LA VIRGINIA', subregion: 'N/A'},
        {id: '906', cod_dept: '26', departamento: 'RISARALDA', municipio: 'MARSELLA', subregion: 'N/A'},
        {id: '907', cod_dept: '26', departamento: 'RISARALDA', municipio: 'MISTRATÓ', subregion: 'N/A'},
        {id: '908', cod_dept: '26', departamento: 'RISARALDA', municipio: 'PUEBLO RICO', subregion: 'N/A'},
        {id: '909', cod_dept: '26', departamento: 'RISARALDA', municipio: 'QUINCHÍA', subregion: 'N/A'},
        {id: '910', cod_dept: '26', departamento: 'RISARALDA', municipio: 'SANTA ROSA DE CABAL', subregion: 'N/A'},
        {id: '911', cod_dept: '26', departamento: 'RISARALDA', municipio: 'SANTUARIO', subregion: 'N/A'},
        {id: '912', cod_dept: '27', departamento: 'SANTANDER', municipio: 'BUCARAMANGA', subregion: 'N/A'},
        {id: '913', cod_dept: '27', departamento: 'SANTANDER', municipio: 'AGUADA', subregion: 'N/A'},
        {id: '914', cod_dept: '27', departamento: 'SANTANDER', municipio: 'ALBANIA', subregion: 'N/A'},
        {id: '915', cod_dept: '27', departamento: 'SANTANDER', municipio: 'ARATOCA', subregion: 'N/A'},
        {id: '916', cod_dept: '27', departamento: 'SANTANDER', municipio: 'BARBOSA', subregion: 'N/A'},
        {id: '917', cod_dept: '27', departamento: 'SANTANDER', municipio: 'BARICHARA', subregion: 'N/A'},
        {id: '918', cod_dept: '27', departamento: 'SANTANDER', municipio: 'BARRANCABERMEJA', subregion: 'N/A'},
        {id: '919', cod_dept: '27', departamento: 'SANTANDER', municipio: 'BETULIA', subregion: 'N/A'},
        {id: '920', cod_dept: '27', departamento: 'SANTANDER', municipio: 'BOLÍVAR', subregion: 'N/A'},
        {id: '921', cod_dept: '27', departamento: 'SANTANDER', municipio: 'CABRERA', subregion: 'N/A'},
        {id: '922', cod_dept: '27', departamento: 'SANTANDER', municipio: 'CALIFORNIA', subregion: 'N/A'},
        {id: '923', cod_dept: '27', departamento: 'SANTANDER', municipio: 'CAPITANEJO', subregion: 'N/A'},
        {id: '924', cod_dept: '27', departamento: 'SANTANDER', municipio: 'CARCASÍ', subregion: 'N/A'},
        {id: '925', cod_dept: '27', departamento: 'SANTANDER', municipio: 'CEPITÁ', subregion: 'N/A'},
        {id: '926', cod_dept: '27', departamento: 'SANTANDER', municipio: 'CERRITO', subregion: 'N/A'},
        {id: '927', cod_dept: '27', departamento: 'SANTANDER', municipio: 'CHARALÁ', subregion: 'N/A'},
        {id: '928', cod_dept: '27', departamento: 'SANTANDER', municipio: 'CHARTA', subregion: 'N/A'},
        {id: '929', cod_dept: '27', departamento: 'SANTANDER', municipio: 'CHIMA', subregion: 'N/A'},
        {id: '930', cod_dept: '27', departamento: 'SANTANDER', municipio: 'CHIPATÁ', subregion: 'N/A'},
        {id: '931', cod_dept: '27', departamento: 'SANTANDER', municipio: 'CIMITARRA', subregion: 'N/A'},
        {id: '932', cod_dept: '27', departamento: 'SANTANDER', municipio: 'CONCEPCIÓN', subregion: 'N/A'},
        {id: '933', cod_dept: '27', departamento: 'SANTANDER', municipio: 'CONFINES', subregion: 'N/A'},
        {id: '934', cod_dept: '27', departamento: 'SANTANDER', municipio: 'CONTRATACIÓN', subregion: 'N/A'},
        {id: '935', cod_dept: '27', departamento: 'SANTANDER', municipio: 'COROMORO', subregion: 'N/A'},
        {id: '936', cod_dept: '27', departamento: 'SANTANDER', municipio: 'CURITÍ', subregion: 'N/A'},
        {id: '937', cod_dept: '27', departamento: 'SANTANDER', municipio: 'EL CARMEN DE CHUCURÍ', subregion: 'N/A'},
        {id: '938', cod_dept: '27', departamento: 'SANTANDER', municipio: 'EL GUACAMAYO', subregion: 'N/A'},
        {id: '939', cod_dept: '27', departamento: 'SANTANDER', municipio: 'EL PEÑÓN', subregion: 'N/A'},
        {id: '940', cod_dept: '27', departamento: 'SANTANDER', municipio: 'EL PLAYÓN', subregion: 'N/A'},
        {id: '941', cod_dept: '27', departamento: 'SANTANDER', municipio: 'ENCINO', subregion: 'N/A'},
        {id: '942', cod_dept: '27', departamento: 'SANTANDER', municipio: 'ENCISO', subregion: 'N/A'},
        {id: '943', cod_dept: '27', departamento: 'SANTANDER', municipio: 'FLORIÁN', subregion: 'N/A'},
        {id: '944', cod_dept: '27', departamento: 'SANTANDER', municipio: 'FLORIDABLANCA', subregion: 'N/A'},
        {id: '945', cod_dept: '27', departamento: 'SANTANDER', municipio: 'GALÁN', subregion: 'N/A'},
        {id: '946', cod_dept: '27', departamento: 'SANTANDER', municipio: 'GÁMBITA', subregion: 'N/A'},
        {id: '947', cod_dept: '27', departamento: 'SANTANDER', municipio: 'GIRÓN', subregion: 'N/A'},
        {id: '948', cod_dept: '27', departamento: 'SANTANDER', municipio: 'GUACA', subregion: 'N/A'},
        {id: '949', cod_dept: '27', departamento: 'SANTANDER', municipio: 'GUADALUPE', subregion: 'N/A'},
        {id: '950', cod_dept: '27', departamento: 'SANTANDER', municipio: 'GUAPOTÁ', subregion: 'N/A'},
        {id: '951', cod_dept: '27', departamento: 'SANTANDER', municipio: 'GUAVATÁ', subregion: 'N/A'},
        {id: '952', cod_dept: '27', departamento: 'SANTANDER', municipio: 'GÜEPSA', subregion: 'N/A'},
        {id: '953', cod_dept: '27', departamento: 'SANTANDER', municipio: 'HATO', subregion: 'N/A'},
        {id: '954', cod_dept: '27', departamento: 'SANTANDER', municipio: 'JESÚS MARÍA', subregion: 'N/A'},
        {id: '955', cod_dept: '27', departamento: 'SANTANDER', municipio: 'JORDÁN', subregion: 'N/A'},
        {id: '956', cod_dept: '27', departamento: 'SANTANDER', municipio: 'LA BELLEZA', subregion: 'N/A'},
        {id: '957', cod_dept: '27', departamento: 'SANTANDER', municipio: 'LANDÁZURI', subregion: 'N/A'},
        {id: '958', cod_dept: '27', departamento: 'SANTANDER', municipio: 'LA PAZ', subregion: 'N/A'},
        {id: '959', cod_dept: '27', departamento: 'SANTANDER', municipio: 'LEBRIJA', subregion: 'N/A'},
        {id: '960', cod_dept: '27', departamento: 'SANTANDER', municipio: 'LOS SANTOS', subregion: 'N/A'},
        {id: '961', cod_dept: '27', departamento: 'SANTANDER', municipio: 'MACARAVITA', subregion: 'N/A'},
        {id: '962', cod_dept: '27', departamento: 'SANTANDER', municipio: 'MÁLAGA', subregion: 'N/A'},
        {id: '963', cod_dept: '27', departamento: 'SANTANDER', municipio: 'MATANZA', subregion: 'N/A'},
        {id: '964', cod_dept: '27', departamento: 'SANTANDER', municipio: 'MOGOTES', subregion: 'N/A'},
        {id: '965', cod_dept: '27', departamento: 'SANTANDER', municipio: 'MOLAGAVITA', subregion: 'N/A'},
        {id: '966', cod_dept: '27', departamento: 'SANTANDER', municipio: 'OCAMONTE', subregion: 'N/A'},
        {id: '967', cod_dept: '27', departamento: 'SANTANDER', municipio: 'OIBA', subregion: 'N/A'},
        {id: '968', cod_dept: '27', departamento: 'SANTANDER', municipio: 'ONZAGA', subregion: 'N/A'},
        {id: '969', cod_dept: '27', departamento: 'SANTANDER', municipio: 'PALMAR', subregion: 'N/A'},
        {id: '970', cod_dept: '27', departamento: 'SANTANDER', municipio: 'PALMAS DEL SOCORRO', subregion: 'N/A'},
        {id: '971', cod_dept: '27', departamento: 'SANTANDER', municipio: 'PÁRAMO', subregion: 'N/A'},
        {id: '972', cod_dept: '27', departamento: 'SANTANDER', municipio: 'PIEDECUESTA', subregion: 'N/A'},
        {id: '973', cod_dept: '27', departamento: 'SANTANDER', municipio: 'PINCHOTE', subregion: 'N/A'},
        {id: '974', cod_dept: '27', departamento: 'SANTANDER', municipio: 'PUENTE NACIONAL', subregion: 'N/A'},
        {id: '975', cod_dept: '27', departamento: 'SANTANDER', municipio: 'PUERTO PARRA', subregion: 'N/A'},
        {id: '976', cod_dept: '27', departamento: 'SANTANDER', municipio: 'PUERTO WILCHES', subregion: 'N/A'},
        {id: '977', cod_dept: '27', departamento: 'SANTANDER', municipio: 'RIONEGRO', subregion: 'N/A'},
        {id: '978', cod_dept: '27', departamento: 'SANTANDER', municipio: 'SABANA DE TORRES', subregion: 'N/A'},
        {id: '979', cod_dept: '27', departamento: 'SANTANDER', municipio: 'SAN ANDRÉS', subregion: 'N/A'},
        {id: '980', cod_dept: '27', departamento: 'SANTANDER', municipio: 'SAN BENITO', subregion: 'N/A'},
        {id: '981', cod_dept: '27', departamento: 'SANTANDER', municipio: 'SAN GIL', subregion: 'N/A'},
        {id: '982', cod_dept: '27', departamento: 'SANTANDER', municipio: 'SAN JOAQUÍN', subregion: 'N/A'},
        {id: '983', cod_dept: '27', departamento: 'SANTANDER', municipio: 'SAN JOSÉ DE MIRANDA', subregion: 'N/A'},
        {id: '984', cod_dept: '27', departamento: 'SANTANDER', municipio: 'SAN MIGUEL', subregion: 'N/A'},
        {id: '985', cod_dept: '27', departamento: 'SANTANDER', municipio: 'SAN VICENTE DE CHUCURÍ', subregion: 'N/A'},
        {id: '986', cod_dept: '27', departamento: 'SANTANDER', municipio: 'SANTA BÁRBARA', subregion: 'N/A'},
        {id: '987', cod_dept: '27', departamento: 'SANTANDER', municipio: 'SANTA HELENA DEL OPÓN', subregion: 'N/A'},
        {id: '988', cod_dept: '27', departamento: 'SANTANDER', municipio: 'SIMACOTA', subregion: 'N/A'},
        {id: '989', cod_dept: '27', departamento: 'SANTANDER', municipio: 'SOCORRO', subregion: 'N/A'},
        {id: '990', cod_dept: '27', departamento: 'SANTANDER', municipio: 'SUAITA', subregion: 'N/A'},
        {id: '991', cod_dept: '27', departamento: 'SANTANDER', municipio: 'SUCRE', subregion: 'N/A'},
        {id: '992', cod_dept: '27', departamento: 'SANTANDER', municipio: 'SURATÁ', subregion: 'N/A'},
        {id: '993', cod_dept: '27', departamento: 'SANTANDER', municipio: 'TONA', subregion: 'N/A'},
        {id: '994', cod_dept: '27', departamento: 'SANTANDER', municipio: 'VALLE DE SAN JOSÉ', subregion: 'N/A'},
        {id: '995', cod_dept: '27', departamento: 'SANTANDER', municipio: 'VÉLEZ', subregion: 'N/A'},
        {id: '996', cod_dept: '27', departamento: 'SANTANDER', municipio: 'VETAS', subregion: 'N/A'},
        {id: '997', cod_dept: '27', departamento: 'SANTANDER', municipio: 'VILLANUEVA', subregion: 'N/A'},
        {id: '998', cod_dept: '27', departamento: 'SANTANDER', municipio: 'ZAPATOCA', subregion: 'N/A'},
        {id: '999', cod_dept: '28', departamento: 'SUCRE', municipio: 'SINCELEJO', subregion: 'N/A'},
        {id: '1000', cod_dept: '28', departamento: 'SUCRE', municipio: 'BUENAVISTA', subregion: 'N/A'},
        {id: '1001', cod_dept: '28', departamento: 'SUCRE', municipio: 'CAIMITO', subregion: 'N/A'},
        {id: '1002', cod_dept: '28', departamento: 'SUCRE', municipio: 'COLOSO', subregion: 'N/A'},
        {id: '1003', cod_dept: '28', departamento: 'SUCRE', municipio: 'COROZAL', subregion: 'N/A'},
        {id: '1004', cod_dept: '28', departamento: 'SUCRE', municipio: 'COVEÑAS', subregion: 'N/A'},
        {id: '1005', cod_dept: '28', departamento: 'SUCRE', municipio: 'CHALÁN', subregion: 'N/A'},
        {id: '1006', cod_dept: '28', departamento: 'SUCRE', municipio: 'EL ROBLE', subregion: 'N/A'},
        {id: '1007', cod_dept: '28', departamento: 'SUCRE', municipio: 'GALERAS', subregion: 'N/A'},
        {id: '1008', cod_dept: '28', departamento: 'SUCRE', municipio: 'GUARANDA', subregion: 'N/A'},
        {id: '1009', cod_dept: '28', departamento: 'SUCRE', municipio: 'LA UNIÓN', subregion: 'N/A'},
        {id: '1010', cod_dept: '28', departamento: 'SUCRE', municipio: 'LOS PALMITOS', subregion: 'N/A'},
        {id: '1011', cod_dept: '28', departamento: 'SUCRE', municipio: 'MAJAGUAL', subregion: 'N/A'},
        {id: '1012', cod_dept: '28', departamento: 'SUCRE', municipio: 'MORROA', subregion: 'N/A'},
        {id: '1013', cod_dept: '28', departamento: 'SUCRE', municipio: 'OVEJAS', subregion: 'N/A'},
        {id: '1014', cod_dept: '28', departamento: 'SUCRE', municipio: 'PALMITO', subregion: 'N/A'},
        {id: '1015', cod_dept: '28', departamento: 'SUCRE', municipio: 'SAMPUÉS', subregion: 'N/A'},
        {id: '1016', cod_dept: '28', departamento: 'SUCRE', municipio: 'SAN BENITO ABAD', subregion: 'N/A'},
        {id: '1017', cod_dept: '28', departamento: 'SUCRE', municipio: 'SAN JUAN DE BETULIA', subregion: 'N/A'},
        {id: '1018', cod_dept: '28', departamento: 'SUCRE', municipio: 'SAN MARCOS', subregion: 'N/A'},
        {id: '1019', cod_dept: '28', departamento: 'SUCRE', municipio: 'SAN ONOFRE', subregion: 'N/A'},
        {id: '1020', cod_dept: '28', departamento: 'SUCRE', municipio: 'SAN PEDRO', subregion: 'N/A'},
        {id: '1021', cod_dept: '28', departamento: 'SUCRE', municipio: 'SAN LUIS DE SINCÉ', subregion: 'N/A'},
        {id: '1022', cod_dept: '28', departamento: 'SUCRE', municipio: 'SUCRE', subregion: 'N/A'},
        {id: '1023', cod_dept: '28', departamento: 'SUCRE', municipio: 'SANTIAGO DE TOLÚ', subregion: 'N/A'},
        {id: '1024', cod_dept: '28', departamento: 'SUCRE', municipio: 'TOLÚ VIEJO', subregion: 'N/A'},
        {id: '1025', cod_dept: '29', departamento: 'TOLIMA', municipio: 'IBAGUÉ', subregion: 'N/A'},
        {id: '1026', cod_dept: '29', departamento: 'TOLIMA', municipio: 'ALPUJARRA', subregion: 'N/A'},
        {id: '1027', cod_dept: '29', departamento: 'TOLIMA', municipio: 'ALVARADO', subregion: 'N/A'},
        {id: '1028', cod_dept: '29', departamento: 'TOLIMA', municipio: 'AMBALEMA', subregion: 'N/A'},
        {id: '1029', cod_dept: '29', departamento: 'TOLIMA', municipio: 'ANZOÁTEGUI', subregion: 'N/A'},
        {id: '1030', cod_dept: '29', departamento: 'TOLIMA', municipio: 'ARMERO GUAYABAL', subregion: 'N/A'},
        {id: '1031', cod_dept: '29', departamento: 'TOLIMA', municipio: 'ATACO', subregion: 'N/A'},
        {id: '1032', cod_dept: '29', departamento: 'TOLIMA', municipio: 'CAJAMARCA', subregion: 'N/A'},
        {id: '1033', cod_dept: '29', departamento: 'TOLIMA', municipio: 'CARMEN DE APICALÁ', subregion: 'N/A'},
        {id: '1034', cod_dept: '29', departamento: 'TOLIMA', municipio: 'CASABIANCA', subregion: 'N/A'},
        {id: '1035', cod_dept: '29', departamento: 'TOLIMA', municipio: 'CHAPARRAL', subregion: 'N/A'},
        {id: '1036', cod_dept: '29', departamento: 'TOLIMA', municipio: 'COELLO', subregion: 'N/A'},
        {id: '1037', cod_dept: '29', departamento: 'TOLIMA', municipio: 'COYAIMA', subregion: 'N/A'},
        {id: '1038', cod_dept: '29', departamento: 'TOLIMA', municipio: 'CUNDAY', subregion: 'N/A'},
        {id: '1039', cod_dept: '29', departamento: 'TOLIMA', municipio: 'DOLORES', subregion: 'N/A'},
        {id: '1040', cod_dept: '29', departamento: 'TOLIMA', municipio: 'ESPINAL', subregion: 'N/A'},
        {id: '1041', cod_dept: '29', departamento: 'TOLIMA', municipio: 'FALAN', subregion: 'N/A'},
        {id: '1042', cod_dept: '29', departamento: 'TOLIMA', municipio: 'FLANDES', subregion: 'N/A'},
        {id: '1043', cod_dept: '29', departamento: 'TOLIMA', municipio: 'FRESNO', subregion: 'N/A'},
        {id: '1044', cod_dept: '29', departamento: 'TOLIMA', municipio: 'GUAMO', subregion: 'N/A'},
        {id: '1045', cod_dept: '29', departamento: 'TOLIMA', municipio: 'HERVEO', subregion: 'N/A'},
        {id: '1046', cod_dept: '29', departamento: 'TOLIMA', municipio: 'HONDA', subregion: 'N/A'},
        {id: '1047', cod_dept: '29', departamento: 'TOLIMA', municipio: 'ICONONZO', subregion: 'N/A'},
        {id: '1048', cod_dept: '29', departamento: 'TOLIMA', municipio: 'LÉRIDA', subregion: 'N/A'},
        {id: '1049', cod_dept: '29', departamento: 'TOLIMA', municipio: 'LÍBANO', subregion: 'N/A'},
        {id: '1050', cod_dept: '29', departamento: 'TOLIMA', municipio: 'SAN SEBASTIÁN DE MARIQUITA', subregion: 'N/A'},
        {id: '1051', cod_dept: '29', departamento: 'TOLIMA', municipio: 'MELGAR', subregion: 'N/A'},
        {id: '1052', cod_dept: '29', departamento: 'TOLIMA', municipio: 'MURILLO', subregion: 'N/A'},
        {id: '1053', cod_dept: '29', departamento: 'TOLIMA', municipio: 'NATAGAIMA', subregion: 'N/A'},
        {id: '1054', cod_dept: '29', departamento: 'TOLIMA', municipio: 'ORTEGA', subregion: 'N/A'},
        {id: '1055', cod_dept: '29', departamento: 'TOLIMA', municipio: 'PALOCABILDO', subregion: 'N/A'},
        {id: '1056', cod_dept: '29', departamento: 'TOLIMA', municipio: 'PIEDRAS', subregion: 'N/A'},
        {id: '1057', cod_dept: '29', departamento: 'TOLIMA', municipio: 'PLANADAS', subregion: 'N/A'},
        {id: '1058', cod_dept: '29', departamento: 'TOLIMA', municipio: 'PRADO', subregion: 'N/A'},
        {id: '1059', cod_dept: '29', departamento: 'TOLIMA', municipio: 'PURIFICACIÓN', subregion: 'N/A'},
        {id: '1060', cod_dept: '29', departamento: 'TOLIMA', municipio: 'RIOBLANCO', subregion: 'N/A'},
        {id: '1061', cod_dept: '29', departamento: 'TOLIMA', municipio: 'RONCESVALLES', subregion: 'N/A'},
        {id: '1062', cod_dept: '29', departamento: 'TOLIMA', municipio: 'ROVIRA', subregion: 'N/A'},
        {id: '1063', cod_dept: '29', departamento: 'TOLIMA', municipio: 'SALDAÑA', subregion: 'N/A'},
        {id: '1064', cod_dept: '29', departamento: 'TOLIMA', municipio: 'SAN ANTONIO', subregion: 'N/A'},
        {id: '1065', cod_dept: '29', departamento: 'TOLIMA', municipio: 'SAN LUIS', subregion: 'N/A'},
        {id: '1066', cod_dept: '29', departamento: 'TOLIMA', municipio: 'SANTA ISABEL', subregion: 'N/A'},
        {id: '1067', cod_dept: '29', departamento: 'TOLIMA', municipio: 'SUÁREZ', subregion: 'N/A'},
        {id: '1068', cod_dept: '29', departamento: 'TOLIMA', municipio: 'VALLE DE SAN JUAN', subregion: 'N/A'},
        {id: '1069', cod_dept: '29', departamento: 'TOLIMA', municipio: 'VENADILLO', subregion: 'N/A'},
        {id: '1070', cod_dept: '29', departamento: 'TOLIMA', municipio: 'VILLAHERMOSA', subregion: 'N/A'},
        {id: '1071', cod_dept: '29', departamento: 'TOLIMA', municipio: 'VILLARRICA', subregion: 'N/A'},
        {id: '1072', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'CALI', subregion: 'N/A'},
        {id: '1073', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'ALCALÁ', subregion: 'N/A'},
        {id: '1074', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'ANDALUCÍA', subregion: 'N/A'},
        {id: '1075', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'ANSERMANUEVO', subregion: 'N/A'},
        {id: '1076', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'ARGELIA', subregion: 'N/A'},
        {id: '1077', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'BOLÍVAR', subregion: 'N/A'},
        {id: '1078', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'BUENAVENTURA', subregion: 'N/A'},
        {id: '1079', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'GUADALAJARA DE BUGA', subregion: 'N/A'},
        {id: '1080', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'BUGALAGRANDE', subregion: 'N/A'},
        {id: '1081', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'CAICEDONIA', subregion: 'N/A'},
        {id: '1082', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'CALIMA', subregion: 'N/A'},
        {id: '1083', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'CANDELARIA', subregion: 'N/A'},
        {id: '1084', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'CARTAGO', subregion: 'N/A'},
        {id: '1085', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'DAGUA', subregion: 'N/A'},
        {id: '1086', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'EL ÁGUILA', subregion: 'N/A'},
        {id: '1087', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'EL CAIRO', subregion: 'N/A'},
        {id: '1088', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'EL CERRITO', subregion: 'N/A'},
        {id: '1089', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'EL DOVIO', subregion: 'N/A'},
        {id: '1090', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'FLORIDA', subregion: 'N/A'},
        {id: '1091', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'GINEBRA', subregion: 'N/A'},
        {id: '1092', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'GUACARÍ', subregion: 'N/A'},
        {id: '1093', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'JAMUNDÍ', subregion: 'N/A'},
        {id: '1094', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'LA CUMBRE', subregion: 'N/A'},
        {id: '1095', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'LA UNIÓN', subregion: 'N/A'},
        {id: '1096', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'LA VICTORIA', subregion: 'N/A'},
        {id: '1097', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'OBANDO', subregion: 'N/A'},
        {id: '1098', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'PALMIRA', subregion: 'N/A'},
        {id: '1099', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'PRADERA', subregion: 'N/A'},
        {id: '1100', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'RESTREPO', subregion: 'N/A'},
        {id: '1101', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'RIOFRÍO', subregion: 'N/A'},
        {id: '1102', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'ROLDANILLO', subregion: 'N/A'},
        {id: '1103', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'SAN PEDRO', subregion: 'N/A'},
        {id: '1104', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'SEVILLA', subregion: 'N/A'},
        {id: '1105', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'TORO', subregion: 'N/A'},
        {id: '1106', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'TRUJILLO', subregion: 'N/A'},
        {id: '1107', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'TULUÁ', subregion: 'N/A'},
        {id: '1108', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'ULLOA', subregion: 'N/A'},
        {id: '1109', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'VERSALLES', subregion: 'N/A'},
        {id: '1110', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'VIJES', subregion: 'N/A'},
        {id: '1111', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'YOTOCO', subregion: 'N/A'},
        {id: '1112', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'YUMBO', subregion: 'N/A'},
        {id: '1113', cod_dept: '30', departamento: 'VALLE DEL CAUCA', municipio: 'ZARZAL', subregion: 'N/A'},
        {id: '1114', cod_dept: '31', departamento: 'VAUPÉS', municipio: 'MITÚ', subregion: 'N/A'},
        {id: '1115', cod_dept: '31', departamento: 'VAUPÉS', municipio: 'CARURÚ', subregion: 'N/A'},
        {id: '1116', cod_dept: '31', departamento: 'VAUPÉS', municipio: 'PACOA', subregion: 'N/A'},
        {id: '1117', cod_dept: '31', departamento: 'VAUPÉS', municipio: 'TARAIRA', subregion: 'N/A'},
        {id: '1118', cod_dept: '31', departamento: 'VAUPÉS', municipio: 'PAPUNAUA', subregion: 'N/A'},
        {id: '1119', cod_dept: '31', departamento: 'VAUPÉS', municipio: 'YAVARATÉ', subregion: 'N/A'},
        {id: '1120', cod_dept: '32', departamento: 'VICHADA', municipio: 'PUERTO CARREÑO', subregion: 'N/A'},
        {id: '1121', cod_dept: '32', departamento: 'VICHADA', municipio: 'LA PRIMAVERA', subregion: 'N/A'},
        {id: '1122', cod_dept: '32', departamento: 'VICHADA', municipio: 'SANTA ROSALÍA', subregion: 'N/A'},
        {id: '1123', cod_dept: '32', departamento: 'VICHADA', municipio: 'CUMARIBO', subregion: 'N/A'}
    ],
    tipoMovilidad: [
        '0', 'DEPARTAMENTAL', 'INTERMUNICIPAL', 'INTERURBANO'
    ],
    barrio: [
        {id: '0', idcomuna: '0', comuna: '', barrio: ''},
        {id: '1', idcomuna: '1', comuna: 'POPULAR', barrio: 'SANTO DOMINGO SAVIO Nº 1'},
        {id: '2', idcomuna: '1', comuna: 'POPULAR', barrio: 'SANTO DOMINGO SAVIO Nº 2'},
        {id: '3', idcomuna: '1', comuna: 'POPULAR', barrio: 'POPULAR'},
        {id: '4', idcomuna: '1', comuna: 'POPULAR', barrio: 'GRANIZAL'},
        {id: '5', idcomuna: '1', comuna: 'POPULAR', barrio: 'MOSCU Nº 2'},
        {id: '6', idcomuna: '1', comuna: 'POPULAR', barrio: 'VILLA GUADALUPE'},
        {id: '7', idcomuna: '1', comuna: 'POPULAR', barrio: 'SAN PABLO - POPULAR'},
        {id: '8', idcomuna: '1', comuna: 'POPULAR', barrio: 'EL COMPROMISO'},
        {id: '9', idcomuna: '1', comuna: 'POPULAR', barrio: 'ALDEA PABLO VI'},
        {id: '10', idcomuna: '1', comuna: 'POPULAR', barrio: 'LA ESPERANZA Nº 2 - POPULAR'},
        {id: '11', idcomuna: '1', comuna: 'POPULAR', barrio: 'LA AVANZADA'},
        {id: '12', idcomuna: '1', comuna: 'POPULAR', barrio: 'CARPINELO'},
        {id: '13', idcomuna: '2', comuna: 'SANTA CRUZ', barrio: 'LA ISLA'},
        {id: '14', idcomuna: '2', comuna: 'SANTA CRUZ', barrio: 'EL PLAYON DE LOS COMUNEROS'},
        {id: '15', idcomuna: '2', comuna: 'SANTA CRUZ', barrio: 'PABLO VI'},
        {id: '16', idcomuna: '2', comuna: 'SANTA CRUZ', barrio: 'LA FRONTERA'},
        {id: '17', idcomuna: '2', comuna: 'SANTA CRUZ', barrio: 'LA FRANCIA'},
        {id: '18', idcomuna: '2', comuna: 'SANTA CRUZ', barrio: 'ANDALUCIA'},
        {id: '19', idcomuna: '2', comuna: 'SANTA CRUZ', barrio: 'VILLA DEL SOCORRO'},
        {id: '20', idcomuna: '2', comuna: 'SANTA CRUZ', barrio: 'VILLA NIZA'},
        {id: '21', idcomuna: '2', comuna: 'SANTA CRUZ', barrio: 'MOSCU Nº 1'},
        {id: '22', idcomuna: '2', comuna: 'SANTA CRUZ', barrio: 'SANTA CRUZ'},
        {id: '23', idcomuna: '2', comuna: 'SANTA CRUZ', barrio: 'LA ROSA'},
        {id: '24', idcomuna: '3', comuna: 'MANRIQUE', barrio: 'LA SALLE'},
        {id: '25', idcomuna: '3', comuna: 'MANRIQUE', barrio: 'LAS GRANJAS'},
        {id: '26', idcomuna: '3', comuna: 'MANRIQUE', barrio: 'CAMPO VALDES Nº 2'},
        {id: '27', idcomuna: '3', comuna: 'MANRIQUE', barrio: 'SANTA INES'},
        {id: '28', idcomuna: '3', comuna: 'MANRIQUE', barrio: 'EL RAIZAL'},
        {id: '29', idcomuna: '3', comuna: 'MANRIQUE', barrio: 'EL POMAR'},
        {id: '30', idcomuna: '3', comuna: 'MANRIQUE', barrio: 'MANRIQUE CENTRAL Nº 2'},
        {id: '31', idcomuna: '3', comuna: 'MANRIQUE', barrio: 'MANRIQUE ORIENTAL'},
        {id: '32', idcomuna: '3', comuna: 'MANRIQUE', barrio: 'VERSALLES Nº 1'},
        {id: '33', idcomuna: '3', comuna: 'MANRIQUE', barrio: 'VERSALLES Nº 2'},
        {id: '34', idcomuna: '3', comuna: 'MANRIQUE', barrio: 'LA CRUZ'},
        {id: '35', idcomuna: '3', comuna: 'MANRIQUE', barrio: 'ORIENTE'},
        {id: '36', idcomuna: '3', comuna: 'MANRIQUE', barrio: 'MARIA CANO - CARAMBOLAS'},
        {id: '37', idcomuna: '3', comuna: 'MANRIQUE', barrio: 'SAN JOSE LA CIMA Nº 1'},
        {id: '38', idcomuna: '3', comuna: 'MANRIQUE', barrio: 'SAN JOSE LA CIMA Nº 2'},
        {id: '39', idcomuna: '4', comuna: 'ARANJUEZ', barrio: 'BERLIN'},
        {id: '40', idcomuna: '4', comuna: 'ARANJUEZ', barrio: 'SAN ISIDRO'},
        {id: '41', idcomuna: '4', comuna: 'ARANJUEZ', barrio: 'PALERMO'},
        {id: '42', idcomuna: '4', comuna: 'ARANJUEZ', barrio: 'BERMEJAL - LOS ALAMOS'},
        {id: '43', idcomuna: '4', comuna: 'ARANJUEZ', barrio: 'MORAVIA'},
        {id: '44', idcomuna: '4', comuna: 'ARANJUEZ', barrio: 'SEVILLA'},
        {id: '45', idcomuna: '4', comuna: 'ARANJUEZ', barrio: 'SAN PEDRO'},
        {id: '46', idcomuna: '4', comuna: 'ARANJUEZ', barrio: 'MANRIQUE CENTRAL Nº 1'},
        {id: '47', idcomuna: '4', comuna: 'ARANJUEZ', barrio: 'CAMPO VALDES Nº 1'},
        {id: '48', idcomuna: '4', comuna: 'ARANJUEZ', barrio: 'LAS ESMERALDAS'},
        {id: '49', idcomuna: '4', comuna: 'ARANJUEZ', barrio: 'LA PIÑUELA'},
        {id: '50', idcomuna: '4', comuna: 'ARANJUEZ', barrio: 'ARANJUEZ'},
        {id: '51', idcomuna: '4', comuna: 'ARANJUEZ', barrio: 'BRASILIA'},
        {id: '52', idcomuna: '4', comuna: 'ARANJUEZ', barrio: 'MIRANDA'},
        {id: '53', idcomuna: '4', comuna: 'ARANJUEZ', barrio: 'JARDÍN BOTÁNICO'},
        {id: '54', idcomuna: '4', comuna: 'ARANJUEZ', barrio: 'UNIVERSIDAD DE ANTIOQUIA'},
        {id: '55', idcomuna: '4', comuna: 'ARANJUEZ', barrio: 'PARQUE NORTE'},
        {id: '56', idcomuna: '5', comuna: 'CASTILLA', barrio: 'TOSCANA'},
        {id: '57', idcomuna: '5', comuna: 'CASTILLA', barrio: 'LAS BRISAS'},
        {id: '58', idcomuna: '5', comuna: 'CASTILLA', barrio: 'FLORENCIA'},
        {id: '59', idcomuna: '5', comuna: 'CASTILLA', barrio: 'TEJELO'},
        {id: '60', idcomuna: '5', comuna: 'CASTILLA', barrio: 'BOYACA'},
        {id: '61', idcomuna: '5', comuna: 'CASTILLA', barrio: 'HECTOR ABAD GOMEZ'},
        {id: '62', idcomuna: '5', comuna: 'CASTILLA', barrio: 'BELALCAZAR'},
        {id: '63', idcomuna: '5', comuna: 'CASTILLA', barrio: 'GIRARDOT'},
        {id: '64', idcomuna: '5', comuna: 'CASTILLA', barrio: 'TRICENTENARIO'},
        {id: '65', idcomuna: '5', comuna: 'CASTILLA', barrio: 'CASTILLA'},
        {id: '66', idcomuna: '5', comuna: 'CASTILLA', barrio: 'FRANCISCO ANTONIO ZEA'},
        {id: '67', idcomuna: '5', comuna: 'CASTILLA', barrio: 'ALFONSO LOPEZ'},
        {id: '68', idcomuna: '5', comuna: 'CASTILLA', barrio: 'CARIBE'},
        {id: '69', idcomuna: '5', comuna: 'CASTILLA', barrio: 'PROGRESO'},
        {id: '70', idcomuna: '5', comuna: 'CASTILLA', barrio: 'PLAZA DE FERIAS'},
        {id: '71', idcomuna: '5', comuna: 'CASTILLA', barrio: 'OLEODUCTO'},
        {id: '72', idcomuna: '5', comuna: 'CASTILLA', barrio: 'CEMENTERIO UNIVERSAL'},
        {id: '73', idcomuna: '5', comuna: 'CASTILLA', barrio: 'TERMINAL DE TRANSPORTE'},
        {id: '74', idcomuna: '6', comuna: 'DOCE DE OCTUBRE', barrio: 'SANTANDER'},
        {id: '75', idcomuna: '6', comuna: 'DOCE DE OCTUBRE', barrio: 'DOCE DE OCTUBRE Nº 1'},
        {id: '76', idcomuna: '6', comuna: 'DOCE DE OCTUBRE', barrio: 'DOCE DE OCTUBRE Nº 2'},
        {id: '77', idcomuna: '6', comuna: 'DOCE DE OCTUBRE', barrio: 'PEDREGAL - DOCE DE OCTUBRE'},
        {id: '78', idcomuna: '6', comuna: 'DOCE DE OCTUBRE', barrio: 'LA ESPERANZA - DOCE DE OCTUBRE'},
        {id: '79', idcomuna: '6', comuna: 'DOCE DE OCTUBRE', barrio: 'SAN MARTIN DE PORRES'},
        {id: '80', idcomuna: '6', comuna: 'DOCE DE OCTUBRE', barrio: 'KENNEDY'},
        {id: '81', idcomuna: '6', comuna: 'DOCE DE OCTUBRE', barrio: 'PICACHO'},
        {id: '82', idcomuna: '6', comuna: 'DOCE DE OCTUBRE', barrio: 'PICACHITO'},
        {id: '83', idcomuna: '6', comuna: 'DOCE DE OCTUBRE', barrio: 'MIRADOR DEL DOCE'},
        {id: '84', idcomuna: '6', comuna: 'DOCE DE OCTUBRE', barrio: 'PROGRESO Nº 2'},
        {id: '85', idcomuna: '6', comuna: 'DOCE DE OCTUBRE', barrio: 'EL TRIUNFO'},
        {id: '86', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'CERRO EL VOLADOR'},
        {id: '87', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'SAN GERMAN'},
        {id: '88', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'BARRIO FACULTAD DE MINAS'},
        {id: '89', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'LA PILARICA'},
        {id: '90', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'BOSQUES DE SAN PABLO'},
        {id: '91', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'ALTAMIRA'},
        {id: '92', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'CORDOBA'},
        {id: '93', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'LOPEZ DE MESA'},
        {id: '94', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'EL DIAMANTE'},
        {id: '95', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'AURES Nº 2'},
        {id: '96', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'AURES Nº 1'},
        {id: '97', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'BELLO HORIZONTE'},
        {id: '98', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'VILLA FLORA'},
        {id: '99', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'PALENQUE'},
        {id: '100', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'ROBLEDO'},
        {id: '101', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'CUCARACHO'},
        {id: '102', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'FUENTE CLARA'},
        {id: '103', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'SANTA MARGARITA'},
        {id: '104', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'OLAYA HERRERA'},
        {id: '105', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'PAJARITO - ROBLEDO'},
        {id: '106', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'MONTECLARO'},
        {id: '107', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'NUEVA VILLA DE LA IGUANA'},
        {id: '108', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'FACULTAD DE MINAS U. NAL'},
        {id: '109', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'FAC. VETERINARIA Y ZOOTECNIA U.DE.A.'},
        {id: '110', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'ECOPARQUE CERRO EL VOLADOR'},
        {id: '111', idcomuna: '7', comuna: 'ROBLEDO', barrio: 'UNIVERSIDAD NACIONAL'},
        {id: '112', idcomuna: '8', comuna: 'VILLA HERMOSA', barrio: 'VILLA HERMOSA'},
        {id: '113', idcomuna: '8', comuna: 'VILLA HERMOSA', barrio: 'LA MANSION'},
        {id: '114', idcomuna: '8', comuna: 'VILLA HERMOSA', barrio: 'SAN MIGUEL'},
        {id: '115', idcomuna: '8', comuna: 'VILLA HERMOSA', barrio: 'LA LADERA'},
        {id: '116', idcomuna: '8', comuna: 'VILLA HERMOSA', barrio: 'BATALLON GIRARDOT'},
        {id: '117', idcomuna: '8', comuna: 'VILLA HERMOSA', barrio: 'LLANADITAS'},
        {id: '118', idcomuna: '8', comuna: 'VILLA HERMOSA', barrio: 'LOS MANGOS'},
        {id: '119', idcomuna: '8', comuna: 'VILLA HERMOSA', barrio: 'ENCISO'},
        {id: '120', idcomuna: '8', comuna: 'VILLA HERMOSA', barrio: 'SUCRE'},
        {id: '121', idcomuna: '8', comuna: 'VILLA HERMOSA', barrio: 'EL PINAL'},
        {id: '122', idcomuna: '8', comuna: 'VILLA HERMOSA', barrio: 'TRECE DE NOVIEMBRE'},
        {id: '123', idcomuna: '8', comuna: 'VILLA HERMOSA', barrio: 'LA LIBERTAD'},
        {id: '124', idcomuna: '8', comuna: 'VILLA HERMOSA', barrio: 'VILLA TINA'},
        {id: '125', idcomuna: '8', comuna: 'VILLA HERMOSA', barrio: 'SAN ANTONIO - VILLA HERMONA'},
        {id: '126', idcomuna: '8', comuna: 'VILLA HERMOSA', barrio: 'LAS ESTANCIAS'},
        {id: '127', idcomuna: '8', comuna: 'VILLA HERMOSA', barrio: 'VILLA TURBAY'},
        {id: '128', idcomuna: '8', comuna: 'VILLA HERMOSA', barrio: 'LA SIERRA '},
        {id: '129', idcomuna: '8', comuna: 'VILLA HERMOSA', barrio: 'VILLA LILLIAM'},
        {id: '130', idcomuna: '9', comuna: 'BUENOS AIRES', barrio: 'JUAN PABLO II'},
        {id: '131', idcomuna: '9', comuna: 'BUENOS AIRES', barrio: 'BARRIOS DE JESUS'},
        {id: '132', idcomuna: '9', comuna: 'BUENOS AIRES', barrio: 'BOMBONA Nº 2'},
        {id: '133', idcomuna: '9', comuna: 'BUENOS AIRES', barrio: 'LOS CERROS EL VERGEL'},
        {id: '134', idcomuna: '9', comuna: 'BUENOS AIRES', barrio: 'ALEJANDRO ECHEVARRIA'},
        {id: '135', idcomuna: '9', comuna: 'BUENOS AIRES', barrio: 'BARRIO CAICEDO'},
        {id: '136', idcomuna: '9', comuna: 'BUENOS AIRES', barrio: 'BUENOS AIRES'},
        {id: '137', idcomuna: '9', comuna: 'BUENOS AIRES', barrio: 'MIRAFLORES'},
        {id: '138', idcomuna: '9', comuna: 'BUENOS AIRES', barrio: 'CATALUÑA'},
        {id: '139', idcomuna: '9', comuna: 'BUENOS AIRES', barrio: 'LA MILAGROSA'},
        {id: '140', idcomuna: '9', comuna: 'BUENOS AIRES', barrio: 'GERONA'},
        {id: '141', idcomuna: '9', comuna: 'BUENOS AIRES', barrio: 'EL SALVADOR'},
        {id: '142', idcomuna: '9', comuna: 'BUENOS AIRES', barrio: 'LORETO'},
        {id: '143', idcomuna: '9', comuna: 'BUENOS AIRES', barrio: 'ASOMADERA Nº 1'},
        {id: '144', idcomuna: '9', comuna: 'BUENOS AIRES', barrio: 'ASOMADERA Nº 2'},
        {id: '145', idcomuna: '9', comuna: 'BUENOS AIRES', barrio: 'ASOMADERA Nº 3'},
        {id: '146', idcomuna: '9', comuna: 'BUENOS AIRES', barrio: 'OCHO DE MARZO'},
        {id: '147', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'PRADO'},
        {id: '148', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'JESUS NAZARENO'},
        {id: '149', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'EL CHAGUALO'},
        {id: '150', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'ESTACION VILLA'},
        {id: '151', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'SAN BENITO'},
        {id: '152', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'GUAYAQUIL'},
        {id: '153', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'CORAZON DE JESUS'},
        {id: '154', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'CALLE NUEVA'},
        {id: '155', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'PERPETUO SOCORRO'},
        {id: '156', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'BARRIO COLON'},
        {id: '157', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'LAS PALMAS - LA CANDELARIA'},
        {id: '158', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'BOMBONA Nº 1'},
        {id: '159', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'BOSTON'},
        {id: '160', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'LOS ANGELES'},
        {id: '161', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'VILLA NUEVA'},
        {id: '162', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'LA CANDELARIA'},
        {id: '163', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'SAN DIEGO'},
        {id: '164', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'HOSPITAL SAN VICENTE DE PAÚL'},
        {id: '165', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'LA ALPUJARRA'},
        {id: '166', idcomuna: '10', comuna: 'LA CANDELARIA', barrio: 'CENTRO ADMINISTRATIVO'},
        {id: '167', idcomuna: '11', comuna: 'LAURELES - ESTADIO', barrio: 'CARLOS E. RESTREPO'},
        {id: '168', idcomuna: '11', comuna: 'LAURELES - ESTADIO', barrio: 'SURAMERICANA'},
        {id: '169', idcomuna: '11', comuna: 'LAURELES - ESTADIO', barrio: 'NARANJAL - LAURELES'},
        {id: '170', idcomuna: '11', comuna: 'LAURELES - ESTADIO', barrio: 'SAN JOAQUIN'},
        {id: '171', idcomuna: '11', comuna: 'LAURELES - ESTADIO', barrio: 'LOS CONQUISTADORES'},
        {id: '172', idcomuna: '11', comuna: 'LAURELES - ESTADIO', barrio: 'BOLIVARIANA'},
        {id: '173', idcomuna: '11', comuna: 'LAURELES - ESTADIO', barrio: 'LAURELES'},
        {id: '174', idcomuna: '11', comuna: 'LAURELES - ESTADIO', barrio: 'LAS ACACIAS'},
        {id: '175', idcomuna: '11', comuna: 'LAURELES - ESTADIO', barrio: 'LA CASTELLANA'},
        {id: '176', idcomuna: '11', comuna: 'LAURELES - ESTADIO', barrio: 'LORENA'},
        {id: '177', idcomuna: '11', comuna: 'LAURELES - ESTADIO', barrio: 'EL VELODROMO'},
        {id: '178', idcomuna: '11', comuna: 'LAURELES - ESTADIO', barrio: 'ESTADIO'},
        {id: '179', idcomuna: '11', comuna: 'LAURELES - ESTADIO', barrio: 'LOS COLORES'},
        {id: '180', idcomuna: '11', comuna: 'LAURELES - ESTADIO', barrio: 'CUARTA BRIGADA'},
        {id: '181', idcomuna: '11', comuna: 'LAURELES - ESTADIO', barrio: 'FLORIDA NUEVA'},
        {id: '182', idcomuna: '11', comuna: 'LAURELES - ESTADIO', barrio: 'BATALLÓN CUARTA BRIGADA'},
        {id: '183', idcomuna: '11', comuna: 'LAURELES - ESTADIO', barrio: 'U.D. ATANASIO GIRARDOT'},
        {id: '184', idcomuna: '11', comuna: 'LAURELES - ESTADIO', barrio: 'U.P.B'},
        {id: '185', idcomuna: '12', comuna: 'LA AMÉRICA', barrio: 'FERRINI'},
        {id: '186', idcomuna: '12', comuna: 'LA AMÉRICA', barrio: 'CALASANZ'},
        {id: '187', idcomuna: '12', comuna: 'LA AMÉRICA', barrio: 'LOS PINOS'},
        {id: '188', idcomuna: '12', comuna: 'LA AMÉRICA', barrio: 'LA AMERICA'},
        {id: '189', idcomuna: '12', comuna: 'LA AMÉRICA', barrio: 'LA FLORESTA'},
        {id: '190', idcomuna: '12', comuna: 'LA AMÉRICA', barrio: 'SANTA LUCIA'},
        {id: '191', idcomuna: '12', comuna: 'LA AMÉRICA', barrio: 'EL DANUBIO'},
        {id: '192', idcomuna: '12', comuna: 'LA AMÉRICA', barrio: 'CAMPO ALEGRE'},
        {id: '193', idcomuna: '12', comuna: 'LA AMÉRICA', barrio: 'SANTA MONICA'},
        {id: '194', idcomuna: '12', comuna: 'LA AMÉRICA', barrio: 'BARRIO CRISTOBAL'},
        {id: '195', idcomuna: '12', comuna: 'LA AMÉRICA', barrio: 'SIMON BOLIVAR'},
        {id: '196', idcomuna: '12', comuna: 'LA AMÉRICA', barrio: 'SANTA TERESITA'},
        {id: '197', idcomuna: '12', comuna: 'LA AMÉRICA', barrio: 'CALASANZ PARTE ALTA'},
        {id: '198', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'EL PESEBRE'},
        {id: '199', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'BLANQUIZAL'},
        {id: '200', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'SANTA ROSA DE LIMA'},
        {id: '201', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'LOS ALCAZARES'},
        {id: '202', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'METROPOLITANO'},
        {id: '203', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'LA PRADERA'},
        {id: '204', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'JUAN XIII - LA QUIEBRA'},
        {id: '205', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'SAN JAVIER Nº 2'},
        {id: '206', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'SAN JAVIER Nº 1'},
        {id: '207', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'VEINTE DE JULIO'},
        {id: '208', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'BELENCITO'},
        {id: '209', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'BETANIA'},
        {id: '210', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'EL CORAZON - SAN JAVIER'},
        {id: '211', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'LAS INDEPENDENCIAS'},
        {id: '212', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'NUEVOS CONQUISTADORES'},
        {id: '213', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'EL SALADO - SAN JAVIER'},
        {id: '214', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'EDUARDO SANTOS'},
        {id: '215', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'ANTONIO NARIÑO'},
        {id: '216', idcomuna: '13', comuna: 'SAN JAVIER', barrio: 'EL SOCORRO'},
        {id: '217', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'BARRIO COLOMBIA'},
        {id: '218', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'VILLA CARLOTA'},
        {id: '219', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'CASTROPOL'},
        {id: '220', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'LALINDE'},
        {id: '221', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'LAS LOMAS Nº 1'},
        {id: '222', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'LAS LOMAS Nº 2'},
        {id: '223', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'ALTOS DEL POBLADO'},
        {id: '224', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'EL TESORO'},
        {id: '225', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'LOS NARANJOS'},
        {id: '226', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'LOS BALSOS Nº 1'},
        {id: '227', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'SAN LUCAS'},
        {id: '228', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'EL DIAMANTE Nº 2'},
        {id: '229', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'EL CASTILLO'},
        {id: '230', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'LOS BALSOS Nº 2'},
        {id: '231', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'ALEJANDRIA'},
        {id: '232', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'LA FLORIDA - EL POBLADO'},
        {id: '233', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'EL POBLADO'},
        {id: '234', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'MANILA'},
        {id: '235', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'ASTORGA'},
        {id: '236', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'PATIO BONITO'},
        {id: '237', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'LA AGUACATALA'},
        {id: '238', idcomuna: '14', comuna: 'EL POBLADO', barrio: 'SANTA MARIA DE LOS ANGELES'},
        {id: '239', idcomuna: '15', comuna: 'GUAYABAL', barrio: 'TENCHE'},
        {id: '240', idcomuna: '15', comuna: 'GUAYABAL', barrio: 'TRINIDAD'},
        {id: '241', idcomuna: '15', comuna: 'GUAYABAL', barrio: 'SANTA FE'},
        {id: '242', idcomuna: '15', comuna: 'GUAYABAL', barrio: 'CAMPO AMOR'},
        {id: '243', idcomuna: '15', comuna: 'GUAYABAL', barrio: 'CRISTO REY'},
        {id: '244', idcomuna: '15', comuna: 'GUAYABAL', barrio: 'GUAYABAL'},
        {id: '245', idcomuna: '15', comuna: 'GUAYABAL', barrio: 'LA COLINA'},
        {id: '246', idcomuna: '15', comuna: 'GUAYABAL', barrio: 'PARQUE JUAN PABLO II'},
        {id: '247', idcomuna: '15', comuna: 'GUAYABAL', barrio: 'EL RODEO'},
        {id: '248', idcomuna: '16', comuna: 'BELÉN', barrio: 'FATIMA'},
        {id: '249', idcomuna: '16', comuna: 'BELÉN', barrio: 'ROSALES'},
        {id: '250', idcomuna: '16', comuna: 'BELÉN', barrio: 'BELEN'},
        {id: '251', idcomuna: '16', comuna: 'BELÉN', barrio: 'GRANADA'},
        {id: '252', idcomuna: '16', comuna: 'BELÉN', barrio: 'SAN BERNARDO'},
        {id: '253', idcomuna: '16', comuna: 'BELÉN', barrio: 'LAS PLAYAS - BELEN'},
        {id: '254', idcomuna: '16', comuna: 'BELÉN', barrio: 'DIEGO ECHEVARRIA'},
        {id: '255', idcomuna: '16', comuna: 'BELÉN', barrio: 'LA MOTA'},
        {id: '256', idcomuna: '16', comuna: 'BELÉN', barrio: 'LA HONDONADA'},
        {id: '257', idcomuna: '16', comuna: 'BELÉN', barrio: 'EL RINCON'},
        {id: '258', idcomuna: '16', comuna: 'BELÉN', barrio: 'LA LOMA DE LOS BERNAL'},
        {id: '259', idcomuna: '16', comuna: 'BELÉN', barrio: 'LA GLORIA'},
        {id: '260', idcomuna: '16', comuna: 'BELÉN', barrio: 'ALTAVISTA - BELEN'},
        {id: '261', idcomuna: '16', comuna: 'BELÉN', barrio: 'LA PALMA - BELEN'},
        {id: '262', idcomuna: '16', comuna: 'BELÉN', barrio: 'LOS ALPES'},
        {id: '263', idcomuna: '16', comuna: 'BELÉN', barrio: 'LAS VIOLETAS'},
        {id: '264', idcomuna: '16', comuna: 'BELÉN', barrio: 'LAS MERCEDES'},
        {id: '265', idcomuna: '16', comuna: 'BELÉN', barrio: 'NUEVA VILLA DE ABURRA'},
        {id: '266', idcomuna: '16', comuna: 'BELÉN', barrio: 'MIRAVALLE'},
        {id: '267', idcomuna: '16', comuna: 'BELÉN', barrio: 'EL NOGAL - LOS ALMENDROS'},
        {id: '268', idcomuna: '16', comuna: 'BELÉN', barrio: 'CERRO NUTIBARA'},
        {id: '269', idcomuna: '16', comuna: 'BELÉN', barrio: 'CERRO NUTIBARA INS.'},
        {id: '270', idcomuna: '50', comuna: 'PALMITAS', barrio: 'LA SUIZA -CORREG DE PALMITAS'},
        {id: '271', idcomuna: '50', comuna: 'PALMITAS', barrio: 'LA SUCIA - CORREG DE PALMITAS'},
        {id: '272', idcomuna: '50', comuna: 'PALMITAS', barrio: 'URQUITA - CORREG DE PALMITAS'},
        {id: '273', idcomuna: '50', comuna: 'PALMITAS', barrio: 'SECTOR CENTRAL - CORREG DE PALMITAS'},
        {id: '274', idcomuna: '50', comuna: 'PALMITAS', barrio: 'LA VOLCANA - GUAYABAL  - CORREG DE PALMITAS'},
        {id: '275', idcomuna: '50', comuna: 'PALMITAS', barrio: 'LA FRISOLA - CORREG DE PALMITAS'},
        {id: '276', idcomuna: '50', comuna: 'PALMITAS', barrio: 'LA ALDEA -CORREG DE PALMILTAS'},
        {id: '277', idcomuna: '50', comuna: 'PALMITAS', barrio: 'LA POTRERA - MISERENGA - CORREG DE PALMITAS'},
        {id: '278', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'LA PALMA - CORREG SAN CRISTOBAL'},
        {id: '279', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'EL PATIO - CORREG SAN CRISTOBAL'},
        {id: '280', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'EL UVITO SAN JOSE DE LA MONTAÑA - CORREG SAN CRISTOBAL'},
        {id: '281', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'LA CUCHILLA - CORREG SAN CRISTOBAL'},
        {id: '282', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'NARANJAL - CORREG SAN CRISTOBAL'},
        {id: '283', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'BOQUERON - CORREG SAN CRISTOBAL'},
        {id: '284', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'EL OLIVO - CORREG  SAN CRISTOBAL'},
        {id: '285', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'LA ILUSION - CORREG SAN CRISTOBAL'},
        {id: '286', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'EL YOLOMBO - CORREG SAN CRISTOBAL'},
        {id: '287', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'EL CARMELO - CORREG SAN CRISTOBAL'},
        {id: '288', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'EL PICACHO - CORREG SAN CRISTOBAL'},
        {id: '289', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'PAJARITO - CORREG SAN CRISTOBAL'},
        {id: '290', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'PEDREGAL  ALTO - CORREG SAN CRISTOBAL'},
        {id: '291', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'LA LOMA - CORREG SAN CRISTOBAL'},
        {id: '292', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'LAS PLAYAS - CORREG SAN CRISTOBAL'},
        {id: '293', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'TRAVESIAS LA CUMBRE - CORREG SAN CRISTOBAL'},
        {id: '294', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'EL LLANO - CORREG SAN CRISTOBAL'},
        {id: '295', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'ÁREA DE EXPANSIÓN PAJARITO'},
        {id: '296', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'ÁREA DE EXPANSIÓN CALASANZ'},
        {id: '297', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'ÁREA DE EXPANSIÓN EDUARDO SANTOS'},
        {id: '298', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'SAN CRISTOBAL SECTOR CENTRAL'},
        {id: '299', idcomuna: '70', comuna: 'ALTAVISTA', barrio: 'BUGA - PATIO BONITO - CORREG ALTAVISTA'},
        {id: '300', idcomuna: '70', comuna: 'ALTAVISTA', barrio: 'AGUAS FRIAS - CORREG ALTAVISTA'},
        {id: '301', idcomuna: '70', comuna: 'ALTAVISTA', barrio: 'EL CORAZON - EL MORRO - CORREG ALTAVISTA'},
        {id: '302', idcomuna: '70', comuna: 'ALTAVISTA', barrio: 'SAN PABLO - CORREG ALTAVISTA'},
        {id: '303', idcomuna: '70', comuna: 'ALTAVISTA', barrio: 'ALTAVISTA CENTRAL - CORREG ALTAVISTA'},
        {id: '304', idcomuna: '70', comuna: 'ALTAVISTA', barrio: 'LA ESPERANZA - CORREG ALTAVISTA '},
        {id: '305', idcomuna: '70', comuna: 'ALTAVISTA', barrio: 'SAN JOSE DEL MANZANILLO - CORREG ALTAVISTA'},
        {id: '306', idcomuna: '70', comuna: 'ALTAVISTA', barrio: 'EL JARDIN - CORREG ALTAVISTA'},
        {id: '307', idcomuna: '70', comuna: 'ALTAVISTA', barrio: 'ÁREA DE EXPANSIÓN EL NORAL'},
        {id: '308', idcomuna: '70', comuna: 'ALTAVISTA', barrio: 'ÁREA DE EXPANSIÓN ALTAVISTA'},
        {id: '309', idcomuna: '70', comuna: 'ALTAVISTA', barrio: 'ÁREA DE EXPANSIÓN BELÉN'},
        {id: '310', idcomuna: '80', comuna: 'SAN ANTONIO DE PRADO', barrio: 'LA FLORIDA - CORREG SAN ANTONIO DE PRADO'},
        {id: '311', idcomuna: '80', comuna: 'SAN ANTONIO DE PRADO', barrio: 'POTRERITO - CORREG SAN ANTONIO DE PRADO'},
        {id: '312', idcomuna: '80', comuna: 'SAN ANTONIO DE PRADO', barrio: 'MONTAÑITA - CORREG SAN ANTONIO DE PRADO'},
        {id: '313', idcomuna: '80', comuna: 'SAN ANTONIO DE PRADO', barrio: 'YARUMALITO - CORREG SAN ANTONIO DE PRADO'},
        {id: '314', idcomuna: '80', comuna: 'SAN ANTONIO DE PRADO', barrio: 'EL ASTILLERO - CORREG SAN ANTONIO DE PRADO'},
        {id: '315', idcomuna: '80', comuna: 'SAN ANTONIO DE PRADO', barrio: 'EL SALADO - CORREG SAN ANTONIO DE PRADO'},
        {id: '316', idcomuna: '80', comuna: 'SAN ANTONIO DE PRADO', barrio: 'LA VERDE - CORREG SAN ANTONIO DE PRADO'},
        {id: '317', idcomuna: '80', comuna: 'SAN ANTONIO DE PRADO', barrio: 'SAN JOSE - CORREG SAN ANTONIO DE PRADO'},
        {id: '318', idcomuna: '80', comuna: 'SAN ANTONIO DE PRADO', barrio: 'ÁREA DE EXPANSIÓN SAN ANTONIO DE PRADO'},
        {id: '319', idcomuna: '80', comuna: 'SAN ANTONIO DE PRADO', barrio: 'SAN ANTONIO PARTE CENTRAL - CORREG SAN ANTONIO DE PRADO'},
        {id: '320', idcomuna: '90', comuna: 'SANTA ELENA', barrio: 'LAS PALMAS - CORREG SANTA ELENA'},
        {id: '321', idcomuna: '90', comuna: 'SANTA ELENA', barrio: 'MEDIA LUNA - CORREG SANTA ELENA'},
        {id: '322', idcomuna: '90', comuna: 'SANTA ELENA', barrio: 'PIEDRAS BLANCAS - MATAZANO - CORREG SANTA ELENA'},
        {id: '323', idcomuna: '90', comuna: 'SANTA ELENA', barrio: 'BARRO BLANCO - CORREG SANTA ELENA'},
        {id: '324', idcomuna: '90', comuna: 'SANTA ELENA', barrio: 'EL PLACER - CORREG SANTA ELENA'},
        {id: '325', idcomuna: '90', comuna: 'SANTA ELENA', barrio: 'SANTA ELENA SECTOR CENTRAL (CABECERA) - CORREG SANTA ELENA'},
        {id: '326', idcomuna: '90', comuna: 'SANTA ELENA', barrio: 'EL CERRO - CORREG SANTA ELENA'},
        {id: '327', idcomuna: '90', comuna: 'SANTA ELENA', barrio: 'EL LLANO - CORREG SANTA ELENA'},
        {id: '328', idcomuna: '90', comuna: 'SANTA ELENA', barrio: 'EL PLAN - CORREG SANTA ELENA'},
        {id: '329', idcomuna: '90', comuna: 'SANTA ELENA', barrio: 'PIEDRA GORDA - CORREG SANTA ELENA'},
        {id: '330', idcomuna: '90', comuna: 'SANTA ELENA', barrio: 'MAZO - CORREG SANTA ELENA'},
        {id: '331', idcomuna: '60', comuna: 'SAN CRISTOBAL', barrio: 'PEDREGAL  BAJO - CORREG SAN CRISTOBAL'},
        {id: '332', idcomuna: '70', comuna: 'ALTAVISTA', barrio: 'LA PALMA - CORREG ALTAVISTA'},
        {id: '334', idcomuna: '90', comuna: 'SANTA ELENA', barrio: 'EL ROSARIO - CORREG SANTA ELENA'}
    ],
    lugarExacto: [
        '0', '1 - Cabecera municipal', '2 - Barrio.', '3 - Corregimiento.', '4 - Vereda.'        
    ],
    actorArmado: [
        { value: '0', text: '' },
        { value: '1', text: '8 DE MARZO' },
        { value: '2', text: 'ALTOS DE LA VIRGEN' },
        { value: '3', text: 'ARAGÓN' },
        { value: '4', text: 'AUTODEFENSAS GAITANISTAS-URABEÑOS-CLAN DEL GOLFO-CLAN ÚSUGA' },
        { value: '5', text: 'BACRIM' },
        { value: '6', text: 'BANANEROS' },
        { value: '7', text: 'BELLO ORIENTE' },
        { value: '8', text: 'BETANIA' },
        { value: '9', text: 'BJ CHAMIZOS' },
        { value: '10', text: 'CALATRAVA' },
        { value: '11', text: 'CAPARRAPOS' },
        { value: '12', text: 'COCUELOS ' },
        { value: '13', text: 'COMBO DEL BARRIO O DE LA ZONA' },
        { value: '14', text: 'COOTRANAL' },
        { value: '15', text: 'CÓRDOBA' },
        { value: '16', text: 'CURAZAO' },
        { value: '17', text: 'DISIDENCIA DE LAS FARC' },
        { value: '18', text: 'EDUARDO SANTOS' },
        { value: '19', text: 'EL 12' },
        { value: '20', text: 'EL ACOPIO' },
        { value: '21', text: 'EL COCO ' },
        { value: '22', text: 'EL HUECO' },
        { value: '23', text: 'EL MESA' },
        { value: '24', text: 'EL SALADO, LA 115 O LA BOA' },
        { value: '25', text: 'ELN' },
        { value: '26', text: 'EPL O PELUSOS' },
        { value: '27', text: 'FLORENCIA' },
        { value: '28', text: 'FUERZA PUBLICA' },
        { value: '29', text: 'GRUPO ARMADO DESCONOCIDO' },
        { value: '30', text: 'GUERRILLA' },
        { value: '31', text: 'HUECO DE A CANDELARIA' },
        { value: '32', text: 'INDEPENDENCIAS' },
        { value: '33', text: 'JUAQUINILLOS' },
        { value: '34', text: 'EL VENTIADERO' },
        { value: '35', text: 'LA 260' },
        { value: '36', text: 'LA AGONÍA' },
        { value: '37', text: 'LA AVANZADA' },
        { value: '38', text: 'LA CAMPIÑA' },
        { value: '39', text: 'LA CAPILLA' },
        { value: '40', text: 'LA COLINITA' },
        { value: '41', text: 'LA DIVISA' },
        { value: '42', text: 'LA HUERTA' },
        { value: '43', text: 'LA IMPERIAL' },
        { value: '44', text: 'LA LIBERTAD' },
        { value: '45', text: 'LA NUEVA GENERACIÓN' },
        { value: '46', text: 'LA OCULTA' },
        { value: '47', text: 'LA OFICINA' },
        { value: '48', text: 'LA RAYA' },
        { value: '49', text: 'LA ROJA' },
        { value: '50', text: 'LA SEXTA' },
        { value: '51', text: 'LA SILLA' },
        { value: '52', text: 'LA TERRAZA' },
        { value: '53', text: 'LA TORRE' },
        { value: '54', text: 'LA UNION' },
        { value: '55', text: 'LA VIÑA' },
        { value: '56', text: 'LECHEROS' },
        { value: '57', text: 'LIMONAR O LAS BIFAS' },
        { value: '58', text: 'LOS CHATAS' },
        { value: '59', text: 'LOS CHIVOS' },
        { value: '60', text: 'LOS CONEJOS' },
        { value: '61', text: 'LOS DE PARÍS' },
        { value: '62', text: 'LOS OTOTOS' },
        { value: '63', text: 'LOS PÁJAROS' },
        { value: '64', text: 'LOS RASTROJOS' },
        { value: '65', text: 'LOS TRIANA' },
        { value: '66', text: 'MACHACOS' },
        { value: '67', text: 'MANO DE DIOS O NUEVO AMANECER' },
        { value: '68', text: 'MATECAÑA' },
        { value: '69', text: 'MONDONGUEROS' },
        { value: '70', text: 'OFICINA DE MORAVIA' },
        { value: '71', text: 'OTROS' },
        { value: '72', text: 'PACHELLY' },
        { value: '73', text: 'PÁJARO AZUL' },
        { value: '74', text: 'PARAMILITARES' },
        { value: '75', text: 'PEÑITAS' },
        { value: '76', text: 'PESEBREROS' },
        { value: '77', text: 'PICACHO' },
        { value: '78', text: 'PICÚAS' },
        { value: '79', text: 'PUNTILLEROS-BLOQUE META' },
        { value: '80', text: 'PUNTILLEROS-LIBERTADORES DEL VICHADA' },
        { value: '81', text: 'SAN ANTONIO' },
        { value: '82', text: 'TRECE DE NOVIEMBRE' },
        { value: '83', text: 'TRIANON' },
        { value: '84', text: 'TRINIDAD' },
        { value: '85', text: 'VILLA FLORA, EL MORRO O LA VIRGEN' },
        { value: '86', text: 'VILLA SOFÍA' },
        { value: '87', text: 'ZAFRA O LOS PÁJAROS' },
        { value: '88', text: 'NO IDENTIFICA' },
        { value: '89', text: 'ESTADO MAYOR CENTRAL (EMC)' },
        { value: '90', text: 'SEGUNDA MARQUETALIA ' },
        { value: '91', text: 'LOS TUMAQUEÑOS' },
        { value: '92', text: 'AGUILAS NEGRAS' },
        { value: '93', text: 'AUTODEFENSAS CONQUISTADORES LA SIERRA NEVADA' },
        { value: '94', text: 'BALCONES DE LA 29' },
        { value: '95', text: 'BANDA DE AGUASFRIAS' },
        { value: '96', text: 'BANDA LA LOCAL LOS CHOTAS' },
        { value: '97', text: 'BARRIO BOLSA' },
        { value: '98', text: 'BATMAN' },
        { value: '99', text: 'DISIDENCIAS GENTÍL DUARTE ' },
        { value: '100', text: 'CARABANUDOS' },
        { value: '101', text: 'CARTEL DE SINALOA' },
        { value: '102', text: 'CDF COMANDO DE FONTRATERA' },
        { value: '103', text: 'LOS MEXICANOS' },
        { value: '104', text: 'CLAN DE ORIENTE' },
        { value: '105', text: 'LOS CONTADORES' },
        { value: '106', text: 'LA CORDILLERA' },
        { value: '107', text: 'FRENTE DAGOBERTO RAMOS -DISIDENCIAS FARC' },
        { value: '108', text: 'FRENTE 18 - DISIDENCIAS FARC' },
        { value: '109', text: 'FRENTE 36 - DISIDENCIAS FARC' },
        { value: '110', text: 'FRENTE CARLOS PATIÑO - DISIDENCIAS FARC' },
        { value: '111', text: 'EL AJIZAL ' },
        { value: '112', text: 'EL DIAMANTE' },
        { value: '113', text: 'TREN DE ARAGUA ' },
        { value: '114', text: 'ESPARTANOS' },
        { value: '115', text: 'FRENTE 26 - DISIDENCIAS FARC' },
        { value: '116', text: 'FRENTE FRANCO BENAVIDES DEL ESTADO MAYOR CENTRAL ' },
        { value: '117', text: 'FRENTE 33 ISMAÉL RUÍZ - DISIDENCIAS FARC' },
        { value: '118', text: 'FRENTE DOMINGO LAÍN - DISIDENCIAS FARC' },
        { value: '119', text: 'LA 115' },
        { value: '120', text: 'LAS VIOLETAS' },
        { value: '121', text: 'NUEVO RENACER' },
        { value: '122', text: 'SAN PABLO' },
        { value: '123', text: 'LOS JOAQUINILLOS' },
        { value: '124', text: 'LA 38' },
        { value: '125', text: 'LA ARBOLEDA' },
        { value: '126', text: '20 DE JULIO' },
        { value: '127', text: 'LA GALERA' },
        { value: '128', text: 'SHOTTAS' },
        { value: '129', text: 'LA MIEL' },
        { value: '130', text: 'LA SIERRA' },
        { value: '131', text: 'LAS MULAS' },
        { value: '132', text: 'LIBERTADORES DEL NORDESTE' },
        { value: '133', text: 'LOS BUCAROS' },
        { value: '134', text: 'LOS CHACALITOS ' },
        { value: '135', text: 'LOS CHICORIOS' },
        { value: '136', text: 'LOS CHINCHES' },
        { value: '137', text: 'LOS COSTEÑOS' },
        { value: '138', text: 'LOS DE ABAJO' },
        { value: '139', text: 'NIQUIA CAMACOL' },
        { value: '140', text: 'LA 100' },
        { value: '141', text: 'LOS DE CAMPO VALDÉS' },
        { value: '142', text: 'LOS DE LA PAZ ' },
        { value: '143', text: 'NUEVA JERUSALÉN' },
        { value: '144', text: 'LOS MENA' },
        { value: '145', text: 'MORROCHISPAS' },
        { value: '146', text: 'LOS PACHENCAS' },
        { value: '147', text: 'LOS PAISAS' },
        { value: '148', text: 'LOS PALMEÑOS' },
        { value: '149', text: 'LOS PAMPLONA' },
        { value: '150', text: 'LOS PELUDOS' },
        { value: '151', text: 'LOS PIRUSOS' },
        { value: '152', text: 'LOS PITUFOS' },
        { value: '153', text: 'LOS TERRIBLES' },
        { value: '154', text: 'LOS NORTEÑOS' },
        { value: '155', text: 'LOS PRIMOS' },
        { value: '156', text: 'FUERZAS ARMADAS RPC' },
        { value: '157', text: 'TERRANOVA' },

    ]
}