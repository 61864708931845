<template>
    <div>
        <Navbar/>
        <b-row>
            <b-col>
                <p>Se generara un archivo para enviar a notificaciones y se guardara la fecha de envio a hoy</p>
                <button @click="generarArchivo()">Generar</button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <p v-if="pendientesEnvioNotificar.length == 0">Noy hay datos para mostrar</p>                    
                <b-table 
                    responsive
                    striped 
                    hover 
                    outlined
                    :items="pendientesEnvioNotificar">                    
                </b-table>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import moment from 'moment-timezone'

export default {
    name: 'ListaEnviadosNotificar',
    components: {
        Navbar
    },
    data() {
        return {
            pendientesEnvioNotificar: [],
            token: '',
        }
    },
    methods: {
        generarArchivo: function () {
            let url = this.$store.getters.getApiUrl
            this.pendientesEnvioNotificar.forEach( element => {                
                fetch( url + "setfechaenviadovaloracion/" + element.id, {
                    method: 'POST',
                    headers: new Headers({
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`
                    }),
                    body: JSON.stringify({
                        ENVIADOVALORACION: moment().format("YYYY-MM-DD")
                    })
                })
            })
            
            this.csvExport(this.pendientesEnvioNotificar); 
        },
        csvExport(arrData) {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
                Object.keys(arrData[0]).join(";"),
                ...arrData.map(item => Object.values(item).join(";"))
            ]
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "");

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", "export.csv");
            link.click();
        }    
    },
    mounted () {
        let url = this.$store.getters.getApiUrl
        this.token = this.$store.getters.theToken
        fetch( url + "pendientesenviarvaloracion", {
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.token}`
            })
        })
        .then( resp => resp.json())
        .then( resp => {
            this.pendientesEnvioNotificar = resp.data
        })
    }

}
</script>