<template>
    <div>
        <br>
        <h1>Contratos albergue</h1>
        <b-container v-if="!showformContrato">
            <b-button @click="resetContrato();showformContrato=true;">Nuevo contrato</b-button>            
            <b-table responsive
                :sticky-header="stickyHeader"
                :striped="striped"
                :bordered="bordered"
                :items="contratoalbergues"
                :fields="fields"
                >                
                <template #cell(actions)="row">
                    <b-button size="sm" @click="llenarformContrato(row.item)" class="mr-1">
                    Ver
                    </b-button>                    
                </template>
            </b-table>
        </b-container>
        

        <b-container v-if="showformContrato">            
            <b-form @submit.prevent="saveContrato">  
                <b-row align-h="end">
                    <b-col sm="1"><b-button-close @click="showformContrato=false">X</b-button-close></b-col>
                </b-row>
                <b-row>
                    <b-col>
                        Contrato de albergues: {{ objs.contrato.id }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="2">
                        <br>
                        <b-form-input 
                            v-model="objs.contrato.NROCONTRATO" 
                            placeholder="Contrato numero"
                            required>
                        </b-form-input>
                    </b-col>
                    <b-col sm="3">
                        <br>
                        <b-form-input
                            v-model="objs.contrato.NOMBREALBERGUE"
                            placeholder="Nombre albergue"
                            required>
                        </b-form-input>
                    </b-col>                
                </b-row>
                <b-row>
                    <b-col sm="5">
                        <br>
                        <b-input-group prepend="Fecha inicio">
                            <b-form-datepicker 
                                locale='es' 
                                id="fechaInicio" 
                                v-model="objs.contrato.FECHAINICIO"
                                required>
                            </b-form-datepicker>
                        </b-input-group>
                    </b-col>
                    <b-col sm="5">
                        <br>
                        <b-input-group prepend="Fecha fin">
                            <b-form-datepicker 
                                locale='es' 
                                id="fechaFin" 
                                v-model="objs.contrato.FECHAFIN"
                                required>
                            </b-form-datepicker>
                        </b-input-group>                    
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="3">
                        <br>
                        <b-input-group prepend="Valor contrato">
                            <b-form-input
                                v-model="objs.contrato.VALORCONTRATO"
                                type="number"
                                required>
                            </b-form-input>
                        </b-input-group>                    
                    </b-col>
                    <b-col sm="3">
                        <br>
                        <b-input-group prepend="Valor cupo día">
                            <b-form-input
                                v-model="objs.contrato.VALORCUPODIA"
                                type="number"
                                required>
                            </b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="3">
                        <br>
                        <b-input-group prepend="% cupo no utilizado">
                            <b-form-input
                                v-model="objs.contrato.PERCCUPONOUTIL"
                                type="number"
                                required>
                            </b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col sm="9">
                        <br>
                        <b-form-textarea
                            v-model="objs.contrato.OBSERVACION"
                            rows="3"
                            placeholder="Observaciones">
                        </b-form-textarea>
                    </b-col>
                </b-row>
                <b-row align-h="end">
                    <b-col sm="2">
                        <br>
                        
                        <b-button type="submit">Guardar</b-button>
                    </b-col>
                </b-row>
                
            </b-form>
        </b-container>
        
    </div>
</template>
<script>
import axios from 'axios'
import objs from '../albergue/objetos'
export default {
    name: 'Contratoalbergue',
    data () {
        return {
            showformContrato: false,
            token: "",
            contratoalbergues: [],
            objs: objs,            
            minDate: '2019-07-01',
            maxDate: new Date(),
            fields: [
                { key: 'id', label: 'id' },
                { key: 'NROCONTRATO', label: 'Numero' },
                { key: 'NOMBREALBERGUE', label: 'Albergue' },
                { key: 'FECHAINICIO', label: 'Inicio' },
                { key: 'FECHAFIN', label: 'Fin'},
                { key: 'VALORCONTRATO', label: 'Valor',},
                { key: 'VALORCUPODIA', label: 'Cupo día' },
                { key: 'PERCCUPONOUTIL', label: '% cupo no utilizado' },
                { key: 'actions', label: 'Acciones' }
            ]
        }
    },
    methods: {
        getContratos: function () {
            this.token = this.$store.getters.theToken
            let url = this.$store.getters.getApiUrl
            axios.get( url + 'contratoalbergue', 
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                }
            })
            .then((res) => {            
                this.contratoalbergues = res.data.data
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        iraLogin: function (error) {
            if(error.response && error.response.status == 401){
                this.$store.dispatch('logout')
                this.$router.push('/login') 
            }
        },
        llenarformContrato: function (item) {
            this.objs.contrato.id = item.id
            this.objs.contrato.NROCONTRATO = item.NROCONTRATO
            this.objs.contrato.NOMBREALBERGUE = item.NOMBREALBERGUE
            this.objs.contrato.FECHAINICIO = item.FECHAINICIO
            this.objs.contrato.FECHAFIN = item.FECHAFIN
            this.objs.contrato.VALORCONTRATO = item.VALORCONTRATO
            this.objs.contrato.VALORCUPODIA = item.VALORCUPODIA
            this.objs.contrato.PERCCUPONOUTIL = item.PERCCUPONOUTIL
            this.objs.contrato.OBSERVACION = item.OBSERVACION
            this.showformContrato=true
        },
        resetContrato: function () {
            this.objs.contrato.id = ''
            this.objs.contrato.NROCONTRATO = ''
            this.objs.contrato.NOMBREALBERGUE = ''
            this.objs.contrato.FECHAINICIO = ''
            this.objs.contrato.FECHAFIN = ''
            this.objs.contrato.VALORCONTRATO = ''
            this.objs.contrato.VALORCUPODIA = ''
            this.objs.contrato.PERCCUPONOUTIL = ''
            this.objs.contrato.OBSERVACION = ''
        },
        saveContrato: function () {
            let url = this.$store.getters.getApiUrl
            this.token = this.$store.getters.theToken                   
            const params = new URLSearchParams()            
            params.append('id', this.objs.contrato.id)
            params.append('NROCONTRATO', this.objs.contrato.NROCONTRATO)
            params.append('NOMBREALBERGUE', this.objs.contrato.NOMBREALBERGUE)
            params.append('FECHAINICIO', this.objs.contrato.FECHAINICIO)
            params.append('FECHAFIN', this.objs.contrato.FECHAFIN)
            params.append('VALORCONTRATO', this.objs.contrato.VALORCONTRATO)
            params.append('VALORCUPODIA', this.objs.contrato.VALORCUPODIA)            
            params.append('PERCCUPONOUTIL', this.objs.contrato.PERCCUPONOUTIL)            
            params.append('OBSERVACION', this.objs.contrato.OBSERVACION)            
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.token}`
            }

            if(this.objs.contrato.id == ""){
               url = url + 'contratoalbergue' 
               axios.post( url, params, { headers })
                .then(() => {                        
                    alert ("Contrato guardado")                    
                })
                .catch((error) => {
                    console.log(error)
                })
            }else{
                url = url + 'contratoalbergue/' + this.objs.contrato.id 
                axios.put( url, params, { headers })
                .then(() => {                        
                    alert ("Contrato guardado")
                })
                .catch((error) => {
                    console.log(error)
                })
            }
            this.showformContrato = false
            this.getContratos()
        }
    },
    mounted () {
        this.getContratos()
    }
}
</script>