import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user : {
      name: '', 
      perfil: 0
    },
    urlApi: 'https://dignapi.earvmedellin.com.co/api/',
    //urlApi: 'http://127.0.0.1:8000/api/'
  },
  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, token, user, perfil){
      state.status = 'success'
      state.token = token
      state.user.name = user
      state.user.perfil = perfil
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
    },
  },
  actions: {
    login({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        let url = this.getters.getApiUrl
        axios({url: url + 'login', data: user, method: 'POST' })
        .then(resp => {
          const token = resp.data.data.token          
          const userName = resp.data.data.name
          const userPerfil = resp.data.data.perfil
          const userRol = resp.data.data.ROL
          let p
          switch (userPerfil) {
            case 2:
              p = 'ADMINISTRADOR'
              break
            case 22: 
              p = 'ANALISTA'
              break
            case 18: 
              p = 'ALBERGUE'
              break
            case 10: 
              p = 'GESTION_SOCIAL'
              break
            case 1: 
              p = 'CONSULTA'
              break
            default:
              p = userPerfil
              break
          }
          console.log(p)
          localStorage.setItem('userRol', userRol)
          localStorage.setItem('token', token)
          localStorage.setItem('userName', userName)
          localStorage.setItem('userPerfil', p)
          axios.defaults.headers.common['Authorization'] = token          
          commit('auth_success', token, userName, p)
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    logout({commit}){
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('userName')
        localStorage.removeItem('userPerfil')
        localStorage.removeItem('sede')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    }
  },
  modules: {
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    getApiUrl: state => state.urlApi,
    theToken: state => state.token,
    getUser: state => state.user
  }
})
